import newsletterOK from "../../../../animations/newsletterOK";
import succeed from "../../../../animations/succeed";
import Lottie from "react-lottie";
import React from "react";
import importedStyles from "./NewsletterSection.module.sass";
import {CardContent} from "@material-ui/core";
import Button from "@material-ui/core/Button/index";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import TextField from "@material-ui/core/TextField/index";
import baseRoute from "../../../../helpers/baseRoute";
import {showSnack, snackTypes} from "../../../../components/Snack/SnackActions";
import Cookies from 'universal-cookie';
import CircularProgress from "@material-ui/core/CircularProgress/index";
import ReactPixel from "react-facebook-pixel";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";

class NewsletterSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendingData: false,
            email: '',
            subscriptionSucceed: false,
        };
    }

    componentDidMount(): void {

    }

    renderAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: newsletterOK,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                //speed={0.7}
            />
        )
    };
    renderSucceedAnimation = () => {
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: succeed,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                    style={{marginTop: '1rem'}}
            />
        )
    };

    handleInput = (e) => {
        let email = e.target.value;
        let regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        let isValidMail = regex.test(email);

        this.setState({email: email, isValidMail: isValidMail});
    };

    setNewsletterCokkie = () => {
        const cookies = new Cookies();
        let expireDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        cookies.set('subscribedToNewsletter', true, {path: '/', expires: expireDate});
    };

    triggerDocumentDownload = (requestedDocument) => {
        let link = document.createElement('a');
        link.href = './helpDocuments/' + requestedDocument + '.pdf';
        link.download = requestedDocument + '.pdf';
        link.dispatchEvent(new MouseEvent('click'));
    };

    handleSubscription = () => {
        this.setState({sendingData: true});
        fetch(baseRoute + 'api/newsletters/newSubscription', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: this.state.email,
                    requestedDocument: null
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setNewsletterCokkie();
                this.setState({subscriptionSucceed: true});
                //this.props.onClose();

                //If succeed then track an event on fb pixel
                ReactPixel.trackCustom('subscribedToNewsletter', {email: this.state.email});

            } else {
                throw new Error(response.errorMessage);
            }
            this.setState({sendingData: false});
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    renderDialogContent = () => {
        let dialogContent = '';

        if (this.state.subscriptionSucceed) {
            dialogContent = (
                <div className={importedStyles.succeedAnimationContainer}>
                    {this.renderSucceedAnimation()}
                    <Typography className={importedStyles.succeedAnimationClaim}>
                        Genial, gracias por subscribirte
                    </Typography>
                </div>
            );
        } else {
            dialogContent = (
                <React.Fragment>
                            {this.renderAnimation()}
                            <div className={importedStyles.inputAndClaimParentContainer}>
                                <Typography className={importedStyles.dialogContentText}>
                                    Subscríbete a nuestra newsletter
                                </Typography>
                                <Typography variant={"caption"} className={importedStyles.dialogContentText}>
                                    Recibe infografias relacionadas con la salud además de nuestras ofertas y promociones exclusivas
                                </Typography>
                                <div className={importedStyles.inputAndButtonContainer}>
                                    <TextField
                                        variant={"outlined"}
                                        margin="normal"
                                        label="Correo"
                                        type="email"
                                        value={this.state.email}
                                        onChange={this.handleInput}
                                        fullWidth
                                    />
                                    <Button
                                        variant={"contained"}
                                        color={"primary"} size={"large"}
                                        className={importedStyles.mainActionButton}
                                        onClick={this.handleSubscription}
                                        disabled={!this.state.isValidMail || this.state.email.length <= 0 || this.state.sendingData}
                                    >
                                        Subscribirme {this.state.sendingData && <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                                    </Button>
                                </div>
                            </div>

                </React.Fragment>
            );
        }

        return (<React.Fragment>{dialogContent}</React.Fragment>)
    };

    render() {
        return (
            <React.Fragment>
                <Card id={'newsCard'}>
                    <CardContent className={importedStyles.cardContent}>
                         {this.renderDialogContent()}
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    };
}


NewsletterSection.propTypes = {
    open: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    requestedDocument: PropTypes.func.isRequired
};
const mapStateToProps = ({snackReducer, patientReducer, authReducer}) => {

    return ({
        onAssignFlow: authReducer.onAssignFlow,

    });
};

export default connect(mapStateToProps)(withMobileDialog()(NewsletterSection));