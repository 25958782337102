import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {persistor, store} from './store/index'
import history from './helpers/history.js';
import {PersistGate} from 'redux-persist/integration/react'
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Route, Router, Switch} from 'react-router-dom';
import Landing from "./modules/Landing/Landing";
import AuthModals from "./modules/Modals/BuyModals";
import Privacy from "./modules/Privacy/Privacy";
import Detail from "./modules/Detail/Detail";
import BraceletList from "./modules/BraceletList/BraceletList";
import './App.css';
import LoginModal from "./modules/Modals/LoginModal/LoginModal";
import AddOrEditPatientModal from "./modules/Patient/PatientModal";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import ErrorIcon from '@material-ui/icons/Error';
import ReactGA from 'react-ga';
import Cookies from "./modules/Privacy/Cookies";
import Law from "./modules/Privacy/Law";
import BuyConditions from "./modules/Privacy/BuyConditions";
import codeModal from "./modules/codeModal/codeModal";
import notFound from "./modules/notFound/notFound";
import tutorialCompra from "./modules/Tutoriales/compra/tutorialCompra";
import allTutos from "./modules/Tutoriales/allTutos";
import tutorial from "./modules/Tutoriales/tutorial";
import ForgottenPasswordModal from "./modules/ForgottenPasswordModal/ForgottenPasswordModal";
import PollLanding from "./modules/PollLanding/PollLanding";
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet";
import FAQ from "./modules/FAQ/FAQ";
import OurHistory from "./modules/OurHistory/OurHistory";
import WeAreEco from "./modules/WeAreEco/WeAreEco";
import OldLanding from "./modules/oldLanding/OldLanding";
import ImpetraThanksPage from "./modules/ImpetraThanksPage/ImpetraThanksPage";

class App extends Component {

    componentWillMount() {

        //DEBUG MODE

        //PROD MODE
        ReactGA.initialize('UA-132037086-1');

        ReactPixel.init('614730442270960');

    }



    render() {

        let isIE = /*@cc_on!@*/false || !!document.documentMode;

        if (isIE) {
            return (
                <Card
                    style={{
                        width: 650,
                        margin: 0,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        msTransform: 'translate(-50%, -50%)',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <CardContent style={{textAlign: 'center'}}>
                        <ErrorIcon/>
                        <Typography component="p">
                            Oops, no ofrecemos soporte para Internet Explorer, por favor, utiliza otro navegador
                        </Typography>
                    </CardContent>
                </Card>
            );
        } else {
            return (
                <Provider store={store}>
                    <Helmet>
                        <title>PulsID</title>
                        <meta name="description" content="Protege a tus seres queridos con PulsID, nuestra pulsera contra emergencias"/>
                        <meta name="keywords"
                              content="pulsid, pulsera, pulsera niños, pulsera mayores, pulsera seguridad, emergencia, proteger, alzheimer, epilepsia, qr ,hospital, residencia"/>
                    </Helmet>
                    <PersistGate loading={<CircularProgress size={50}/>} persistor={persistor}>
                            <Router history={history}>
                                <Switch>
                                    <Route exact path="/" component={Landing}/>
                                    <Route exact path="/impetraThanksPage" component={ImpetraThanksPage}/>
                                    <Route exact path="/tienda" component={OldLanding}/>
                                    <Route exact path="/nuestraHistoria" component={OurHistory}/>
                                    <Route exact path="/compromisoEco" component={WeAreEco}/>
                                    <Route exact path="/FAQ" component={FAQ}/>
                                    <Route exact path="/register" component={AuthModals}/>
                                    <Route exact path="/editPatient" component={AddOrEditPatientModal}/>
                                    <Route exact path="/login" component={LoginModal}/>
                                    <Route exact path="/forgottenPassword" component={ForgottenPasswordModal}/>
                                    <Route exact path="/privacy" component={Privacy}/>
                                    <Route exact path="/buyConditions" component={BuyConditions}/>
                                    <Route exact path="/law" component={Law}/>
                                    <Route exact path="/clave/:braceletCode?" component={codeModal}/>
                                    <Route exact path="/codigo/:braceletCode?" component={codeModal}/>
                                    <Route exact path="/cookies" component={Cookies}/>
                                    <Route exact path="/users/:id/:noNotify?" component={Detail}/>
                                    <Route exact path="/pollLanding/:userEmail/:answer" component={PollLanding}/>
                                    <Route exact path="/list" component={BraceletList}/>
                                    <Route exact path="/tutorial" component={tutorial}/>
                                    <Route exact path="/compra" component={tutorialCompra}/>
                                    <Route exact path="/tutoriales" component={allTutos}/>
                                    <Route component={notFound}/>
                                </Switch>
                            </Router>
                    </PersistGate>
                </Provider>
            );
        }

    }
}


export default App;
