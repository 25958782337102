import {
    LOGIN_SUCCESS,
    LOGOUT,
    ACCEPT_COOKIES,
    SHOW_BUY_MODAL,
    SET_CREATING_NEW_USER_FLAG, SET_ASSIGN_FLOW_FLAG
} from "../../constants/action-types";

const initialState = {
    user: {},
    logged: false,
    showCookiesBanner: true,
    showBuyModal: false,
    creatingNewUser: false,
    onAssignFlow: false,
};

const modalsReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOGIN_SUCCESS:
            return {...state, user: action.user, logged: true};
        case LOGOUT:
            return {...state, user: {}, logged: false};
        case ACCEPT_COOKIES:
            return {...state, showCookiesBanner: false};
        case SHOW_BUY_MODAL:
            return {...state, showBuyModal: action.showBuyModal};
        case SET_CREATING_NEW_USER_FLAG:
            return {...state, creatingNewUser: action.creatingNewUser};
        case SET_ASSIGN_FLOW_FLAG:
            return {...state, onAssignFlow: action.onAssignFlow};

        default:
            return state;
    }
};


export default modalsReducer;