import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from "@material-ui/core/TextField/TextField";
import importedStyles from "./MedicalDataForm.module.sass";
import {connect} from "react-redux";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import Grid from "@material-ui/core/Grid/Grid";
import {
    patient_emergency_steps_handle_modal,
    patient_handle_modal,
    set_sending_data,
    update_documents_data,
    update_medic_data
} from "../../PatientActions";
import MobileStepper from "@material-ui/core/MobileStepper/MobileStepper";
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Button from '@material-ui/core/Button';
import baseRoute from "../../../../helpers/baseRoute";
import {showSnack, snackTypes} from "../../../../components/Snack/SnackActions";
import {update_list} from "../../../BraceletList/BraceletListActions";
import ChipInput from "../../../../components/ChipInput/ChipInput";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Tour from "reactour";
import {set_bracelet_entity} from "../../../Modals/AssignMailModal/AssignActions";


class MedicalDataForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sendingData: false,
            open: true,
            allergies: this.props.allergies ? this.props.allergies : [],
            medicines: this.props.medicines ? this.props.medicines : [],
            diagnosis: this.props.diagnosis ? this.props.diagnosis : [],
            observations: this.props.observations,
            isTourOpen: true,
            uxTutorialSteps: [
                {
                    position: 'right',
                    selector: '.userTutorialTarget',
                    content: (
                        <div className={importedStyles.tutoWrapper}>
                            <Typography variant={"subheading"} gutterBottom>Apunta las dolencias o tratamientos<br/>que tiene la persona que llevará la pulsera</Typography>
                            <div className={importedStyles.tutoImgWrapper}>
                                <img height={350} alt={'preview datos basicos'}
                                       src={require('../../../../img/previewMedicalData.png')}
                                />
                            </div>
                            <Button color={"primary"} variant={"contained"}
                                    onClick={() => this.setState({isTourOpen: false})}>Entendido</Button>
                        </div>
                    ),
                }
            ]
        };
    }

    componentWillUnmount() {
       //this.props.dispatch(update_medic_data(this.state.diagnosis, this.state.medicines, this.state.allergies, this.state.observations));
    }

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    sendPatientData() {

        //this.setState({sendingData: true});
        this.props.dispatch(set_sending_data(true));

        fetch(baseRoute + 'api/patients/createNewPatient', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userUuid: this.props.userUuid,
                    braceletUuid: this.props.braceletUuid,
                    patientPhoto: this.props.patientPhoto,
                    medicines: this.state.medicines,
                    allergies: this.state.allergies,
                    observations: this.state.observations,
                    diagnosis: this.state.diagnosis,
                    address: this.props.address,
                    name: this.props.name,
                    surname: this.props.surname,
                    bornDate: this.props.bornDate,
                    relationship: this.props.relationship,
                    documents: this.props.documents,
                    emergencyContacts: this.props.emergencyContacts,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                //this.setState({sendingData: false});
                this.props.dispatch(set_sending_data(false));
                //Mostrar snack
                this.props.dispatch(showSnack('Datos asignados', snackTypes.success));
                //Añadir name a la bracelet list pertinente
                this.props.dispatch(update_list(response.list));
                // cerrar modal
                this.props.dispatch(patient_handle_modal(0));
                //Limpiar datos de redux
                this.props.dispatch(update_medic_data([],[],[],''));
                this.props.dispatch(update_documents_data([]));

                //If coming from assign bracelet logic and you are creating a different user than the actual logged one, open emergency_steps modal
                // if not just redirect to bracelet list
                if (this.props.creatingNewUser || this.props.onAssignFlow) {
                    if (response.braceletEntity){
                        this.props.dispatch(set_bracelet_entity(response.braceletEntity))
                    }
                    this.props.dispatch(patient_emergency_steps_handle_modal());
                }

            } else {
                throw new Error('error');
            }
        }).catch((error) => {
            //this.setState({sendingData: false});
            this.props.dispatch(set_sending_data(false));
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    editPatientData() {
        //this.setState({sendingData: true});
        this.props.dispatch(set_sending_data(true));

        fetch(baseRoute + 'api/patients/editPatient', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userUuid: this.props.userUuid,
                    braceletUuid: this.props.braceletUuid,
                    medicines: this.state.medicines,
                    allergies: this.state.allergies,
                    observations: this.state.observations,
                    diagnosis: this.state.diagnosis,
                    address: this.props.address,
                    name: this.props.name,
                    surname: this.props.surname,
                    bornDate: this.props.bornDate,
                    patientPhoto: this.props.patientPhoto,
                    relationship: this.props.relationship,
                    documents: this.props.documents,
                    emergencyContacts: this.props.emergencyContacts,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {

            response = JSON.parse(response);

            if (response.error === false) {
                //this.setState({sendingData: false});
                this.props.dispatch(set_sending_data(false));

                //Mostrar snack
                this.props.dispatch(showSnack('Datos editados', snackTypes.success));
                //Añadir name a la bracelet list pertinente
                this.props.dispatch(update_list(response.list));
                // cerrar modal
                this.props.dispatch(patient_handle_modal(0));
                //Limpieza de datos redux
                this.props.dispatch(update_documents_data([]));


            } else {
                throw new Error('error');
            }
        }).catch((error) => {
            //this.setState({sendingData: false});
            this.props.dispatch(set_sending_data(false));
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    handleAllergiesChipDelete(value) {
        let chipsArray = this.state.allergies;
        let index = chipsArray.indexOf(value);
        chipsArray.splice(index, 1);

        this.setState({allergies: chipsArray});
    }

    handleDiagnosisChipDelete(value) {
        let chipsArray = this.state.diagnosis;
        let index = chipsArray.indexOf(value);
        chipsArray.splice(index, 1);

        this.setState({diagnosis: chipsArray});
    }

    handleMedicinesChipDelete(value) {
        let chipsArray = this.state.medicines;
        let index = chipsArray.indexOf(value);
        chipsArray.splice(index, 1);

        this.setState({medicines: chipsArray});
    }

    displayUserTutorial(){
        if (this.props.creatingNewUser){
            return (
                <Tour
                    highlightedMaskClassName={importedStyles.UxTutoMask}
                    steps={this.state.uxTutorialSteps}
                    isOpen={this.state.isTourOpen}
                    rounded={10}
                    maskSpace={5}
                    showCloseButton={false}
                    disableInteraction={true}
                    showNavigation={false}
                    showButtons={false}
                    showNumber={false}
                    disableDotsNavigation={true}
                    onRequestClose={() => this.setState({isTourOpen: false})}
                    className={importedStyles.tutoParentWrapper}
                />
            )
        }
    }


    render() {

        return (
            <div>
                <DialogContentText>
                    <Typography gutterBottom variant={"body"}>Cuantos más datos añadas más te podrá ayudar tu pulsera en caso de emergencia. </Typography>
                    <Typography gutterBottom variant={"subheading"}>Añade los datos médicos de quien llevará la pulsera.</Typography>
                </DialogContentText>
                <Grid container spacing={16} className={importedStyles.gridContainer}>
                    <div className={'userTutorialTarget'}>
                    {this.props.onAssignFlow && this.displayUserTutorial()}

                    <Grid item xs={12} sm={12}>
                        <ChipInput
                            value={this.state.diagnosis}
                            label={'Diagnóstico'}
                            helperText={'Introduce cada diagnóstico / enfermedad separado por comas'}
                            chipKeys={[',', '.']}
                            onChange={(chips) => this.setState({diagnosis: chips})}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <ChipInput
                            value={this.state.medicines}
                            label={'Medicación'}
                            helperText={'Introduce cada medicación separada por comas'}
                            chipKeys={[',', '.']}
                            onChange={(chips) => this.setState({medicines: chips})}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <ChipInput
                            value={this.state.allergies}
                            label={'Alergias'}
                            helperText={'Introduce cada alergia separada por comas'}
                            chipKeys={[',', '.']}
                            onChange={(chips) => this.setState({allergies: chips})}/>
                    </Grid>
                    </div>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            label="Observaciones"
                            multiline
                            rows="6"
                            className={importedStyles.textField}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            name={"observations"}
                            defaultValue={this.state.observations}
                            onChange={this.handleInputChange}
                            inputProps={{
                                maxLength: 350
                            }}
                        />
                    </Grid>
                </Grid>
                <MobileStepper
                    className={importedStyles.stepper}
                    variant="dots"
                    steps={4}
                    position="static"
                    activeStep={this.props.activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={this.props.editing ? () => this.editPatientData() : () => this.sendPatientData()}
                            disabled={this.props.sendingData}
                        >
                            Listo <KeyboardArrowRight/>
                            {this.props.sendingData &&
                            <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={this.props.handleBack}
                            disabled={this.state.activeStep === 0}
                        >
                            <KeyboardArrowLeft/>
                            Atrás
                        </Button>
                    }
                />

            </div>
        );
    }
}

MedicalDataForm.propTypes = {
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
};
const mapStateToProps = ({snackReducer, patientReducer, authReducer}) => {
    return ({
        userUuid: authReducer.user.uuid,
        onAssignFlow: authReducer.onAssignFlow,
        creatingNewUser: authReducer.creatingNewUser,
        snackData: snackReducer.data,
        medicines: patientReducer.medicines,
        allergies: patientReducer.allergies,
        observations: patientReducer.observations,
        relationship: patientReducer.relationship,
        diagnosis: patientReducer.diagnosis,
        address: patientReducer.address,
        name: patientReducer.name,
        surname: patientReducer.surname,
        bornDate: patientReducer.bornDate,
        patientPhoto: patientReducer.patientPhoto,
        documents: patientReducer.documents,
        emergencyContacts: patientReducer.emergencyContacts,
        braceletUuid: patientReducer.braceletUuid,
        editing: patientReducer.editing,
        activeStep: patientReducer.activeStep,
        sendingData: patientReducer.sendingData,
    });
};
export default connect(mapStateToProps)(withMobileDialog()(MedicalDataForm));