import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField/TextField";
import Typography from "@material-ui/core/Typography/Typography";
import importedStyles from "./LoginModal.module.sass";
import baseRoute from "../../../helpers/baseRoute";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import {connect} from "react-redux";
import Snack from "../../../components/Snack/Snack";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton/IconButton";
import {login_success} from "../ModalsActions";
import history from "../../../helpers/history";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import Grid from "@material-ui/core/Grid/Grid";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import {close_assign_modals} from "../../Patient/PatientActions";
import ReactPixel from "react-facebook-pixel";
const jwt = require('jsonwebtoken');


class LoginModal extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            sendingData: false,
            email: '',
            password: '',
            showPassword: false,
        };
    }

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    disableTabTitleChanger = () => {
        window.onblur = () =>{};
    };


    handleLogin = () => {
        this.setState({sendingData: true});

        fetch(baseRoute + 'api/users/handleLogin', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: this.state.email,
                    password: this.state.password
                }),
            }
        ).then((responseJSON) => {
            if (responseJSON.ok){
                return responseJSON.text();
            } else {
                throw Error(`Request rejected with status ${responseJSON.status}`);
            }
        }).then((response) => {
            let results = JSON.parse(response);
            if (!results.error) {
                //this.disableTabTitleChanger();
                this.props.dispatch(showSnack('¡Bienvenido!', snackTypes.success));
                this.props.dispatch(login_success(results.user));
                this.props.dispatch(close_assign_modals());
                let token = jwt.sign({data: {auth: true}}, 'secret', { expiresIn: '20m' });

                localStorage.setItem('AuthToken', token);
                ReactPixel.trackCustom('userLogged', {email: this.state.email});
                this.props.handleModal();
                history.push('/list');
            } else {
                switch (results.errorType) {
                    case 'email':
                        this.props.dispatch(showSnack('No existe ningún usuario con ese email', snackTypes.error));
                        break;
                    case 'password':
                        this.props.dispatch(showSnack('Contraseña incorrecta', snackTypes.error));
                        break;
                    default:
                        this.props.dispatch(showSnack('Ha ocurrido un error verificando los datos, por favor, vuelve a intentarlo dentro de unos minutos', snackTypes.error));
                        break;
                }
            }

            this.setState({sendingData: false});
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error verificando los datos, por favor, vuelve a intentarlo dentro de unos minutos', snackTypes.error));
        });
    };

    render() {
        const {fullScreen} = this.props;

        return (
            <React.Fragment>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.open}
                    onClose={this.props.handleModal}
                    disableBackdropClick
                >
                    <DialogTitle>
                        {"Iniciar sesión"}
                        <IconButton onClick={this.props.handleModal} className={importedStyles.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={importedStyles.flexColumn}>
                        <DialogContentText>
                            Accede con tu correo
                        </DialogContentText>
                        <Grid container spacing={16} style={{marginTop: 15}}>

                            <Grid item xs={12} sm={12}>
                                <TextField
                                    className={importedStyles.textField}
                                    autoFocus
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Correo"
                                    name={"email"}
                                    type="text"
                                    value={this.state.email}
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    onKeyPress={(e) => {
                                        if (e.which === 13) {
                                            if (this.state.email !== '' && this.state.password !== '') {
                                                this.handleLogin();
                                            }
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <TextField
                                    className={importedStyles.textField}
                                    margin="normal"
                                    variant="outlined"
                                    label="Contraseña"
                                    name={"password"}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.password}
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    onKeyPress={(e) => {
                                        if (e.which === 13) {
                                            if (this.state.email !== '' && this.state.password !== '') {
                                                this.handleLogin();
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                className={importedStyles.textFieldAdorn}
                                                position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={importedStyles.flexColumn}>
                        <Button
                            className={importedStyles.buttonSubmit}
                            variant={"contained"}
                            fullWidth
                            onClick={this.handleLogin}
                            disabled={this.state.sendingData || !this.state.password.length > 0 || !this.state.email.length > 0}
                        >
                            Acceder {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                        <Typography variant={'caption'} className={importedStyles.forgottenPasswordText}>
                            <a href={'/forgottenPassword'} target={'_blank'}> ¿Has olvidado tu contraseña? </a>
                        </Typography>
                    </DialogActions>
                </Dialog>
                <Snack/>
            </React.Fragment>
        );
    }
}

LoginModal.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
};
const mapStateToProps = ({snackReducer}) => {
    return ({
        snackData: snackReducer.data,
    });
};
export default connect(mapStateToProps)(withMobileDialog()(LoginModal));
