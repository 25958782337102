import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card/Card';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import PlacesAutocomplete from 'react-places-autocomplete';
import FormControl from "@material-ui/core/FormControl/FormControl";
import importedStyles from "./BuyModals.module.sass";
import baseRoute from "../../helpers/baseRoute";
import ArrowForward from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import WarningIcon from '@material-ui/icons/WarningRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import TrashIcon from '@material-ui/icons/DeleteForeverRounded';
import LabelIcon from '@material-ui/icons/NavigateNext';
import getCountryCodes from "../../helpers/getCountryCodes";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import {connect} from "react-redux";
import Snack from "../../components/Snack/Snack";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import Grid from "@material-ui/core/Grid/Grid";
import Validators from "../../helpers/validators";
import SnackbarContent from "@material-ui/core/es/SnackbarContent/SnackbarContent";
import Snackbar from "@material-ui/core/es/Snackbar/Snackbar";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/es/Typography/Typography";
import ReactPixel from "react-facebook-pixel";
import loadingPaymentRequest from "../../animations/loadingPaymentRequest";
import redirectToSecure from "../../animations/redirectToSecure";
import check from "../../animations/check";
import loadingDots from "../../animations/loadingDots";
import Lottie from "react-lottie";
import Image from "react-image-webp";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import Countdown, {zeroPad} from "react-countdown-now";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import Skeleton from "react-loading-skeleton";
import {isWebpSupported} from 'react-image-webp/dist/utils';
import ReactGA from "react-ga";
//import importedStyles from "../Landing/Landing.module.sass";

//////////////////////////

const braceletPrice = 19.95;
const packagingPrice = 2;

const shippingPrice = 3.75;
const stockS = Math.floor(Math.random() * (15 - 9)) + 9;
const stockM = Math.floor(Math.random() * (9 - 5)) + 5;
const stockL = Math.floor(Math.random() * (15 - 9)) + 9;

class BuyModals extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.user && props.newBracelet) {
            this.state = {
                validMail: true,
                sendingData: false,
                openRegisterModal: false,
                openPaymentModal: false,
                privacityTermsCheck: false,
                address: this.props.user.addresses && this.props.user.addresses[0] ? this.props.user.addresses[0].address : '',
                zipcode: this.props.user.addresses && this.props.user.addresses[0] ? this.props.user.addresses[0].zipCode : '',
                email: this.props.user.mail ? this.props.user.mail : '',
                name: this.props.user.name ? this.props.user.name : '',
                documentId: this.props.user.documentId ? this.props.user.documentId : '',
                braceletsS: 0,
                braceletsM: 0,
                braceletsL: 0,
                totalBracelets: 0,
                includeShipping: 'free',
                discount: this.props.promoCode ? this.props.promoCode : '',
                discountObject: '',
                giftStickerContent: '',
                city: this.props.user.addresses && this.props.user.addresses[0] ? this.props.user.addresses[0].city : '',
                province: this.props.user.addresses && this.props.user.addresses[0] ? this.props.user.addresses[0].region : '',
                country: this.props.user.addresses && this.props.user.addresses[0] ? this.props.user.addresses[0].country : '',
                openSnackTotalBracelets: false,
                includePackaging: false,
                packagingQuantity: 1,
                firstCartAddition: true,
                firstCheckoutResume: true,
                waitingForPaymentRequestPackage: false,
                waitingForRedsysRedirect: false,
                openLoadingPaymentRequestModal: false,
                elementFontSize: window.innerWidth < 450 ? '14px' : '18px',
                pageHasUserData: false,
                currentStep: 1,
                countImagesLoaded: 0,
                errorTwoForOne: false,
            };
        } else {
            this.state = {
                validMail: true,
                sendingData: false,
                openRegisterModal: false,
                openPaymentModal: false,
                privacityTermsCheck: false,
                address: '',
                zipcode: '',
                email: '',
                name: '',
                documentId: '',
                braceletsS: 0,
                braceletsM: 0,
                braceletsL: 0,
                totalBracelets: 0,
                includeShipping: 'free',
                discount: this.props.promoCode ? this.props.promoCode : '',
                discountObject: '',
                giftStickerContent: '',
                city: '',
                province: '',
                country: 'spain',
                includePackaging: false,
                packagingQuantity: 1,
                firstCartAddition: true,
                openSnackTotalBracelets: false,
                firstCheckoutResume: true,
                waitingForPaymentRequestPackage: false,
                waitingForRedsysRedirect: false,
                openLoadingPaymentRequestModal: false,
                elementFontSize: window.innerWidth < 450 ? '14px' : '18px',
                pageHasUserData: false,
                currentStep: 1,
                countImagesLoaded: 0,
                errorTwoForOne: false,
            };
        }

        this.handleBraceletSelect = this.handleBraceletSelect.bind(this);
        this.handlePaymentModal = this.handlePaymentModal.bind(this);
    }

    componentDidMount(): void {
        if (this.props.promoCode) {
            this.checkDiscount();
        }
        this.animateCSS('.packagingInputs', 'fadeInLeft');
        //Listen to page close to avoid accidental closing or refreshing
        window.onbeforeunload = (e) => this.handlePossibleAccidentalExit(e);
    }

    animateCSS(element, animationName, callback) {
        const node = document.querySelector(element);
        if (node) {
            node.classList.add('animated', animationName);

            function handleAnimationEnd() {
                node.classList.remove('animated', animationName);
                node.removeEventListener('animationend', handleAnimationEnd);

                if (typeof callback === 'function') callback()
            }

            node.addEventListener('animationend', handleAnimationEnd)
        }

    }

    handlePossibleAccidentalExit = (e) => {
        if (this.state.pageHasUserData) {
            e.preventDefault(); //for the standard
            e.returnValue = ''; //required for Chrome
        }
    };

    validateMail = () => {
        this.setState({validMail: Validators.validateEmail(this.state.email)});
    };

    handleInputChange = (event) => {
        let onlyLettersRegex = /^[A-Za-zÀ-ÿ ]+$/;
        let onlyNumbersRegex = /^[0-9]+$/;
        let alphanumericRegex = /^[A-Za-z0-9]+$/;


        if (event.target.value.length > 0) {
            switch (event.target.name) {
                case 'includeShipping':
                    this.setState({includeShipping: event.target.value});
                    break;
                case 'name':
                    if (onlyLettersRegex.test(event.target.value)) {
                        this.setState({[event.target.name]: event.target.value});
                    }
                    break;
                case 'zipcode':
                    if (onlyNumbersRegex.test(event.target.value)) {
                        this.setState({[event.target.name]: event.target.value});
                    }
                    break;
                case 'documentId':
                    if (alphanumericRegex.test(event.target.value)) {
                        let documentId = event.target.value.toUpperCase();
                        this.setState({[event.target.name]: documentId});
                    }
                    break;
                default:
                    this.setState({[event.target.name]: event.target.value});
                    break;
            }

        } else {
            this.setState({[event.target.name]: event.target.value});
        }

        this.setState({pageHasUserData: true});
    };

    handleGiftStickerContent = (e) => {
        let value = e.target.value;
        this.setState({giftStickerContent: value});
    };

    handlePackagingQuantity = (e) => {
        let value = e.target.value;
        let regex = /^[1-9]+$/;
        if (regex.test(value) && value.length === 1 && value <= this.state.totalBracelets) {
            this.setState({packagingQuantity: e.target.value});
        }
        this.setState({pageHasUserData: true});
    };

    handleCityChange = city => {
        let comaPosition = city.indexOf(',');
        if (comaPosition !== -1) {
            city = city.substring(0, comaPosition);
        }
        this.setState({city, pageHasUserData: true});
    };

    handleProvinceChange = province => {
        let comaPosition = province.indexOf(',');
        if (comaPosition !== -1) {
            province = province.substring(0, comaPosition);
        }
        this.setState({province, pageHasUserData: true});
    };

    renderAutocompleteProvinceInput() {
        const countryCode = getCountryCodes(this.state.country);
        const searchOptions = {
            componentRestrictions: {country: countryCode},
            types: ["(regions)"]
        };

        return (
            <PlacesAutocomplete
                searchOptions={searchOptions}
                value={this.state.province}
                onChange={this.handleProvinceChange}
                autoComplete={"nope"}
            >
                {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
                    <div className={importedStyles.autocompleteLocationInputs}>
                        <TextField
                            className={importedStyles.textField}
                            variant={"outlined"}
                            margin="dense"
                            name={"province"}
                            label="Provincia"
                            type="text"
                            fullWidth
                            {...getInputProps({
                                autoComplete: "nope"
                            })}
                        />

                        <Card>
                            {loading && <div>Generando sugerencias...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? {backgroundColor: '#c4c4c4', cursor: 'pointer', marginBottom: 5}
                                    : {backgroundColor: '#ffffff', cursor: 'pointer', marginBottom: 5};
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </Card>
                    </div>
                )}
            </PlacesAutocomplete>
        )
    }

    renderAutocompleteCityInput() {
        const countryCode = getCountryCodes(this.state.country);
        const searchOptions = {
            componentRestrictions: {country: countryCode},
            types: ["(cities)"]
        };

        return (
            <PlacesAutocomplete
                value={this.state.city}
                onChange={this.handleCityChange}
                searchOptions={searchOptions}
            >
                {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
                    <React.Fragment>
                        <TextField
                            className={importedStyles.textField}
                            variant={"outlined"}
                            margin="dense"
                            name={"city"}
                            label="Ciudad"
                            type="text"
                            fullWidth
                            {...getInputProps({
                                autoComplete: "nope"
                            })}
                        />

                        <div>
                            {loading && <div>Generando sugerencias...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? {backgroundColor: '#c4c4c4', cursor: 'pointer', marginBottom: 5}
                                    : {backgroundColor: '#ffffff', cursor: 'pointer', marginBottom: 5};
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </React.Fragment>
                )}
            </PlacesAutocomplete>
        )
    }

    handlePaymentModal() {
        this.setState({
            openPaymentModal: !this.state.openPaymentModal,
        });
    }

    handleCheckoutResume = () => {
        if (this.state.firstCheckoutResume) {
            ReactPixel.trackCustom('CheckoutResume');
            ReactGA.ga('send', 'event', 'click', 'CheckOutResume');

            this.setState({firstCheckoutResume: false});
        }
        this.handlePaymentModal(); //Open payment data and checkout resume modal
        this.props.handleModal(); //Close products selector modal
    };

    handlePaymentRequest = () => {
        window.onbeforeunload = () => {
            //blank function do nothing
        };
        this.setState({sendingData: true, waitingForPaymentRequestPackage: true});
        this.handleLoadingPaymentRequestModal();

        let email = this.state.email;
        let name = this.state.name;
        let documentId = this.state.documentId;
        let discountId = this.state.discountObject ? this.state.discountObject.uuid : '';
        let address = this.state.address;
        let country = this.state.country;
        let province = this.state.province;
        let city = this.state.city;
        let zipcode = this.state.zipcode;
        let braceletsS = this.state.braceletsS;
        let braceletsM = this.state.braceletsM;
        let braceletsL = this.state.braceletsL;
        let includePackaging = this.state.includePackaging;
        let giftStickerContent = this.state.giftStickerContent;
        let packagingQuantity = this.state.includePackaging ? this.state.packagingQuantity : 0;
        let includeShipping = this.state.includeShipping === 'premium';

        ReactPixel.track('InitiateCheckout', {braceletsQuantity: braceletsL + braceletsM + braceletsS});
        ReactGA.ga('send', 'event', 'click', 'InitPayment');


        fetch(baseRoute + 'api/payments/createPaymentRequest', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: email,
                name: name,
                documentId: documentId,
                discountId: discountId,
                address: address,
                country: country,
                province: province,
                city: city,
                zipcode: zipcode,
                totalBracelets: parseInt(braceletsS) + parseInt(braceletsM) + parseInt(braceletsL),
                braceletsS: braceletsS,
                braceletsM: braceletsM,
                braceletsL: braceletsL,
                packaging: includePackaging,
                packagingQuantity: packagingQuantity,
                giftStickerContent: giftStickerContent,
                newBracelet: this.props.newBracelet,
                includeShipping: includeShipping,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((resp) => {
            const response = JSON.parse(resp);
            if (response.mailError) {
                this.props.dispatch(showSnack('¡Ya existe un usuario con este email!', snackTypes.error));
                this.setState({openLoadingPaymentRequestModal: false})
            } else if (response.usedDiscountError) {
                this.props.dispatch(showSnack('Ese codigo ha sido usado con anterioridad', snackTypes.error));
                this.setState({openLoadingPaymentRequestModal: false})
            } else {

                this.setState({
                    Ds_SignatureVersion: response.Ds_SignatureVersion,
                    Ds_MerchantParameters: response.Ds_MerchantParameters,
                    Ds_Signature: response.Ds_Signature,
                });

                //Change loadingAnimation to check animation, rotate dots animation and display it
                setTimeout(() => {
                    this.setState({waitingForPaymentRequestPackage: false});
                    document.getElementById('dotsAnimation').style.transform = 'rotate(90deg)';
                    document.getElementById('redsysAnimationContainer').style.opacity = 1;
                    //Redirect to redsys after 3s
                    setTimeout(() => {
                        document.getElementById('redsysForm').submit();
                    }, 2500);
                }, 2500);

            }
            this.setState({sendingData: false});
        }).catch((error) => {
            console.error('Error: ', error);
            this.setState({sendingData: false, openLoadingPaymentRequestModal: false});
        });

    };

    increaseOrderId() {
        fetch(baseRoute + 'api/payments/increaseOrderId', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                apiKey: 'M3QWZk`V|~hoB@vo{af{gy4aCz7,;N'
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((resp) => {
            // const response = JSON.parse(resp);
        });
    }

    handleBraceletSelectMobile = (action, target) => () => {

        this.setState({pageHasUserData: true});

        let nextValue = this.state[target];

        switch (action) {
            case 'add':
                nextValue = nextValue + 1;
                break;
            case 'remove':
                nextValue = nextValue - 1;
                break;
            default:
                //do nothing
                break;
        }

        let event = {
            target: {
                name: target,
                value: nextValue
            }
        };

        this.handleBraceletSelect(event);
    };

    handleBraceletSelect(event) {
        this.setState({
            openSnackTotalBracelets: false,
            pageHasUserData: true
        });

        if (this.state.firstCartAddition) {
            ReactPixel.track('AddToCart');
            this.setState({firstCartAddition: false});
        }

        if (event.target.value === '') {
            this.setState({[event.target.name]: event.target.value});
            this.setState({
                totalBracelets: parseInt(this.state.totalBracelets) - (this.state[event.target.name] ? parseInt(this.state[event.target.name]) : 0)
            });

        } else {
            if (event.target.value >= 0 && event.target.value < 6) {
                if ((parseInt(this.state.totalBracelets) - (this.state[event.target.name] ? parseInt(this.state[event.target.name]) : 0) + parseInt(event.target.value)) <= 5) {
                    this.setState({[event.target.name]: event.target.value});
                    this.setState((state) => ({
                        totalBracelets: (state.braceletsS ? parseInt(state.braceletsS) : 0) + (state.braceletsM ? parseInt(state.braceletsM) : 0) + (state.braceletsL ? parseInt(state.braceletsL) : 0),
                    }));

                } else {
                    if (!this.state.openSnackTotalBracelets) {
                        this.setState({openSnackTotalBracelets: true});
                    }
                }
            } else if (!this.state.openSnackTotalBracelets && event.target.value > 0) {
                this.setState({
                    openSnackTotalBracelets: true,
                });
            }
        }

        //Every change on the selectors must check packaging quantity and change it if it's greater than totalBracelets.
        let totalBracelets = 0;
        switch (event.target.name) {
            case 'braceletsS':
                totalBracelets = parseInt(this.state.braceletsM) + parseInt(this.state.braceletsL) + parseInt(event.target.value);
                break;
            case 'braceletsM':
                totalBracelets = parseInt(this.state.braceletsS) + parseInt(this.state.braceletsL) + parseInt(event.target.value);
                break;
            case 'braceletsL':
                totalBracelets = parseInt(this.state.braceletsS) + parseInt(this.state.braceletsM) + parseInt(event.target.value);
                break;
            default:
                totalBracelets = 0;

        }
        if (totalBracelets < this.state.packagingQuantity && totalBracelets > 0) {
            this.setState({packagingQuantity: totalBracelets});
        }

    }

    checkDiscount = () => {
        this.setState({sendingData: true, errorTwoForOne: false});
        fetch(baseRoute + 'api/discounts/checkDiscount', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    code: this.state.discount,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({discount: '', sendingData: false});
                if (response.discountObject.type === '2x1' && this.state.totalBracelets < 2) {
                    this.setState({errorTwoForOne: true});
                    this.props.dispatch(showSnack('Para usar este descuento debes seleccionar al menos 2 pulseras', snackTypes.warning));
                } else {
                    this.setState({discountObject: response.discountObject});
                }

                //show snack only if coming from promo links
                if (this.props.promoCode) {
                    this.props.dispatch(showSnack('¡Selecciona tu talla y obtén tu descuento promocional!', 'success'));
                }
            } else {
                throw new Error('error');
            }
        }).catch((error) => {
            this.setState({discount: '', sendingData: false});
            this.props.dispatch(showSnack('El código promocional no es válido', snackTypes.error));
        });
    };

    getTotalPrice = () => {
        let discountValue = 0;

        let totalValue = this.state.totalBracelets * braceletPrice;

        if (this.state.includePackaging) {
            totalValue = totalValue + (packagingPrice * this.state.packagingQuantity);
        }
        if (this.state.includeShipping === 'premium') {
            totalValue = totalValue + shippingPrice;
        }


        if (this.state.discountObject) {
            switch (this.state.discountObject.type) {
                case '€':
                    discountValue = this.state.discountObject.value;
                    break;
                case '%':
                    discountValue = (totalValue * (this.state.discountObject.value / 100)).toFixed(2);
                    break;
                case '2x1':
                    if (this.state.totalBracelets > 1) {
                        totalValue = totalValue - braceletPrice;
                    } else {
                        this.setState({errorTwoForOne: true});
                    }
                    break;
                default:
                    break;
            }
        }
        return (totalValue - discountValue).toFixed(2);
    };

    hideImagePreloadSkeleton = size => {
        let skeleton = document.getElementById('skeleton' + size);
        if (skeleton) {
            skeleton.style.display = 'none';
        }
        let image = document.getElementById('pulsidImage' + size);
        if (image) {
            image.style.display = 'block';
        }
    };

    renderPulsidImageWebp = size => {
        return (
            <img id={'pulsidImage' + size} style={{display: 'none'}} src={require('../../img/pulsid' + size + '.webp')}
                 className={importedStyles.sizeCardImg} alt={'pulsid pulsera' + size}
                 onLoad={() => this.hideImagePreloadSkeleton(size)}/>);
    };
    renderPulsidImage = size => {
        return (
            <img id={'pulsidImage' + size} style={{display: 'none'}} src={require('../../img/pulsid' + size + '.jpg')}
                 className={importedStyles.sizeCardImg} alt={'pulsid pulsera' + size}
                 onLoad={() => this.hideImagePreloadSkeleton(size)}/>);
    };

    renderSizeSelector() {
        const styles = [importedStyles.textField, importedStyles.sizePicker];
        return (
            <Grid container className={importedStyles.sizeCardContainer}>
                <Grid item xs={12} md={3} className={importedStyles.sizeCard}>
                    {/*  <Carousel showArrows={true} showThumbs={false} showStatus={false} infiniteLoop emulateTouch className={importedStyles.productImgCarousel}>
                        <div>
                            <img
                                //className={importedStyles.sizeCardImg}
                                src={require('../../img/pulsidL.png')}
                                alt={'pulsid pulsera'}
                            />
                        </div>
                        <div>
                            <img
                                //className={importedStyles.sizeCardImg}
                                src={require('../../img/pruebas2.jpg')}
                                alt={'pulsid pulsera'}
                            />
                        </div>
                        <div>
                            <img
                                //className={importedStyles.sizeCardImg}
                                src={require('../../img/pruebas3.jpg')}
                                alt={'pulsid pulsera'}
                            />
                        </div>
                    </Carousel>*/}
                    <div className={importedStyles.sizeCardImg} id={'skeletonL'}>
                        <Skeleton height={'10rem'} width={'100%'}/>
                    </div>
                    {isWebpSupported() ? this.renderPulsidImageWebp('L') : this.renderPulsidImage('L')}

                    <div className={importedStyles.sizeCardSize}>
                        <span className={importedStyles.braceletSizeCode}>L</span>
                        <div className={importedStyles.braceletSizeBox}>
                            <span>202 mm</span>
                        </div>
                        <span>Para las muñecas  más grandes</span>
                    </div>
                    <div className={importedStyles.sizeSelectorActions}>
                        <IconButton onClick={this.handleBraceletSelectMobile('remove', 'braceletsL')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <RemoveIcon/>
                        </IconButton>
                        <TextField
                            className={styles}
                            variant={"outlined"}
                            margin="normal"
                            label="Cantidad"
                            name={"braceletsL"}
                            type="number"
                            value={this.state.braceletsL}
                            onChange={this.handleBraceletSelect}
                        />
                        <IconButton onClick={this.handleBraceletSelectMobile('add', 'braceletsL')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <AddIcon/>
                        </IconButton>
                    </div>
                    <p className={importedStyles.sizeStock}>Sólo quedan {stockL} en stock</p>
                </Grid>
                <Grid item xs={12} md={3} className={importedStyles.sizeCard}>
                    {/*  <Carousel showArrows={true} showThumbs={false} showStatus={false} infiniteLoop emulateTouch className={importedStyles.productImgCarousel}>
                        <div>
                            <img
                                src={require('../../img/pulsidM.jpg')}
                                alt={'pulsid pulsera'}
                            />
                        </div>
                        <div>
                            <img
                                src={require('../../img/pruebas2.jpg')}
                                alt={'pulsid pulsera'}
                            />
                        </div>
                        <div>
                            <img
                                src={require('../../img/pruebas3.jpg')}
                                alt={'pulsid pulsera'}
                            />
                        </div>
                    </Carousel>*/}
                    {/*<Image
                        id={'pulsidImageM'}
                        className={importedStyles.sizeCardImg}
                        src={require('../../img/pulsidM.jpg')}
                        webp={require('../../img/pulsidM.webp')}
                        alt={'pulsid pulsera'}
                        onLoad={()=>this.setPulsidImageLoaded('M')}
                    />*/}
                    <div className={importedStyles.sizeCardImg} id={'skeletonM'}>
                        <Skeleton height={'10rem'} width={'100%'}/>
                    </div>
                    {isWebpSupported() ? this.renderPulsidImageWebp('M') : this.renderPulsidImage('M')}
                    <div className={importedStyles.sizeCardSize}>
                        <span className={importedStyles.braceletSizeCode}>M</span>
                        <div className={importedStyles.braceletSizeBox}>
                            <span>180 mm</span>
                        </div>
                        <span>Para el adulto medio</span>
                    </div>
                    <div className={importedStyles.sizeSelectorActions}>
                        <IconButton onClick={this.handleBraceletSelectMobile('remove', 'braceletsM')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <RemoveIcon/>
                        </IconButton>
                        <TextField
                            className={styles}
                            variant={"outlined"}
                            margin="normal"
                            label="Cantidad"
                            name={"braceletsM"}
                            type="number"
                            value={this.state.braceletsM}
                            onChange={this.handleBraceletSelect}
                        />
                        <IconButton onClick={this.handleBraceletSelectMobile('add', 'braceletsM')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <AddIcon/>
                        </IconButton>
                    </div>
                    <p className={importedStyles.sizeStock}>Sólo quedan {stockM} en stock</p>
                </Grid>
                <Grid item xs={12} md={3} className={importedStyles.sizeCard}>
                    {/*<Carousel showArrows={true} showThumbs={false} showStatus={false} infiniteLoop emulateTouch className={importedStyles.productImgCarousel}>
                        <div>
                            <img
                                //className={importedStyles.sizeCardImg}
                                src={require('../../img/pulsidTallaS.jpg')}
                                alt={'pulsid pulsera'}
                            />
                        </div>
                        <div>
                            <img
                                //className={importedStyles.sizeCardImg}
                                src={require('../../img/pruebas2.jpg')}
                                alt={'pulsid pulsera'}
                            />
                        </div>
                        <div>
                            <img
                                //className={importedStyles.sizeCardImg}
                                src={require('../../img/pruebas3.jpg')}
                                alt={'pulsid pulsera'}
                            />
                        </div>
                    </Carousel>*/}
                    <div className={importedStyles.sizeCardImg} id={'skeletonS'}>
                        <Skeleton height={'10rem'} width={'100%'}/>
                    </div>
                    {isWebpSupported() ? this.renderPulsidImageWebp('S') : this.renderPulsidImage('S')}

                    <div className={importedStyles.sizeCardSize}>
                        <span className={importedStyles.braceletSizeCode}>S</span>
                        <div className={importedStyles.braceletSizeBox}>
                            <span>160 mm</span>
                        </div>
                        <span>Ideal para los peques</span>
                    </div>
                    <div className={importedStyles.sizeSelectorActions}>
                        <IconButton onClick={this.handleBraceletSelectMobile('remove', 'braceletsS')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <RemoveIcon/>
                        </IconButton>
                        <TextField
                            className={styles}
                            variant={"outlined"}
                            margin="normal"
                            label="Cantidad"
                            name={"braceletsS"}
                            type="number"
                            value={this.state.braceletsS}
                            onChange={this.handleBraceletSelect}
                        />
                        <IconButton onClick={this.handleBraceletSelectMobile('add', 'braceletsS')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <AddIcon/>
                        </IconButton>
                    </div>
                    <p className={importedStyles.sizeStock}>Sólo quedan {stockS} en stock</p>
                </Grid>
            </Grid>
        );
    }

    renderShippingSelector() {
        return (
            <div className={importedStyles.shippingPriceSelector}>
                <RadioGroup aria-label="shipping" name="includeShipping" defaultValue={false}
                            value={this.state.includeShipping} onChange={this.handleInputChange}>
                    <FormControlLabel
                        value={'free'}
                        control={<Radio color="primary"/>}
                        label={<span className={importedStyles.freeShippingOptionLabel}>Recíbelo <b>GRATIS</b> en 4-5 días</span>}
                        labelPlacement="end"
                    />
                    <Divider variant={"middle"}/>
                    <FormControlLabel
                        value={'premium'}
                        control={<Radio color="primary"/>}
                        label={
                            <span><b>PREMIUM</b> - Recíbelo el <b>{this.renderPremiumShippingDate()}</b> (+3.75€)</span>}
                        labelPlacement="end"
                    />
                </RadioGroup>
            </div>
        );
    }

    renderPackagingSelector() {
        const styles = [importedStyles.textField, importedStyles.sizePicker];
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={importedStyles.packagingCheck}
                        control={
                            <Checkbox
                                checked={this.state.includePackaging}
                                onChange={() => this.setState({includePackaging: !this.state.includePackaging})}
                                className={importedStyles.checkbox}
                            />
                        }
                        label={<span
                            className={importedStyles.packagingCheckLabel}>Añadir caja PulsID para regalo con dedicatoria personalizada (+{packagingPrice}€/u)</span>}
                    />
                </Grid>
                <Grid item xs={12} sm={12} className={importedStyles.sizeCardContainer}>
                    <Card className={importedStyles.sizeCard}>
                        <Grid container spacing={8} className={importedStyles.braceletsSelectorGrid}>
                            {this.state.includePackaging &&
                            <Grid item md={6}>
                                <div className={importedStyles.sizeCardSize}>
                                </div>
                                <TextField
                                    className={styles}
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Cantidad"
                                    type="number"
                                    value={this.state.packagingQuantity}
                                    onChange={this.handlePackagingQuantity}
                                />
                                <TextField
                                    className={styles}
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Dedicatoria"
                                    helperText={"Personaliza tu caja con un nombre o mensaje dedicado (Máx. 40 caracteres)"}
                                    type="text"
                                    inputProps={{
                                        maxLength: 40
                                    }}
                                    value={this.state.giftStickerContent}
                                    onChange={this.handleGiftStickerContent}
                                />
                            </Grid>
                            }
                            <Grid item md={this.state.includePackaging ? 6 : 12}>
                                <img
                                    //className={importedStyles.sizeCardImg}
                                    className={importedStyles.packagingImg}
                                    src={require('../../img/cajaPulsidFront.jpg')}
                                    alt={'packaging'}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

            </React.Fragment>
        );
    }

    renderPackagingSelectorBeta() {
        const styles = [importedStyles.textField, importedStyles.sizePicker];
        return (
            <React.Fragment>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>🎁 ¿Es un regalo?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{flexWrap: 'wrap'}}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                className={importedStyles.packagingCheck}
                                control={
                                    <Checkbox
                                        checked={this.state.includePackaging}
                                        onChange={() => this.setState({includePackaging: !this.state.includePackaging})}
                                        className={importedStyles.checkbox}
                                    />
                                }
                                label={<span
                                    className={importedStyles.packagingCheckLabel}>Añadir caja PulsID para regalo con dedicatoria personalizada (+{packagingPrice}€/u)</span>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} className={importedStyles.sizeCardContainer}>
                            <Card className={importedStyles.sizeCard}>
                                <Grid container spacing={8} className={importedStyles.braceletsSelectorGrid}>
                                    {this.state.includePackaging &&
                                    <Grid item md={6} className={'packagingInputs'}>
                                        <div className={importedStyles.sizeCardSize}>
                                        </div>
                                        <TextField
                                            className={styles}
                                            variant={"outlined"}
                                            margin="normal"
                                            label="Cantidad"
                                            type="number"
                                            value={this.state.packagingQuantity}
                                            onChange={this.handlePackagingQuantity}
                                        />
                                        <TextField
                                            className={styles}
                                            variant={"outlined"}
                                            margin="normal"
                                            label="Dedicatoria"
                                            helperText={"Personaliza tu caja con un nombre o mensaje dedicado (Máx. 40 caracteres)"}
                                            type="text"
                                            inputProps={{
                                                maxLength: 40
                                            }}
                                            value={this.state.giftStickerContent}
                                            onChange={this.handleGiftStickerContent}
                                        />
                                    </Grid>
                                    }
                                    <Grid item md={this.state.includePackaging ? 4 : 12}>
                                        <img
                                            //className={importedStyles.sizeCardImg}
                                            className={importedStyles.packagingImg}
                                            src={require('../../img/cajaPulsidFront.jpg')}
                                            alt={'packaging'}
                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </React.Fragment>
        );
    }

    renderCartResumeOld() {
        const totalPrice = this.getTotalPrice();
        return (
            <div>
                <Grid container spacing={16} className={importedStyles.sizeCardContainerCart}>
                    {this.state.braceletsL !== '' && parseInt(this.state.braceletsL) !== 0 &&
                    <Grid item xs={12} md={4} className={importedStyles.cartItem}>
                        <Card className={importedStyles.sizeCardCart}>
                            <Grid container>
                                <Grid item xs={6} className={importedStyles.productImageBox}>
                                    <Image
                                        className={importedStyles.sizeCardImg}
                                        src={require('../../img/pulsidBracelet.png')}
                                        webp={require('../../img/pulsidBracelet.webp')}
                                        alt={'pulsid pulsera'}
                                    />
                                    <span>L · 202 mm</span>
                                    <p>x {this.state.braceletsL}</p>
                                </Grid>
                                <Grid item xs={6} className={importedStyles.productDetails}>
                                    {/* <Chip className={importedStyles.oldPriceChip}
                                          label={this.state.braceletsL * 19.99 + '€'}/>*/}
                                    <Chip className={importedStyles.priceChip}
                                          label={this.state.braceletsL * braceletPrice + '€'}/>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>}
                    {this.state.braceletsM !== '' && parseInt(this.state.braceletsM) !== 0 &&
                    <Grid item xs={12} md={4} className={importedStyles.cartItem}>
                        <Card className={importedStyles.sizeCardCart}>
                            <Grid container>
                                <Grid item xs={6} className={importedStyles.productImageBox}>
                                    <Image
                                        className={importedStyles.sizeCardImg}
                                        src={require('../../img/pulsidBracelet.png')}
                                        webp={require('../../img/pulsidBracelet.webp')}
                                        alt={'pulsid pulsera'}
                                    />
                                    <span>M · 180 mm</span>
                                    <p>x {this.state.braceletsM}</p>
                                </Grid>
                                <Grid item xs={6} className={importedStyles.productDetails}>
                                    {/*<Chip className={importedStyles.oldPriceChip}
                                          label={this.state.braceletsM * 19.99 + '€'}/>*/}
                                    <Chip className={importedStyles.priceChip}
                                          label={this.state.braceletsM * braceletPrice + '€'}/>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>}
                    {this.state.braceletsS !== '' && parseInt(this.state.braceletsS) !== 0 &&
                    <Grid item xs={12} md={4} className={importedStyles.cartItem}>
                        <Card className={importedStyles.sizeCardCart}>
                            <Grid container>
                                <Grid item xs={6} className={importedStyles.productImageBox}>
                                    <Image
                                        className={importedStyles.sizeCardImg}
                                        src={require('../../img/pulsidBracelet.png')}
                                        webp={require('../../img/pulsidBracelet.webp')}
                                        alt={'pulsid pulsera'}
                                    />
                                    <span>S · 160mm</span>
                                    <p>x {this.state.braceletsS}</p>
                                </Grid>
                                <Grid item xs={6} className={importedStyles.productDetails}>
                                    {/*<Chip className={importedStyles.oldPriceChip}
                                          label={this.state.braceletsS * 19.99 + '€'}/>*/}
                                    <Chip className={importedStyles.priceChip}
                                          label={this.state.braceletsS * braceletPrice + '€'}/>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>}
                    {this.state.includePackaging &&
                    <Grid item xs={12} md={4} className={importedStyles.cartItem}>
                        <Card className={importedStyles.sizeCardCart}>
                            <Grid container>
                                <Grid item xs={6} className={importedStyles.productImageBox}>
                                    <img
                                        className={importedStyles.packagingCartImg}
                                        src={require('../../img/cajaPulsidFront.jpg')}
                                        alt={'packaging'}
                                    />
                                </Grid>
                                <Grid item xs={6} className={importedStyles.productDetails}>
                                    <span style={{textAlign: 'center'}}>Caja PulsID para regalo</span>
                                    <p>x {this.state.packagingQuantity}</p>
                                    <Chip className={importedStyles.priceChip}
                                          label={this.state.packagingQuantity * packagingPrice + '€'}/>
                                </Grid>
                                <Grid item xs={12} className={importedStyles.cartItem}>
                                    <span>Dedicatoria: </span>
                                    <span><i>'{this.state.giftStickerContent}'</i></span>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    }
                    <Grid item xs={12} className={importedStyles.parentShippingPriceBox}>
                        <Grid item md={4} className={importedStyles.shippingPriceBox}>
                            <Grid item xs={6} className={importedStyles.centerContainer}>
                                <img
                                    className={importedStyles.packagingCartImg}
                                    src={require('../../img/shipping-pulsID.png')}
                                    alt={'shipping'}
                                />
                            </Grid>
                            <Grid item xs={6} className={importedStyles.centerContainer}>
                                <Typography variant={"h6"}>
                                    {this.state.includeShipping === 'premium' ? "3.75€" :
                                        <React.Fragment>Envío totalmente <b>GRATIS</b></React.Fragment>}
                                </Typography>
                                <Typography variant={"caption"}>
                                    {this.state.includeShipping === 'premium' ? "Recíbela en 48h" : "Recíbela en 4-5 días en tu buzón"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.discountObject.code &&
                    <Grid item xs={12} md={12} className={importedStyles.cartItem}>
                        <Card className={importedStyles.sizeCardCart} style={{borderLeft: 'solid 5px red'}}>
                            <Grid container>
                                <Grid item xs={6} className={importedStyles.productImageBox}>
                                    <img
                                        className={importedStyles.sizeCardImg}
                                        src={require('../../img/discount.png')}
                                        alt={'discount'}
                                    />
                                </Grid>
                                <Grid item xs={6} className={importedStyles.productDetails}>
                                    <span style={{textAlign: 'center'}}>{this.state.discountObject.code}</span>
                                    <span style={{textAlign: 'center'}}>{this.state.discountObject.description}</span>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    }


                    {/*<Grid item xs={12} className={importedStyles.cartItem}>*/}
                    <Grid item xs={12} md={6} className={importedStyles.discountContainer}>
                        <TextField
                            className={importedStyles.textField}
                            variant={"outlined"}
                            margin="dense"
                            label="Código promocional"
                            name={"discount"}
                            type="text"
                            value={this.state.discount}
                            autoComplete="nope"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    this.checkDiscount();
                                }
                            }}
                            onChange={this.handleInputChange}
                            inputProps={{
                                maxLength: 50
                            }}
                        />

                        <Button id={'checkDiscountCode'} onClick={this.checkDiscount}
                                className={importedStyles.discountButton} disabled={this.state.sendingData}>
                            Comprobar {this.state.sendingData &&
                        <CircularProgress size={35} className={importedStyles.sendingDataAnimation}/>}
                        </Button>

                    </Grid>
                    <Grid item xs={12} md={6} className={importedStyles.cartItem}>
                        <div className={importedStyles.totalPriceWrapper}>
                            <Typography variant={"heading"}>
                                TOTAL:
                                <Chip className={importedStyles.totalPriceChip}
                                      label={totalPrice + '€'}/>
                            </Typography>
                            {this.state.discountObject &&
                            <p>{this.state.discountObject.code} | {this.state.discountObject.description}</p>}
                        </div>
                    </Grid>
                    {/*</Grid>*/}
                </Grid>
            </div>
        );
    }

    handleBraceletRemove = size => {
        switch (size) {
            case 'S':
                this.setState({
                    braceletsS: 0,
                    totalBracelets: parseInt(this.state.braceletsL) + parseInt(this.state.braceletsM)
                });
                break;
            case 'M':
                this.setState({
                    braceletsM: 0,
                    totalBracelets: parseInt(this.state.braceletsL) + parseInt(this.state.braceletsS)
                });
                break;
            case 'L':
                this.setState({
                    braceletsL: 0,
                    totalBracelets: parseInt(this.state.braceletsM) + parseInt(this.state.braceletsS)
                });
                break;
            default:
                // do nothing
                break;
        }
    };

    handlePackagingRemove = () => {
        this.setState({packagingQuantity: 0, includePackaging: false})
    };

    renderNoBraceletsSelectedAlert = () => {
        return (
            <React.Fragment>
                <ListItem style={{backgroundColor: 'rgba(255,233,0,0.79)'}}>
                    <ListItemAvatar>
                        <WarningIcon style={{color: '#ff0200'}}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary="ATENCIÓN, no has seleccionado ninguna pulsera, para realizar la compra debes elegir al menos una"/>
                </ListItem>
                <Divider variant={"middle"}/>
            </React.Fragment>
        )
    };

    renderCartResume() {
        const totalPrice = this.getTotalPrice();
        return (
            <div>
                <List
                    subheader={
                        <ListSubheader className={importedStyles.cartResumeListTitle} disableSticky dense disableGutters
                                       component={'h3'}>
                            <b>Resumen de tu pedido:</b>
                        </ListSubheader>
                    }
                >
                    {this.state.totalBracelets <= 0 && this.renderNoBraceletsSelectedAlert()}

                    {this.state.braceletsL !== '' && parseInt(this.state.braceletsL) !== 0 &&
                    <React.Fragment>
                        <ListItem>
                            <ListItemAvatar>
                                <IconButton onClick={() => this.handleBraceletRemove('L')}>
                                    <TrashIcon style={{color: '#f44336'}}/>
                                </IconButton>
                            </ListItemAvatar>
                            <ListItemText primary="Pulsera talla L" secondary={'x' + this.state.braceletsL}/>
                            <ListItemSecondaryAction>{(this.state.braceletsL * braceletPrice).toFixed(2) + '€'}</ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant={"middle"}/>
                    </React.Fragment>
                    }
                    {this.state.braceletsM !== '' && parseInt(this.state.braceletsM) !== 0 &&
                    <React.Fragment>
                        <ListItem>
                            <ListItemAvatar>
                                <IconButton onClick={() => this.handleBraceletRemove('M')}>
                                    <TrashIcon style={{color: '#f44336'}}/>
                                </IconButton>
                            </ListItemAvatar>
                            <ListItemText primary="Pulsera talla M" secondary={'x' + this.state.braceletsM}/>
                            <ListItemSecondaryAction>
                                {(this.state.braceletsM * braceletPrice).toFixed(2) + '€'}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant={"middle"}/>
                    </React.Fragment>
                    }
                    {this.state.braceletsS !== '' && parseInt(this.state.braceletsS) !== 0 &&
                    <React.Fragment>
                        <ListItem>
                            <ListItemAvatar>
                                <IconButton onClick={() => this.handleBraceletRemove('S')}>
                                    <TrashIcon style={{color: '#f44336'}}/>
                                </IconButton>
                            </ListItemAvatar>
                            <ListItemText primary="Pulsera talla S" secondary={'x' + this.state.braceletsS}/>
                            <ListItemSecondaryAction>{(this.state.braceletsS * braceletPrice).toFixed(2) + '€'}</ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant={"middle"}/>
                    </React.Fragment>
                    }
                    {this.state.includePackaging &&
                    <React.Fragment>
                        <ListItem>
                            <ListItemAvatar>
                                <IconButton onClick={this.handlePackagingRemove}>
                                    <TrashIcon style={{color: '#f44336'}}/>
                                </IconButton>
                            </ListItemAvatar>
                            <ListItemText secondary={'x' + this.state.packagingQuantity}
                                          primary={'Caja PulsID para regalo'}/>
                            <ListItemSecondaryAction>{this.state.packagingQuantity * packagingPrice + '€'}</ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant={"middle"}/>
                    </React.Fragment>
                    }
                    <ListItem>
                        <ListItemText
                            primary={this.state.includeShipping === 'premium' ?
                                <React.Fragment>Envío <b>PREMIUM</b></React.Fragment> :
                                <React.Fragment>Envío <b>GRATIS</b></React.Fragment>}
                            secondary={this.state.includeShipping === 'premium' ? "Recíbela en 48h" : "Recíbela en 4-5 días en tu buzón"}
                        />
                        <ListItemSecondaryAction>{this.state.includeShipping === 'premium' ? '3,75' : '0'}€</ListItemSecondaryAction>
                    </ListItem>
                    {this.renderDiscountDivider()}
                </List>
                <Grid item xs={12} className={importedStyles.cartItem}>
                    <small style={{color: '#b3b3b3'}}>Nuestro equipo trabaja duramente para que recibas tu producto,
                        pero es posible que tu envío sufra retrasos inesperados debidos a la situación actual.</small>
                </Grid>
                <Grid container spacing={16} className={importedStyles.sizeCardContainerCart}>

                    <Grid item xs={12} md={6} className={importedStyles.discountContainer}>
                        <TextField
                            className={importedStyles.textField}
                            variant={"outlined"}
                            margin="dense"
                            label="Código promocional"
                            name={"discount"}
                            type="text"
                            fullWidth
                            value={this.state.discount}
                            autoComplete="nope"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    this.checkDiscount();
                                }
                            }}
                            onChange={this.handleInputChange}
                            inputProps={{
                                maxLength: 50
                            }}
                        />

                        <Button id={'checkDiscountCode'} onClick={this.checkDiscount}
                                className={importedStyles.discountButton} disabled={this.state.sendingData}>
                            Comprobar {this.state.sendingData &&
                        <CircularProgress size={35} className={importedStyles.sendingDataAnimation}/>}
                        </Button>

                    </Grid>

                    <Grid item xs={12} md={6} className={importedStyles.cartItem}>

                        <div className={importedStyles.totalPriceWrapper}>
                            <Typography variant={"heading"}>
                                TOTAL:
                                <Chip className={importedStyles.totalPriceChip}
                                      label={totalPrice + '€'}/>
                            </Typography>
                            {this.renderDiscount()}
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderDiscount = () => {
        if (!this.state.errorTwoForOne) {
            return (
                <React.Fragment>
                    {this.state.discountObject &&
                    <p style={{color: '#ff7428'}}>{this.state.discountObject.code} | {this.state.discountObject.description}</p>}
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    {this.state.discountObject &&
                    <p style={{color: '#ff1e09'}}>{"Para usar el 2x1 tienes que elegir al menos 2 pulseras"}</p>}
                </React.Fragment>
            )
        }
    }

    renderDiscountDivider = () => {
        if (!this.state.errorTwoForOne) {
            return (
                this.state.discountObject &&
                <React.Fragment>
                    <Divider variant={"middle"}/>
                    <ListItem>
                        <ListItemAvatar>
                            <LabelIcon style={{color: '#ff7428'}}/>
                        </ListItemAvatar>
                        <ListItemText
                            classes={{
                                primary: importedStyles.discountPrimaryText,
                                secondary: importedStyles.discountSecondaryText
                            }}//sustituir este tambien
                            primary={this.state.discountObject.code}
                            secondary={this.state.discountObject.description}
                        />
                    </ListItem>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {this.state.discountObject &&
                    <p style={{color: '#ff1e09'}}>{"Para el descuento 2x1 tienes que elegir mas de una pulsera"}</p>}
                </React.Fragment>
            )
        }
    }

    renderUserInfoForm = () => {
        return (
            <React.Fragment>
                <Grid item xs={12} sm={12}>
                    <TextField
                        className={importedStyles.textField}
                        variant={"outlined"}
                        margin="dense"
                        label="Nombre y apellidos"
                        name={"name"}
                        type="text"
                        fullWidth
                        value={this.state.name}
                        autoComplete="nope"
                        onChange={this.handleInputChange}
                        inputProps={{
                            maxLength: 150
                        }}
                        disabled={this.props.newBracelet}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={this.state.validMail ? importedStyles.textField : importedStyles.textFieldError}
                        variant={"outlined"}
                        margin="dense"
                        label="Email"
                        name={"email"}
                        type="email"
                        value={this.state.email}
                        fullWidth
                        autoComplete="nope"
                        onBlur={this.validateMail}
                        onChange={this.handleInputChange}
                        disabled={this.props.newBracelet}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <TextField
                        className={importedStyles.textField}
                        variant={"outlined"}
                        margin="dense"
                        label="Nº identificación fiscal "
                        name={"documentId"}
                        type="text"
                        value={this.state.documentId}
                        helperText={'Opcional. Rellena este campo solo si deseas factura'}
                        fullWidth
                        autoComplete="nope"
                        onChange={this.handleInputChange}
                        inputProps={{
                            maxLength: 9
                        }}
                    />
                </Grid>*/}
                <Grid item xs={12}>
                    <DialogContentText className={''}>
                        Indica dónde quieres recibir tu pedido:
                    </DialogContentText>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" className={importedStyles.formControl}
                                 style={{marginTop: 8, marginBottom: 4}}>
                        {/*<InputLabel>
                            País
                        </InputLabel>
                        <Select
                            value={this.state.country}
                            onChange={this.handleInputChange}
                            fullWidth
                            input={<OutlinedInput fullWidth labelWidth={32} name="country"/>}
                        >
                            <MenuItem value=''>
                                <em style={{color: "#8e8e8e"}}>-----</em>
                            </MenuItem>
                            <MenuItem value={'spain'}>España</MenuItem>
                            <MenuItem value={'argentina'}>Argentina</MenuItem>
                        </Select>*/}
                        {this.renderAddressForm()}
                    </FormControl>
                </Grid>


            </React.Fragment>
        )
    };


    renderAddressForm = () => {
      return (
          <React.Fragment>
              <Grid item xs={12} sm={12}>
                  <FormControl variant="outlined" className={importedStyles.formControl} fullWidth
                               autoComplete="nope">
                      {this.renderAutocompleteProvinceInput()}
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={importedStyles.formControl} fullWidth
                               autoComplete="nope">
                      {this.renderAutocompleteCityInput()}
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                  <TextField
                      className={importedStyles.textField}
                      variant={"outlined"}
                      margin="dense"
                      label="Código postal"
                      name={"zipcode"}
                      value={this.state.zipcode}
                      type="text"
                      inputProps={{
                          maxLength: this.state.country === 'spain' ? 5 : 8,
                      }}
                      autoComplete="nope"
                      fullWidth
                      onChange={this.handleInputChange}
                  />
              </Grid>
              <Grid item xs={12} sm={12}>
                  <TextField
                      className={importedStyles.formControl}
                      variant={"outlined"}
                      margin="dense"
                      name={"address"}
                      label="Dirección"
                      value={this.state.address}
                      type="text"
                      fullWidth
                      autoComplete="nope"
                      inputProps={{
                          maxLength: 50,
                      }}
                      onChange={this.handleInputChange}
                  />
              </Grid>
          </React.Fragment>
      )
    };



    backToProductSelector = () => {
        this.props.handleModal(); //Open product selector modal
        this.handlePaymentModal(); //Close payment data and resume modal
    };

    handleLoadingPaymentRequestModal = () => {
        this.setState({
            openLoadingPaymentRequestModal: !this.state.openLoadingPaymentRequestModal
        });
    };

    renderLoadingAnimation = () => {
        let defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loadingPaymentRequest,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        if (!this.state.waitingForPaymentRequestPackage) {
            defaultOptions = defaultOptions = {
                loop: false,
                autoplay: true,
                animationData: check,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
            };
        }

        return (
            <Lottie options={defaultOptions}
                    height={100}
                    width={100}
                //speed={0.7}
            />
        )
    };
    renderLoadingDotsAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loadingDots,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={100}
                    width={100}
                    style={{transform: 'rotate(90deg) !important'}}
                //speed={0.7}
            />
        )
    };
    renderRedirectAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: redirectToSecure,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={100}
                    width={100}
                //speed={0.7}
            />
        )
    };

    handleStepChange = step => {
        this.setState({currentStep: step});
        document.getElementById('mainDialogContent').scrollTo({top: 0, behavior: 'smooth'});

    };


    renderDialogTitle = () => {
        if (!this.props.promoCode) {
            if (window.matchMedia("(max-width: 768px)").matches) {
                return (
                    <React.Fragment>
                        <Typography variant={"subtitle1"} style={{marginTop: '1rem'}}>
                            {this.state.currentStep === 2 && <IconButton onClick={() => this.handleStepChange(1)}
                                                                         style={{marginRight: '0.5rem'}}><ArrowBackIcon/></IconButton>}
                            Consigue tus pulseras por solo <span
                            style={{textDecoration: 'line-through'}}>19.99€</span> 👉 {braceletPrice}€
                        </Typography>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        <Typography variant={"h5"}>
                            {this.state.currentStep === 2 && <IconButton onClick={() => this.handleStepChange(1)}
                                                                         style={{marginRight: '0.5rem'}}><ArrowBackIcon/></IconButton>}
                            {/*"Consigue tus pulseras por solo "+braceletPrice+"€*"*/}
                            Consigue tus pulseras por solo <span
                            style={{textDecoration: 'line-through'}}>19.99€</span> 👉 {braceletPrice}€
                        </Typography>
                    </React.Fragment>
                )
            }
        }

        return (
            <React.Fragment>
                {this.state.currentStep === 2 && <IconButton onClick={() => this.handleStepChange(1)}
                                                             style={{marginRight: '0.5rem'}}><ArrowBackIcon/></IconButton>}
                <Typography variant={"h5"}>{"Escoge una talla"}</Typography>
            </React.Fragment>
        )
    };

    showIncompleteFormAlert = () => {
        if (!this.state.sendingData) {
            this.props.dispatch(showSnack('Tienes algún campo sin rellenar o no has aceptado los términos y condiciones', 'warning'));
        }
    };

    renderPaymentConfirmationButton = () => {

        let incompleteForm = !this.state.email.length > 0 || !this.state.address.length > 0
            || !this.state.province.length > 0 || !this.state.totalBracelets > 0
            || !this.state.city.length > 0 || !this.state.zipcode.length > 0 || !this.state.validMail || !this.state.privacityTermsCheck || this.state.sendingData;

        if (incompleteForm) {
            return (
                <React.Fragment>
                    <Button
                        color="primary"
                        variant={"contained"}
                        className={importedStyles.disabledButton}
                        onClick={this.showIncompleteFormAlert}
                    >
                        Confirmar pedido <DoneIcon style={{marginLeft: '0.5em'}}/>
                        {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                    </Button>
                    {/*<Typography variant={"caption"}>
                        Debes aceptar las condiciones antes de confirmar tu pedido
                    </Typography>*/}
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Button
                        color="primary"
                        variant={"contained"}
                        className={importedStyles.dialogActionButton}
                        onClick={this.handlePaymentRequest}
                    >
                        Confirmar pedido <DoneIcon style={{marginLeft: '0.5em'}}/>
                        {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                    </Button>
                </React.Fragment>
            )
        }
    };

    renderPremiumShippingDate = () => {
        let shippingPremiumDate = moment();
        let firstDayIsFridayEvening = shippingPremiumDate.isoWeekday() === 5 && shippingPremiumDate.format('HH') > 12;
        let firstDateIsSaturday = shippingPremiumDate.isoWeekday() === 6;

        // Primero comprobamos si hoy es viernes tarde o sabado y pasamos la fecha de entrega al martes, de lo contrario sumamos 48h al dia actual
        if (firstDayIsFridayEvening || firstDateIsSaturday) {
            shippingPremiumDate.add(72, 'hours');
        } else {
            shippingPremiumDate.add(48, 'hours');
        }

        //Ahora comprobamos si la fecha de entrega cae en fin de semana y sumamos los dias correspondientes
        let isSaturday = shippingPremiumDate.isoWeekday() === 6;
        let isSunday = shippingPremiumDate.isoWeekday() === 7;
        if (isSaturday) {
            shippingPremiumDate.add(48, 'hours');
        }
        if (isSunday) {
            shippingPremiumDate.add(24, 'hours');
        }

        return shippingPremiumDate.format("DD MMMM");
    };

    renderFirstBuySteps = () => {

        switch (this.state.currentStep) {
            case 1:
                return (
                    <React.Fragment>
                        <DialogContentText className={importedStyles.contentInnerText}>
                            Elige la talla de tus pulseras 📐:
                        </DialogContentText>
                        {/*<div className={importedStyles.hurrify3}>
                            <b style={{color: '#e57373'}}>Anticípate y compra hoy con envío PREMIUM para recibirlo
                                el {this.renderPremiumShippingDate()} 📦</b>
                        </div>*/}
                        {this.renderSizeSelector()}
                    </React.Fragment>
                );

            case 2:
                return (
                    <React.Fragment>
                        <DialogContentText className={importedStyles.contentInnerText}>
                            Tipo de envío 🚚:
                        </DialogContentText>
                        {this.renderShippingSelector()}
                        {/*<DialogContentText className={importedStyles.contentInnerText}>
                            🎁 ¿Es un regalo?:
                        </DialogContentText>*/}
                        <Grid item xs={12}>
                            {this.renderPackagingSelectorBeta()}
                        </Grid>
                    </React.Fragment>
                );
            default:
                // do nothing
                break;
        }
    };

    renderFirstStepsActions = () => {
        switch (this.state.currentStep) {
            case 1:
                return (
                    <React.Fragment>
                        <Button
                            variant={"contained"}
                            //onClick={() => this.handleStepChange(2)} /* COMENTADO PARA EXPERIMENTO PASAR DIRECTAMENTE A INFO DEL COMPRADOR */
                            onClick={this.handleCheckoutResume}
                            disabled={this.state.sendingData || this.state.totalBracelets <= 0}
                            className={importedStyles.dialogActionButton}
                        >
                            Siguiente paso
                            <ArrowForward style={{position: 'absolute', right: '0.5em'}}/>
                            {this.state.sendingData &&
                            <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                    </React.Fragment>
                );

            case 2:
                return (
                    <React.Fragment>
                        <Button
                            variant={"contained"}
                            onClick={this.handleCheckoutResume}
                            disabled={this.state.sendingData || this.state.totalBracelets <= 0}
                            className={importedStyles.dialogActionButton}
                        >
                            <ShoppingCart style={{marginRight: '0.5em'}}/>
                            Ir al carrito
                            <ArrowForward style={{position: 'absolute', right: '0.5em'}}/>
                            {this.state.sendingData &&
                            <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                    </React.Fragment>
                );
            default:
                // do nothing
                break;
        }
    };

    renderCountDown = () => {
        //let finishDate = new Date();
        let currentDate = moment();
        let finishDate = moment('9 Feb 2020');

        if (localStorage.getItem('web10discount')) {
            finishDate = moment(localStorage.getItem('web10discount')).format();
        }
        if (currentDate.isAfter(finishDate)) {
            finishDate = moment(currentDate.add('10', 'minutes')).format();
            localStorage.setItem('web10discount', finishDate);
        }

        /*let previousDate = localStorage.getItem('finishDate')
        let existsStoredDate =  localStorage.getItem('finishDate') !== undefined;

        if (localStorage.getItem('finishDate') !== undefined){

        } else {
            localStorage.setItem('date1', date1.toString())
        }
        var date1 = new Date(localStorage.getItem('finishDate'))

        localStorage.setItem('date2', date2.toString())
        var date2 = new Date(localStorage.getItem('date2'))
        var diff = date1 - date2*/

        //finishDate.setMinutes(finishDate.getMinutes() + 10);
        return (
            <Countdown daysInHours={true} date={finishDate} zeroPadTime={2}
                       renderer={({days, hours, minutes, seconds}) => {
                           return <b>{zeroPad(minutes)}:{zeroPad(seconds)}</b>;
                       }}/>
        )
    };

    renderCouponHurrify = () => {
        if (!this.props.promoCode) {
            return (
                <div className={importedStyles.hurrify2}>
                    ⚠️ Usa el cupón <b>“WEB10”</b> <br/> Caduca en {this.renderCountDown()} <br/>¡APROVÉCHALO!
                </div>
            )
        }
    };

    render() {
        return (
            <React.Fragment>
                <Dialog
                    id={'firstStepsModal'}
                    fullScreen={true}
                    open={this.props.open}
                    onClose={this.props.handleModal}
                    scroll={"paper"}
                    disableBackdropClick
                    className={importedStyles.fixModalPadding}
                >
                    <DialogTitle style={{display: 'flex', alignItems: 'center'}}>
                        {this.renderDialogTitle()}
                        <IconButton onClick={this.props.handleModal} className={importedStyles.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={importedStyles.mainDialogContent} id={'mainDialogContent'}>
                        <DialogContentText className={importedStyles.contentText}>
                            {/*<Card className={importedStyles.offerCard}>
                                <Typography variant={"subtitle1"}>
                                    ¡Oferta de lanzamiento, 20% de descuento!
                                </Typography>
                                <Typography variant={"subheading"}>
                                    <span style={{textDecoration: 'line-through'}}>19.99€</span> 👉 <b>13.99€</b>
                                </Typography>
                            </Card>*/}
                        </DialogContentText>
                        <Grid container spacing={16} className={importedStyles.parentContainer}>
                            {this.renderFirstBuySteps()}
                            <div>
                                <p>*IVA incluido.</p>
                            </div>
                        </Grid>

                    </DialogContent>
                    <DialogActions className={importedStyles.dialogActionsFirstStep}>
                        {this.renderFirstStepsActions()}
                    </DialogActions>
                </Dialog>

                {/*/////////////////////////////////////////////////*/}

                {/*PAYMENT MODAL */}
                <Dialog
                    fullScreen={true}
                    open={this.state.openPaymentModal}
                    onClose={this.handlePaymentModal}
                    disableBackdropClick
                    className={importedStyles.paymentModal}
                >
                    <DialogTitle>
                        <div className={importedStyles.paymentModalTitleBox}>
                            <IconButton onClick={this.backToProductSelector}>
                                <ArrowBackIcon/>
                            </IconButton>
                            <span style={{marginLeft: 5}}>Último paso</span>
                            <IconButton onClick={this.handlePaymentModal} className={importedStyles.closeModalIcon}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent className={importedStyles.mainDialogContent}>
                        {this.renderCouponHurrify()}
                        <Grid container spacing={16}>
                            {this.renderUserInfoForm()}
                        </Grid>
                        {this.renderCartResume()}
                        <div className={importedStyles.hurrify1}>
                            🔥 ¡Queda muy poco stock! 🔥 <br/> No te preocupes, reservamos tu carrito durante 1 hora.
                        </div>
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.privacityTermsCheck}
                                        onChange={() => this.setState({privacityTermsCheck: !this.state.privacityTermsCheck})}
                                        className={importedStyles.checkbox}
                                    />
                                }
                                label={<span className={importedStyles.privacyLink} onClick={(e) => {
                                    e.preventDefault();
                                    let win = window.open(window.location.origin + '/law', '_blank');
                                    win.focus();
                                }}>Acepto los términos y condiciones</span>}
                            />
                        </Grid>
                        <form
                            className={importedStyles.formRedsys}
                            method="post"
                            id={'redsysForm'}
                            //action={'https://sis-t.redsys.es:25443/sis/realizarPago'} // test
                            action={'https://sis.redsys.es/sis/realizarPago'} // production
                            onSubmit={() => {
                                this.setState({sendingData: true})
                            }}
                        >
                            <input type="text" name="Ds_SignatureVersion" value={this.state.Ds_SignatureVersion}
                                   hidden/>
                            <input type="text" name="Ds_MerchantParameters" value={this.state.Ds_MerchantParameters}
                                   hidden/>
                            <input type="text" name="Ds_Signature" value={this.state.Ds_Signature} hidden/>
                            {this.renderPaymentConfirmationButton()}
                        </form>
                        <Divider variant={"middle"}/>
                        <Grid container className={importedStyles.securePaymentBox}>
                            <Grid className={importedStyles.securePaymentItem} item xs={12} md={3}> <img
                                className={importedStyles.securePaymentClaimLogo}
                                src={require('../../img/pago-seguro.png')} alt={'pago seguro'}/></Grid>
                            <Grid className={importedStyles.securePaymentItem} item xs={3} md={3}><img
                                className={importedStyles.securePaymentLogo}
                                src={require('../../img/logoSSLSeguridad.png')} alt={'SSL secure'}/></Grid>
                            <Grid className={importedStyles.securePaymentItem} item xs={3} md={3}><img
                                className={importedStyles.securePaymentLogo}
                                src={require('../../img/REDSYS-COMPRA-SEGURA2.png')} alt={'Pago seguro logos'}/></Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>

                {/* LOADING PAYMENT REQUEST MODAL */}
                <Dialog
                    fullScreen={false}
                    open={this.state.openLoadingPaymentRequestModal}
                    onClose={this.handleLoadingPaymentRequestModal}
                    disableBackdropClick
                    className={importedStyles.loadingPaymentActionsModal}
                >
                    <DialogContent className={importedStyles.mainDialogContent}>
                        <DialogContentText id={'loadingPaymentRequestAnimation'}
                                           style={{textAlign: 'center', marginBottom: '1em'}}>
                            {this.renderLoadingAnimation()}
                            Enviando petición de pago
                        </DialogContentText>
                        <DialogContentText id={'redsysAnimationContainer'}
                                           className={importedStyles.redsysAnimationContainer}>
                            <div id={'dotsAnimation'}>
                                {this.renderLoadingDotsAnimation()}
                            </div>
                            {this.renderRedirectAnimation()}
                            Redireccionando a plataforma de pago seguro
                        </DialogContentText>

                    </DialogContent>
                </Dialog>

                {/* ////////////////////////// */}

                <Snack/>
                <Snackbar
                    className={importedStyles.snackTotalBracelets}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.openSnackTotalBracelets}
                >
                    <SnackbarContent
                        className={importedStyles.snackTotalBraceletsMessage}
                        variant="warning"
                        message={
                            <span>
                                Para pedidos mayores a 5 pulsID, consultar descuentos especiales en <a
                                href={'mailto:mipulsid@gmail.com'} target={'_blank'}>mipulsid@gmail.com</a>
                            </span>
                        }
                        classes={{
                            message: importedStyles.snackTotalBraceletsMessage
                        }}
                        action={
                            <button onClick={() => this.setState({openSnackTotalBracelets: false})}>
                                Ok
                            </button>
                        }
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

BuyModals.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    newBracelet: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
    func_success: PropTypes.func.isRequired,
    promoCode: PropTypes.string.isRequired,
};
BuyModals.defaultProps = {
    newBracelet: false,
};

const mapStateToProps = ({snackReducer, authReducer}) => {
    return ({
        snackData: snackReducer.data,
        user: authReducer.user,
    });
};
export default connect(mapStateToProps)(withMobileDialog()(BuyModals));