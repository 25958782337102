import React from 'react';
import importedStyles from "./Privacy.module.sass";
import Navbar from "../../components/Navbar/Navbar";

class Cookies extends React.Component {

    state = {};

    componentDidMount(){}

    render() {
        return (
            <div>
                <Navbar path={window.location.pathname} hidden={false}/>
                    <div className={importedStyles.privacy}>
                        Por favor, lee con atención
                        No dudes en consultarnos cualquier duda
                        <hr/>

                        <h2>¿Qué son las cookies?</h2>

                        Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma que utilice su equipo, pueden utilizarse para reconocer al usuario.
                        <h2>¿Para qué utiliza las cookies esta página web y cuáles son?</h2>
                        Esta página web utiliza las cookies para una serie de finalidades, incluidas:
                        <br/>
                        •	Análisis: son aquellas cookies que bien, tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la experiencia del usuario.<br/>
                        El detalle de las cookies utilizadas en esta página web es el siguiente:<br/>
                        Cookies	Nombre	Tipo	Propósito	Más información
                        __utma __utmb __utmc __utmz _ga _gat	Google Analytics	De terceros	Recoger información sobre la navegación de los usuarios por el sitio con el fin de conocer el origen de las visitas y otros datos similares a nivel estadístico. No obtiene datos de los nombres o apellidos de los usuarios ni de la dirección postal concreta desde donde se conectan.	Google Analytics Centro de privacidad de Google: http://www.google.com/intl/es/policies/privacy/

                        Nota: Las cookies de tipo 'Propias' son utilizadas sólo por el propietario de esta web y las cookies 'De terceros' son utilizadas, por el prestador del servicio que está detallado en el cuadro anterior.
                        <h2>¿Cómo puedo desactivar o eliminar estas cookies?</h2>
                        Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador:
                        <br/>Firefox<br/>
                        http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
                        <br/>  Chrome<br/>
                        http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
                        <br/>Explorer<br/>
                        http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9
                        <br/>Safari<br/>
                        http://support.apple.com/kb/ph5042
                        <br/>Opera<br/>
                        http://help.opera.com/Windows/11.50/es-ES/cookies.html
                        <br/>Otros navegadores<br/>
                        Consulte la documentación del navegador que tenga instalado.
                        Complemento de inhabilitación para navegadores de Google Analytics
                        Si desea rechazar las cookies analíticas de Google Analytics en todos los navegadores, de forma que no se envíe información suya a Google Analytics, puede descargar un complemento que realiza esta función desde este enlace: https://tools.google.com/dlpage/gaoptout.

                        <br/>
                        <br/>
                        © Sociedad Europea de Verificación S.L. 2018. All Rights Reserved.
                    </div>
            </div>
        );
    }
}
export default Cookies;