import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField/TextField";
import importedStyles from "./AssignModal.module.sass";
import baseRoute from "../../../helpers/baseRoute";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import {connect} from "react-redux";
import Snack from "../../../components/Snack/Snack";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton/IconButton";
import history from "../../../helpers/history";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import Grid from "@material-ui/core/Grid/Grid";
import Validators from "../../../helpers/validators";
import {clean_patient_data, patient_handle_modal, update_bracelet_uuid} from "../../Patient/PatientActions";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {set_assign_flow_flag, set_creating_new_user_flag} from "../ModalsActions";
import {
    set_bracelet_entity,
    set_responsible_name,
    set_responsible_phone,
    set_responsible_user_uuid
} from "./AssignActions";

class AssignModal extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            sendingData: false,
            registerModal: false,
            nextStepModal: false,
            secondEmail: '',
            email: '',
            name: '',
            phone: '',
            documentId: '',
            company: '',
            newUser: false,
            privacityTermsCheck: false
        };
    }

    componentDidMount() {
        if (this.props.user.mail) {
            this.setState({email: this.props.user.mail})
        }
    }

    handleInputChange = (event) => {

        let value = event.target.value;

        if (event.target.name === 'documentId') {
            let alphanumericRegex = /^[A-Za-z0-9]+$/;
            let documentId = value.toUpperCase();

            if (alphanumericRegex.test(documentId) || value.length === 0) {
                this.setState({[event.target.name]: documentId});
            }
        } else if (event.target.name === 'phone') {
            let regexPhone = /^[0-9]+$/;
            if (regexPhone.test(value) || value.length === 0) {
                this.setState({[event.target.name]: value});
            }
        } else {
            this.setState({[event.target.name]: event.target.value});
        }
    };

    handleAssign = () => {

        this.setState({sendingData: true});
        const uuidBracelet = this.props.braceletUuid;

        fetch(baseRoute + 'api/users/assignBracelet', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: this.state.email,
                    braceletUuid: uuidBracelet,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                //Set a flag on redux to know we are on assign flow (important to control behavior of post-assign logics)
                this.props.dispatch(set_assign_flow_flag(true));

                if (response.newUser) {
                    this.setState({newUser: true});
                    this.props.dispatch(set_creating_new_user_flag(true));
                    this.props.dispatch(showSnack('Bienvenido a PulsID, completa tus datos para continuar', snackTypes.success));
                } else {
                    this.props.dispatch(set_bracelet_entity(response.braceletEntity));
                    this.props.dispatch(set_responsible_user_uuid(response.userUuid));
                    this.props.handleModal();
                    this.setState({nextStepModal: true});
                    this.props.dispatch(showSnack('Pulsera asignada con éxito', snackTypes.success));
                }
            } else {
                throw new Error(response.errorMessage);
            }
            this.setState({sendingData: false});
        }).catch((error) => {
            console.error('Error: ', JSON.stringify(error));
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error por favor, vuelve a intentarlo dentro de unos minutos', snackTypes.error));
        });
    };

    handleAssignWithMailVerified = () => {
        this.props.handleModal();
        this.setState({registerModal: true});
    };

    handleCompleteData = () => {

        this.setState({sendingData: true});
        const uuidBracelet = this.props.braceletUuid;

        fetch(baseRoute + 'api/users/registerNewUser', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: this.state.email,
                    name: this.state.name,
                    company: this.state.company,
                    documentId: this.state.documentId,
                    phone: this.state.phone,
                    braceletUuid: uuidBracelet,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                //Set redux data of responsible for later usages
                this.props.dispatch(set_responsible_name(this.state.name));
                this.props.dispatch(set_responsible_phone(this.state.phone));

                //ReactGA.modalview('/RegisterScanning');
                this.setState({registerModal: false});
                this.props.dispatch(showSnack('Ya casi lo tenemos. Te hemos enviado un correo con tus datos de acceso a la plataforma para que puedas completar los datos más tarde', snackTypes.success));

                //Aquí, si la pulsera pertenece a una entidad hacemos que se dispare el 'completar datos ahora o más tarde'
                if (response.haveEntity) {
                    //this.setState({nextStepModal: true});
                    this.handleGoToPatientModal();
                } else {
                    history.push('/');
                }

            } else {
                throw new Error(response.errorMessage);
            }
            this.setState({sendingData: false});
        }).catch((error) => {
            console.error(JSON.stringify(error));
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error por favor, vuelve a intentarlo dentro de unos minutos', snackTypes.error));
        });

    };

    handleGoToPatientModal = () => {
        this.props.dispatch(clean_patient_data());
        this.props.dispatch(update_bracelet_uuid(this.props.braceletUuid));
        this.props.dispatch(patient_handle_modal(0));
        this.setState({nextStepModal: false});
    };

    handleKeyPressAssignMail = (e) => {
        if (e.which === 13) {
            if (this.state.email !== '' && this.state.privacityTermsCheck) {
                this.handleAssign();
            }
        }
    };
    handleKeyPressSecondMail = (e) => {
        if (e.which === 13) {
            if (this.state.privacityTermsCheck && (this.state.email !== '' && this.state.secondEmail !== '') && this.state.email === this.state.secondEmail) {
                this.handleAssignWithMailVerified();
            }
        }
    };

    redirectAfterAssign = () => {
        if (this.props.user) {
            history.push('/list')
        } else {
            if (this.props.braceletEntity === 'Impetra_Farma') {
                history.push('/impetraThanksPage');
            } else {
                history.push('/');
            }
        }
    };

    render() {
        const {fullScreen} = this.props;

        return (
            <React.Fragment>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.open}
                    onClose={this.props.handleModal}
                    disableBackdropClick
                >
                    <DialogTitle>
                        {"Asignar pulsera"}
                        <IconButton onClick={this.props.handleModal} className={importedStyles.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={importedStyles.flexColumn}>
                        <DialogContentText>
                            ¿Esta pulsera es tuya? Asígnala a tu cuenta:
                        </DialogContentText>
                        <Grid container spacing={16} style={{marginTop: 15}}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    className={importedStyles.textField}
                                    autoFocus
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Correo"
                                    name={"email"}
                                    type="text"
                                    value={this.state.email}
                                    fullWidth
                                    onKeyPress={(e) => {
                                        this.handleKeyPressAssignMail(e)
                                    }}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            {this.state.newUser &&
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    className={importedStyles.textField}
                                    autoFocus
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Confirmar correo"
                                    name={"secondEmail"}
                                    type="text"
                                    value={this.state.secondEmail}
                                    fullWidth
                                    onKeyPress={(e) => {
                                        this.handleKeyPressSecondMail(e)
                                    }}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>}
                            {!this.state.newUser &&
                            <Grid item xs={12} sm={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.privacityTermsCheck}
                                            onChange={() => this.setState({privacityTermsCheck: !this.state.privacityTermsCheck})}
                                            className={importedStyles.checkbox}
                                        />
                                    }
                                    label={<span className={importedStyles.privacyLink} onClick={(e) => {
                                        e.preventDefault();
                                        let win = window.open(window.location.origin + '/privacy', '_blank');
                                        win.focus();
                                    }}>Acepto la política de privacidad</span>}
                                />
                            </Grid>
                            }

                        </Grid>
                    </DialogContent>
                    <DialogActions className={importedStyles.flexColumn}>
                        <Button
                            className={importedStyles.buttonSubmit}
                            variant={"contained"}
                            fullWidth
                            onClick={this.state.secondEmail === '' ? this.handleAssign : this.handleAssignWithMailVerified}
                            disabled={this.state.sendingData || !this.state.email.length > 0 || !Validators.validateEmail(this.state.email) || !this.state.privacityTermsCheck || (this.state.newUser ? this.state.email !== this.state.secondEmail : false)}
                        >
                            Asignar {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.registerModal}
                    // onClose={this.props.handleModal}
                    disableBackdropClick
                >
                    <DialogTitle>
                        {"Tus datos"}
                    </DialogTitle>
                    <DialogContent className={importedStyles.flexColumn}>
                        <DialogContentText>
                            Hola, vemos que eres nuevo, danos unos cuantos datos para identificarte como tutor/a y administrador/a de
                            esta pulsera
                        </DialogContentText>
                        <Grid container spacing={16} style={{marginTop: 15}}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    className={importedStyles.textField}
                                    autoFocus
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Nombre completo del tutor/a de la pulsera"
                                    name={"name"}
                                    type="text"
                                    value={this.state.name}
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    className={importedStyles.textField}
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Entidad/Asociación"
                                    helperText={'Opcional. Rellena este campo solo si eres miembro de una entidad/asociación'}
                                    name={"company"}
                                    type="text"
                                    value={this.state.company}
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    inputProps={{maxLength: 50}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    className={importedStyles.textField}
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Documento de identidad del tutor/a"
                                    helperText={'Este campo es opcional'}
                                    name={"documentId"}
                                    type="text"
                                    inputProps={{maxLength: 9}}
                                    value={this.state.documentId}
                                    fullWidth
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    className={importedStyles.textField}
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Teléfono del tutor/a"
                                    name={"phone"}
                                    type="phone"
                                    value={this.state.phone}
                                    inputProps={{maxLength: 15}}
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    onKeyPress={(e) => {
                                        if (e.which === 13 && !this.state.sendingData && this.state.name.length > 0 && this.state.phone.length > 0) {
                                            this.handleCompleteData();
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={importedStyles.flexColumn}>
                        <Button
                            className={importedStyles.buttonSubmit}
                            variant={"contained"}
                            fullWidth
                            onClick={this.handleCompleteData}
                            disabled={this.state.sendingData || !this.state.name.length > 0 || !this.state.phone.length > 0}
                        >
                            Continuar {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.nextStepModal}
                    disableBackdropClick
                >
                    <DialogTitle>
                        {"Y ahora..."}
                    </DialogTitle>
                    <DialogContent className={importedStyles.flexColumn}>
                        <DialogContentText>
                            ¿Qué deseas hacer?
                        </DialogContentText>
                        <Grid container spacing={16} style={{marginTop: 15}}>
                            <Grid item xs={12} sm={12}>
                                <Button
                                    className={importedStyles.buttonSubmit}
                                    variant={"contained"}
                                    fullWidth
                                    onClick={this.handleGoToPatientModal}
                                    disabled={this.state.sendingData || !this.state.email.length > 0}
                                >
                                    Completar datos de tu pulsera ahora
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button
                                    className={importedStyles.buttonSubmit}
                                    variant={"contained"}
                                    fullWidth
                                    onClick={this.redirectAfterAssign}
                                    disabled={this.state.sendingData || !this.state.email.length > 0}
                                >
                                    Completar datos más tarde
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Snack/>
            </React.Fragment>
        );
    }
}

AssignModal.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
    braceletUuid: PropTypes.string.isRequired,
};
const mapStateToProps = ({snackReducer, authReducer}) => {
    return ({
        snackData: snackReducer.data,
        user: authReducer.user,
    });
};
export default connect(mapStateToProps)(withMobileDialog()(AssignModal));
