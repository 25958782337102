import {
    SET_RESPONSIBLE_PHONE,
    SET_RESPONSIBLE_NAME,
    CLEAN_RESPONSIBLE_DATA, SET_RESPONSIBLE_USER_UUID, SET_BRACELET_ENTITY
} from "../../../constants/action-types";

const initialState = {
    responsibleName: '',
    responsiblePhone: '',
    responsibleUserUuid: '',
    braceletEntity: '',
};

const assignReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_BRACELET_ENTITY:
            return {...state, braceletEntity: action.braceletEntity};
        case SET_RESPONSIBLE_USER_UUID:
            return {...state, responsibleUserUuid: action.responsibleUserUuid};
        case SET_RESPONSIBLE_PHONE:
            return {...state, responsiblePhone: action.responsiblePhone};
        case SET_RESPONSIBLE_NAME:
            return {...state, responsibleName: action.responsibleName};
        case CLEAN_RESPONSIBLE_DATA:
            return {...state, responsibleName: '', responsiblePhone: '', responsibleUserUuid: ''};

        default:
            return state;
    }
};


export default assignReducer;