function getCountryCodes(country) {
    switch (country) {
        case 'spain':
            return 'es';
        case 'argentina':
            return 'ar';
        case 'mexico':
            return 'mx';
        case 'colombia':
            return 'co';
        case 'peru':
            return 'pe';
        case 'eeuu':
            return 'us';
        default:
            return '';
    }

}

export default getCountryCodes;