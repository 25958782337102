import {ADD_BRACELET_LIST, HANDLE_VIEW_DETAIL, UPDATE_BRACELET_LIST} from "../../constants/action-types";

export const update_list = (list) => {
    return ({
        type: UPDATE_BRACELET_LIST,
        braceletList: list,
    });
};

export const add_bracelet = (bracelet) => {
    return ({
        type: ADD_BRACELET_LIST,
        newBracelet: bracelet,
    });
};

export const handle_view_detail = (bool) => {
    return ({
        type: HANDLE_VIEW_DETAIL,
        bool: bool
    });
};

