import React from 'react';
import importedStyles from "./PollLanding.module.sass";
import baseRoute from "../../helpers/baseRoute";
import history from "../../helpers/history";
import check from "../../animations/check";
import {Button, Grid, Typography} from "@material-ui/core";
import Lottie from 'react-lottie'

class PollLanding extends React.Component {

    state = {};

    componentDidMount() {
        let userEmail = this.props.match.params.userEmail;
        let answer = this.props.match.params.answer;
        this.saveAnswer(userEmail, answer);
    }

    saveAnswer(userEmail, answer) {
        fetch(baseRoute + 'api/answers/storeUserAnswer', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userEmail: userEmail,
                    answer: answer
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                console.log("Your answer has been stored");
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error: ', error);
        });
    }

    renderAnimation = () => {
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: check,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={250}
                    width={250}
            />
        )
    };

    render() {
        return (
            <Grid container className={importedStyles.gridContainer}>
                <Grid item xs={12} className={importedStyles.thanksMessage}>
                    <Typography variant={"h3"}>
                        ¡Gracias por tu respuesta!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {this.renderAnimation()}
                </Grid>
                <Grid item xs={6}  className={importedStyles.returnButtonContainer}>
                    <Button variant={"contained"} onClick={() => history.push('/')} className={importedStyles.returnButton}>
                        Volver a la página principal
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default PollLanding;