import React from "react";
import Lottie from 'react-lottie';
import connect from "react-redux/es/connect/connect";
import importedStyles from './ImpetraThanksPage.module.sass'
import covid1 from "../../animations/covid1";
import covid2 from "../../animations/covid2";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Image from "react-image-webp";
import impetraLogo from '../../img/impetraLogoWhite.png';
import impetraLogoWebp from '../../img/impetraLogoWhite.webp';
import pulsidLogo from '../../img/logo.png';


const isMobile = window.matchMedia("(max-width: 768px)").matches;

class ImpetraThanksPage extends React.Component {

    state = {
        title: '',
    };

    componentDidMount = () => {

    };

    renderAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: covid1,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                    speed={0.7}
            />
        )
    };
    renderAnimation2 = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: covid2,
            rendererSettings: {
                //preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={isMobile ? window.innerWidth * 0.70 : 400}
                    speed={1}
            />
        )
    };


    render() {

        return (
            <Grid container className={importedStyles.rootContainer}>
                <Grid item xs={12}>
                    <Typography variant={"h6"} className={importedStyles.title}>
                        ¡Enhorabuena! <br/>Ahora eres parte de la comunidad Impetra Farma by PulsID
                    </Typography>
                </Grid>
                <Grid item xs={6} className={importedStyles.logosContainer}>
                    <Image
                        className={importedStyles.impetraLogo}
                        src={impetraLogo}
                        webp={impetraLogoWebp}
                        alt={'impetra farma logo'}
                    />
                    <img
                        className={importedStyles.pulsidLogo}
                        src={pulsidLogo}
                        alt={'pulsid logo'}
                    />
                </Grid>
                <Grid item xs={12} className={importedStyles.animationAndHashtagContainer}>

                        <Typography variant={"h5"} className={importedStyles.hashtag}>
                            <span className={importedStyles.hashtagIcon}>#</span>ADELANTE<br/>JUNTOS
                        </Typography>

                        <div className={importedStyles.covidAnimation}>
                            {this.renderAnimation2()}
                        </div>

                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
    });
};
export default connect(mapStateToProps)(ImpetraThanksPage);