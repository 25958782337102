import {combineReducers} from "redux";
import snackReducer from "../components/Snack/SnackReducer";
import modalsReducer from "../modules/Modals/ModalsReducer";
import braceletListReducer from "../modules/BraceletList/BraceletListReducer";
import patientReducer from "../modules/Patient/PatientReducer";
import assignReducer from "../modules/Modals/AssignMailModal/AssignReducer";
import oldModalsReducer from "../modules/oldLanding/oldModals/oldModalsReducer";



const rootReducer = combineReducers({
    snackReducer,
    authReducer: modalsReducer,
    oldModalsReducer,
    braceletListReducer,
    patientReducer,
    assignReducer
});

export default rootReducer;