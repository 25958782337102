import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import importedStyles from "./PhotosDialog.module.sass";
import {connect} from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import OpenLinkIcon from '@material-ui/icons/OpenInNewRounded';
import PdfIcon from '@material-ui/icons/PictureAsPdfRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import NextArrowIcon from '@material-ui/icons/NavigateNext';
import BeforeArrowIcon from '@material-ui/icons/NavigateBefore';

class PhotosDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activePhoto: 0,
        };
    }

    handleInputChange = (increase) => {
        this.setState({activePhoto: this.state.activePhoto + increase});
    };

    renderContent = () => {
        let url = this.props.photos[this.state.activePhoto];

        if (!url) return false;

        let extension = url.split('.').pop();


        if (extension === 'pdf') {
            // let win = window.open(url, '_blank');
            // win.focus();

            return (
                <a href={url} target={'blank'} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <PdfIcon style={{width: '7rem', height: '7rem', color: '#000'}}/>
                    <span style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
                        Abrir PDF <OpenLinkIcon style={{marginLeft: 5}}/>
                    </span>
                </a>

            )

        } else {
            return (
                <img
                    className={importedStyles.photo}
                    src={url}
                    alt={'Documento'}
                />
            )
        }

    }


    render() {
        const {fullScreen} = this.props;

        return (
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    maxWidth={'md'}
                    fullWidth
                    open={this.props.open}
                    onClose={this.props.handleModal}
                    disableBackdropClick
                >
                    <DialogTitle>
                        {this.props.documentName}
                        <IconButton onClick={() => {
                            this.props.handleModal();
                            this.setState({activePhoto: 0})
                        }} className={importedStyles.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>

                    </DialogTitle>
                    <DialogContent className={importedStyles.flexColumn}>
                        <IconButton
                            onClick={() => this.handleInputChange(-1)}
                            className={importedStyles.stepBeforeButton}
                            style={this.state.activePhoto === 0 ? {visibility: 'hidden'} : {}}
                        >
                            <BeforeArrowIcon/>
                        </IconButton>
                        <DialogContent style={{
                            minHeight: '50vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {this.renderContent()}
                        </DialogContent>


                        <IconButton
                            onClick={() => this.handleInputChange(1)}
                            className={importedStyles.stepNextButton}
                            style={this.state.activePhoto === this.props.photos.length - 1 ? {visibility: 'hidden'} : {}}
                        >
                            <NextArrowIcon/>
                        </IconButton>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

PhotosDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    documentName: PropTypes.string,
    open: PropTypes.bool.isRequired,
    photos: PropTypes.array.isRequired,
    handleModal: PropTypes.func.isRequired,
};
const mapStateToProps = ({snackReducer}) => {
    return ({
        snackData: snackReducer.data,
    });
};
export default connect(mapStateToProps)(withMobileDialog()(PhotosDialog));
