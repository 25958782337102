import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import importedStyles from "./EmergencyStepsModal.module.sass";
import {connect} from "react-redux";
import Snack from "../../../components/Snack/Snack";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import {
    clean_emergency_steps,
    clean_patient_data,
    patient_emergency_steps_handle_modal,
    update_bracelet_uuid,
    update_emergency_steps
} from "../PatientActions";
import {Add} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
    CircularProgress,
    DialogActions,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import baseRoute from "../../../helpers/baseRoute";
import {showSnack} from "../../../components/Snack/SnackActions";
import {update_list} from "../../BraceletList/BraceletListActions";
import history from '../../../helpers/history';
import {set_assign_flow_flag, set_creating_new_user_flag} from "../../Modals/ModalsActions";
import {clean_responsible_data} from "../../Modals/AssignMailModal/AssignActions";
import Tour from "reactour";

class EmergencyStepsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addingStep: false,
            stepText: '',
            sendingData: false,
            allBasicData: false,
            oneStepMin: false,
            activeStep: this.props.activeStep,
            emergencySteps: [],
            isTourOpen: true,
            uxTutorialSteps: [
                {
                    position: 'right',
                    selector: '.userTutorialTarget',
                    content: (
                        <div className={importedStyles.tutoWrapper}>
                            <Typography variant={"subheading"} gutterBottom>Estos pasos serán lo primero <br/> que vea alguien que escanee tu pulsera</Typography>
                            <div className={importedStyles.tutoImgWrapper}>
                                <img height={325} alt={'preview pasos'}
                                       src={require('../../../img/stepsMock.jpg')}

                                />
                            </div>
                            <Button color={"primary"} variant={"contained"}
                                    onClick={() => this.setState({isTourOpen: false})}>Entendido</Button>
                        </div>
                    ),
                }
            ]
        };
    }

    componentDidMount(): void {
        if (this.props.onAssignFlow){
            this.setState({emergencySteps: []});
            this.props.dispatch(clean_emergency_steps());
        } else {
            this.setState({emergencySteps: this.props.emergency_steps});
        }
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if (!this.props.onAssignFlow) {
            this.setState({emergencySteps: nextProps.emergency_steps});
        }
    }


    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleModal = () => {
        this.setState({open: !this.state.open});
    };


    closeModal = () => {
        //Set redux state braceletUuid to null
        this.props.dispatch(update_bracelet_uuid(null));
        this.props.dispatch(patient_emergency_steps_handle_modal());
        this.props.dispatch(clean_emergency_steps());
        //Reset emergency steps state to original in order to avoid changes
        this.setState({emergencySteps: this.props.emergency_steps});

        if (this.props.onAssignFlow) {
            this.props.dispatch(clean_patient_data());
            if (this.props.creatingNewUser) {
                this.props.dispatch(set_creating_new_user_flag(false));
                this.props.dispatch(set_assign_flow_flag(false));
                this.props.dispatch(clean_responsible_data());

                if (this.props.braceletEntity === 'Impetra_Farma'){
                    history.push('/impetraThanksPage');
                } else {
                    history.push('/');
                }
            } else {
                this.props.dispatch(set_assign_flow_flag(false));
                this.props.dispatch(clean_responsible_data());
                history.push('/list');
            }
        }
    };

    renderStepsList() {

        const steps = this.state.emergencySteps;
        let listItems = [];

        Object.keys(steps).forEach((key) => {
            let step = steps[key];
            listItems.push(
                <Draggable key={key} draggableId={key} index={step.position}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            /*style={this.getItemStyle(
                               snapshot.isDragging,
                               provided.draggableProps.style
                            )}*/
                        >
                            <ListItem
                                className={snapshot.isDragging ? importedStyles.listItemDragging : importedStyles.listItem}
                            >
                                <ListItemIcon>
                                    {step.position + 1}
                                </ListItemIcon>
                                <ListItemText primary={step.text}/>
                                <ListItemSecondaryAction className={importedStyles.listItemAction}>
                                    <Tooltip title={'Eliminar paso'} placement={"top"}>
                                        <IconButton onClick={() => this.deleteStepFromList(step.position)}>
                                            <CloseIcon style={{color: '#f22200'}}/>
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </div>
                    )}
                </Draggable>
            )
        });


        return (
            <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            //style={getListStyle(snapshot.isDraggingOver)}
                        >
                            <List>
                                {listItems}
                            </List>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    addStepToList = () =>{
        let newIndex = this.state.emergencySteps ? this.state.emergencySteps.length : 0;
        let newStep = {
            text: this.state.stepText,
            position: newIndex
        };
        let updatedEmergencySteps = Object.assign([], this.state.emergencySteps);
        updatedEmergencySteps.push(newStep);
        this.setState({stepText: '', addingStep: false, emergencySteps: updatedEmergencySteps});
    };
    deleteStepFromList = (stepPosition) => {
        let updatedSteps = Object.assign([], this.state.emergencySteps);
        updatedSteps.splice(stepPosition, 1);

        //change position property by order in array with the keys
        Object.keys(updatedSteps).forEach((key) => {
            updatedSteps[key].position = parseInt(key);
        });

        this.setState({emergencySteps: updatedSteps});
    };

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedSteps = this.reorder(
            this.state.emergencySteps,
            result.source.index,
            result.destination.index
        );

        //change position property by order in array with the keys
        Object.keys(reorderedSteps).forEach((key) => {
            reorderedSteps[key].position = parseInt(key);
        });

        this.setState({emergencySteps: reorderedSteps});
        //this.props.dispatch(update_emergency_steps(reorderedSteps));
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    saveSteps(){
        this.setState({sendingData: true});
        fetch(baseRoute + 'api/patients/saveEmergencySteps', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    braceletUuid: this.props.braceletUuid,
                    emergencySteps: this.state.emergencySteps,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                //Update bracelets with response info
                this.props.dispatch(update_list(response.bracelets));
                //close modal
                this.props.dispatch(patient_emergency_steps_handle_modal());
                this.setState({sendingData: false});

                if (this.props.creatingNewUser || this.props.onAssignFlow){
                    this.props.dispatch(showSnack('Pasos de emergencia guardados, información registrada con éxito', 'success'));
                    this.props.dispatch(clean_patient_data());
                    this.props.dispatch(set_creating_new_user_flag(false));
                    this.props.dispatch(set_assign_flow_flag(false));
                    this.props.dispatch(clean_responsible_data());
                    history.push('/');
                } else {
                    //show snack
                    this.props.dispatch(showSnack('Pasos de emergencia guardados con éxito', 'success'));
                }
                //clean steps in redux
                this.props.dispatch(clean_emergency_steps());
            } else {
                throw Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error: ', error);
            this.props.dispatch(showSnack('Algo ha fallado al guardar los pasos, intentalo de nuevo', 'error'));
            this.setState({
                error: true,
                sendingData: false
            });
        });
    };

    cleanSteps = () => {
        this.props.dispatch(update_emergency_steps([]));
        this.props.dispatch(showSnack('Se han eliminado todos los pasos', 'success'));
    };

    displayUserTutorial(){
        if (this.props.creatingNewUser){
            return (
                <Tour
                    highlightedMaskClassName={importedStyles.UxTutoMask}
                    steps={this.state.uxTutorialSteps}
                    isOpen={this.state.isTourOpen}
                    rounded={10}
                    maskSpace={18}
                    showCloseButton={false}
                    disableInteraction={true}
                    showNavigation={false}
                    showButtons={false}
                    showNumber={false}
                    disableDotsNavigation={true}
                    onRequestClose={() => this.setState({isTourOpen: false})}
                    className={importedStyles.tutoParentWrapper}
                />
            )
        }
    }

    render() {
        const {fullScreen} = this.props;
        return (
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.emergencyStepsModalOpened}
                    onClose={this.closeModal}
                    disableBackdropClick
                    fullWidth={true}
                    maxWidth={"sm"}
                >
                    <DialogTitle>
                        {"Pasos a seguir en caso de emergencia"}
                        <Typography variant={"subtitle1"} className={importedStyles.dialogSubtext}>
                            Añade pasos o instrucciones que puedan ser de ayuda a quién escanee esta pulsera
                        </Typography>
                        {!this.props.onAssignFlow &&
                            <IconButton onClick={this.closeModal} disabled={this.props.sendingData}
                                        className={importedStyles.closeModalIcon}>
                                <CloseIcon/>
                            </IconButton>
                        }
                    </DialogTitle>
                    <DialogContent>
                        {this.props.onAssignFlow && this.displayUserTutorial()}
                        <Grid container spacing={8} className={[importedStyles.stepsContainer, 'userTutorialTarget']}>
                            {!this.state.addingStep &&
                            <Grid item xs={12} sm={12}>
                                <Button
                                    className={importedStyles.addButton}
                                    variant={'contained'}
                                    fullWidth
                                    color={"primary"}
                                    onClick={() => this.setState({addingStep: true})}
                                    disabled={this.state.addingStep}
                                >
                                    <AddIcon className={importedStyles.addButtonIcon}/>Añadir paso
                                </Button>
                            </Grid>
                            }
                            {this.state.addingStep &&
                            <Grid container item xs={12} spacing={16} id={'newContactFormContainer'}
                                  className={importedStyles.newStepFormContainer}>
                                <Grid item xs={12} className={importedStyles.stepsFormControlContainer}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            className={importedStyles.textField}
                                            variant={"outlined"}
                                            label="Nuevo paso"
                                            name={"stepText"}
                                            type="text"
                                            fullWidth
                                            value={this.state.stepText}
                                            onChange={this.handleInputChange}
                                            onKeyPress={(e) => {
                                                if (e.which === 13) {
                                                    if (this.state.stepText.length > 0) {
                                                        this.addStepToList();
                                                    }
                                                }
                                            }}
                                            inputProps={{
                                                maxLength: 125
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={importedStyles.newStepActionButtons}>
                                    <Button
                                        variant={"text"}
                                        style={{color: 'black'}}
                                        onClick={() => this.setState({stepText: '', addingStep: false})}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        className={importedStyles.addButton}
                                        color={"primary"}
                                        variant={"contained"}
                                        onClick={this.addStepToList}
                                        disabled={!this.state.stepText.length > 0}
                                    >
                                        <Add className={importedStyles.addButtonIcon}/>Añadir
                                    </Button>
                                </Grid>
                            </Grid>
                            }
                            {this.state.emergencySteps && this.state.emergencySteps.length > 0 &&
                            <Grid item xs={12} sm={12} className={[importedStyles.stepsGrid, '']}>
                                <Typography variant="h6" gutterBottom style={{color: "#979797"}}>
                                    Lista de pasos
                                </Typography>
                                <div className={importedStyles.stepsListContainer}>
                                    {this.renderStepsList()}
                                </div>
                            </Grid>
                            }
                        </Grid>
                    </DialogContent>
                    {!this.state.addingStep &&
                        <DialogActions>
                            <Button
                                variant={"text"}
                                style={{color: 'grey'}}
                                onClick={this.closeModal}
                                disabled={this.state.sendingData}
                            >
                                Cancelar
                            </Button>
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                className={importedStyles.saveButton}
                                onClick={() => this.saveSteps()}
                                disabled={this.state.sendingData}
                            >
                                Guardar
                                {this.state.sendingData &&
                                <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                            </Button>
                        </DialogActions>
                    }
                </Dialog>
                <Snack/>
            </div>
        );
    }
}

EmergencyStepsModal.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = ({snackReducer, patientReducer, authReducer, assignReducer}) => {
    return ({
        userUuid: authReducer.user.uuid,
        onAssignFlow: authReducer.onAssignFlow,
        snackData: snackReducer.data,
        emergencyStepsModalOpened: patientReducer.emergencyStepsModalOpened,
        sendingData: patientReducer.sendingData,
        braceletUuid: patientReducer.braceletUuid,
        emergency_steps: patientReducer.emergencySteps,
        creatingNewUser: authReducer.creatingNewUser,
        braceletEntity: assignReducer.braceletEntity,
    });
};
export default connect(mapStateToProps)(withMobileDialog()(EmergencyStepsModal));