import React from 'react';
import importedStyles from "./tutorialCompra.module.sass";
import Navbar from "../../../components/Navbar/Navbar";
import ResponsiveEmbed from 'react-responsive-embed';

class tutorialCompra extends React.Component {

    render() {
        let height = window.innerHeight;

        return (
            <div style={{height: height}} className={importedStyles.container}>
                <div>
                    <Navbar path={window.location.pathname} className={importedStyles.navBar}
                            style={{backgroundColor: '#4FA9B3'}}/>
                </div>
                   {/*
                   </div>
                   <video height={height < width ? height-120 : 'auto'} width={height < width ? 'auto' : width-40} controls>
                        <source src="./tutoriales/compra.mp4" type="video/mp4"/>
                        Tu navegador no soporta video HTML5, por seguridad te recomendamos cambiarlo por uno más
                        actual.
                    </video>
                    </div>
                    */}
                    <div className={importedStyles.embedBox}>
                        {/*<YouTube video={'quUrgnTFFSc'} allowFullScreen ratio='16:9' width={'100%'} height={'100%'} autoplay onEnd={(event)=>event.target.playVideo()}/>*/}
                        <ResponsiveEmbed src='https://www.youtube.com/embed/quUrgnTFFSc?loop=1&playlist=quUrgnTFFSc' allowFullScreen ratio='16:9'/>
                    </div>

            </div>
        );
    }
}

export default tutorialCompra;