import React from 'react';
import videojs from 'video.js'
import 'videojs-youtube/dist/Youtube.min';
import 'video.js/dist/video-js.css';
import 'video.js/dist/video-js.min.css';
import '@videojs/themes/dist/sea/index.css';
import '@videojs/themes/dist/fantasy/index.css';
import ReactPixel from "react-facebook-pixel";
import ReactGA from 'react-ga';

export default class VideoPlayer extends React.Component {

    state = {
        videoPlayed: false
    };

    componentDidMount() {
        const self = this;
        // instantiate Video.js
        this.player = videojs(this.videoNode, this.props, function onPlayerReady() {

            this.on('play', ()=> {
                if (!this.state.videoPlayed){
                    ReactPixel.trackCustom('LandingVideoPlayed');
                    ReactGA.ga('send', 'event', 'click', 'playVideo');
                    this.setState({videoPlayed: true});
                }
            });

            this.on('ended', self.props.onVideoEnds);
        });
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        return (
            <div>
                <div data-vjs-player>
                    <video ref={ node => this.videoNode = node } data-setup='{}' controls className="video-js vjs-default-skin vjs-big-play-centered" style={{fontfamily: 'VideoJS !important'}}></video>
                </div>
            </div>
        )
    }
}