import alertNotification from "../../../../animations/alertNotification";
import Lottie from "react-lottie";
import React from "react";
import importedStyles from "./BeforeCloseAlertModal.module.sass";
import {DialogContentText} from "@material-ui/core";
import Button from "@material-ui/core/Button/index";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import IconButton from "@material-ui/core/IconButton/index";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent/index";
import Dialog from "@material-ui/core/Dialog/index";
import baseRoute from "../../../../helpers/baseRoute";
import {showSnack, snackTypes} from "../../../../components/Snack/SnackActions";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import bye from "../../../../animations/bye";
import Cookies from "universal-cookie";
import ReactPixel from "react-facebook-pixel";


class BeforeCloseAlertModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            subscriptionSucceed: false,
            isValidMail: false,
        };
    }


    renderAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: alertNotification,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                    speed={0.7}
            />
        )
    };
    renderSucceedAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: bye,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                    speed={0.5}
            />
        )
    };

    handleInput = (e) => {
        let email = e.target.value;
        let regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        let isValidMail = regex.test(email);

        this.setState({email: email, isValidMail: isValidMail});
    };

    handleScrollToDocuments = () => {
        this.props.onClose();
        let offset = document.getElementById("weHelpYou").offsetTop;
        window.scrollTo({top: offset - 170, behavior: "smooth"});
    };

    handleSubscription = () => {
        this.setState({sendingData: true});
        fetch(baseRoute + 'api/newsletters/newSubscription', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: this.state.email,
                    requestedDocument: this.props.requestedDocument
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setNewsletterCokkie();
                this.setState({subscriptionSucceed: true});

                //If succeed then track an event on fb pixel
                ReactPixel.trackCustom('subscribedToNewsletter', {email: this.state.email});

            } else {
                throw new Error(response.errorMessage);
            }
            this.setState({sendingData: false});
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    setNewsletterCokkie = () => {
        const cookies = new Cookies();
        let expireDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        cookies.set('subscribedToNewsletter', true, {path: '/', expires: expireDate});
    };

    renderDialogContent = () => {
        let dialogContent = '';

        if (this.state.subscriptionSucceed) {
            dialogContent = (
                <React.Fragment>
                    <DialogContentText className={importedStyles.dialogContentText}>
                        ¡Genial, te avisaremos de todas las novedades en PulsID!
                    </DialogContentText>
                    {this.renderSucceedAnimation()}

                    <DialogActions className={importedStyles.dialogActions}>
                        <Button
                            variant={"contained"}
                            className={importedStyles.mainActionButton}
                            color={"secondary"} size={"large"}
                            onClick={this.props.onClose}
                        >
                            Cerrar
                        </Button>
                    </DialogActions>
                </React.Fragment>
            );
        } else {
            dialogContent = (
                <React.Fragment>
                    {this.renderAnimation()}
                    <DialogContentText className={importedStyles.dialogContentText}>
                        Suscríbete y consigue hasta 30 % de descuento en exclusiva. ¡Te llegarán las mejores ofertas
                        antes que a nadie!
                    </DialogContentText>
                    <TextField
                        variant={"outlined"}
                        margin="normal"
                        label="Correo"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleInput}
                        fullWidth
                    />
                    <DialogActions className={importedStyles.dialogActions}>
                        <Button
                            variant={"contained"}
                            color={"primary"} size={"large"}
                            className={importedStyles.mainActionButton}
                            onClick={this.handleSubscription}
                            disabled={!this.state.isValidMail || this.state.email.length <= 0 || this.state.sendingData}
                        >
                            Subscribirme {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                    </DialogActions>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {dialogContent}
            </React.Fragment>
        );
    };

    renderDialogContentDocuments = () => {
        return (
            <React.Fragment>
                <DialogContentText className={importedStyles.dialogContentText}>
                    Antes de despedirnos nos gustaria que vieras nuestros documentos de ayuda, ¡Son muy útiles!
                </DialogContentText>
                <Button variant={"contained"} color={"primary"} className={importedStyles.mainActionButton} fullWidth
                        onClick={this.handleScrollToDocuments}>
                    Llévame a ellos
                </Button>
            </React.Fragment>

        )
    };

    render() {
        const {fullScreen} = this.props;
        return (
            <React.Fragment>
                <Dialog
                    fullScreen={fullScreen}
                    //open={this.props.open}
                    open={false}
                    onClose={this.props.onClose}
                    disableBackdropClick
                    fullWidth={true}
                    maxWidth={"sm"}
                    onblur={true}
                >
                    <DialogTitle>
                        ¿Ya te vas?
                        <IconButton onClick={this.props.onClose} className={importedStyles.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {this.renderDialogContent()}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    };
}


BeforeCloseAlertModal.propTypes = {
    open: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};
const mapStateToProps = ({snackReducer, patientReducer, authReducer}) => {

    return ({
        onAssignFlow: authReducer.onAssignFlow,

    });
};

export default connect(mapStateToProps)(withMobileDialog()(BeforeCloseAlertModal));