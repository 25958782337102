import React from 'react';
import importedStyles from "./OurHistory.module.sass";
import history from '../../helpers/history';
import Snack from "../../components/Snack/Snack";
import ReactGA from 'react-ga';
import Navbar from "../../components/Navbar/Navbar";
import connect from "react-redux/es/connect/connect";
import Image from 'react-image-webp';
import {Helmet} from "react-helmet";
import ReactPixel from "react-facebook-pixel";
import 'video.js/dist/video-js.css';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TapIcon from '@material-ui/icons/TouchApp';

class OurHistory extends React.Component {

    state = {
        showHistory: false,
        showWhat: false,
    };

    componentDidMount() {
        ReactPixel.pageView();
        ReactGA.pageview('/nuestraHistoria');
    }

    componentWillUnmount() {
    }

    handleBuyModal = () => {
        ReactPixel.trackCustom('buyModalOpen', {description: 'El usuario ha empezado el flujo de compra'});
        ReactGA.modalview('/buyModalOpened');
        history.push('/?shop=true');
    };

    getCTA(CTAStyle, bagdeStyle, copy) {
        return (
            <div className={importedStyles.CTAContainer}>
                <a href={'https://buy.stripe.com/28o02A11s6Jtbm0dQQ'} target={'_blank'}
                   style={{textDecoration: 'none'}}>
                    <Button className={[CTAStyle, 'primaryCTA']}>
                        {copy}
                        <TapIcon className={importedStyles.tapIcon}/>
                    </Button>
                </a>
            </div>
        );
    }


    render() {
        return (
            <div className={importedStyles.Landing}>
                <Navbar path={window.location.pathname} hidden={true}/>
                <Helmet>
                    <title>PulsID</title>
                    <meta name="description"
                          content="Protege a tus seres queridos con PulsID, nuestra pulsera contra emergencias"/>
                    <meta name="keywords"
                          content="pulsid, pulsera, emergencia, proteger, alzheimer, epilepsia, qr ,hospital, residencia"/>
                </Helmet>
                <Grid container id={'history'} className={importedStyles.historyContainer}>

                    <Grid item xs={12} md={4} className={importedStyles.historyContainerContent}>
                        <h1>¿Cómo surge PulsID?</h1>
                        <p>Cuando éramos pequeños, mi padre nos confeccionó a mi hermano y a mí
                            una chapa con datos relevantes que permitiera identificarnos en caso de
                            emergencia.</p>
                        <p>
                            Cuando mi hija con diversidad funcional comenzó a sufrir desmayos
                            repentinos decidí crear para ella una chapa como la mía que informara de su
                            situación y permitiera ayudarla de forma inmediata.
                            Sin embargo, conscientes de la necesidad de muchas personas como
                            nosotros de proteger a los suyos, decidimos crear PulsID, un
                            proyecto social, asequible y fácil de usar, que permite incluir no solo los datos
                            de contacto de la persona, sino toda la información médica relevante para
                            acceder a ella con un sencillo gesto.
                        </p>

                    </Grid>
                    <Grid item xs={12} md={6} className={importedStyles.historyImageContainer}>
                        <Image
                            src={require('../../img/ancianos.png')}
                            webp={require('../../img/ancianos.webp')}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {this.getCTA(importedStyles.primaryCTAButton, importedStyles.pulsidPriceBadgeDesktop, 'Consíguela ya')}
                    </Grid>
                </Grid>
                <div className={importedStyles.footerContainer}>
                    <div className={importedStyles.footerContent}>
                        <span style={{textAlign: 'center'}}> Si tienes alguna duda o deseas más información contactanos en <br/>
                        <a target={'_blank'} href={'mailto:hola@gmail.com'}>hola@mipulsid.com</a>
                        </span>
                        <a className={importedStyles.sevi} href={'https://sevisl.com/'}>Copyright © Sociedad Europea de
                            Verificación de Identidad 2020</a>
                        <br/>
                        <div className={importedStyles.footerLawsContainer}>
                            <p className={importedStyles.privacy} onClick={() => history.push('/law')}>Aviso legal</p>
                            <p> y </p>
                            <p className={importedStyles.privacy} onClick={() => history.push('/privacy')}>política de
                                privacidad</p>
                        </div>
                        <Image
                            className={importedStyles.seviLogo}
                            src={require('../../img/logoBrandBlue.png')}
                            webp={require('../../img/logoBrandBlue.webp')}
                            alt={'SEVI'}
                        />
                        <div className={importedStyles.footerLogosContainer}>
                            <Image
                                style={{width: 75}}
                                src={require('../../img/enisa.png')}
                                webp={require('../../img/enisa.webp')}
                            />
                            <Image
                                style={{width: 125}}
                                src={require('../../img/logoIvace.png')}
                                webp={require('../../img/logoIvace.webp')}
                            />
                        </div>
                    </div>
                </div>
                <div className={importedStyles.finalImgContainer}>
                    <Image
                        src={require('../../img/fondoolas2.png')}
                        webp={require('../../img/fondoolas2.webp')}
                    />
                </div>
                <Snack/>
            </div>
        );
    }
}

const mapStateToProps = ({authReducer, braceletListReducer}) => {
    return ({
        user: authReducer.user,
    });
};
export default connect(mapStateToProps)(OurHistory);
