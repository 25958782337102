function baseUrl() {

    /* Local DEV server */
      //return 'http://localhost:8000/';

    /* Remote DEV server */
      //return 'https://backtest.mipulsid.com/';

    /* PRODUCTION servers */
      return 'https://backend.mipulsid.com/';


}

export default baseUrl();