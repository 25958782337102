import React from 'react';
import importedStyles from "./FAQ.module.sass";
import Navbar from "../../components/Navbar/Navbar";
import Grid from "@material-ui/core/es/Grid";
import ExpansionPanelDetails from "@material-ui/core/es/ExpansionPanelDetails";
import Typography from "@material-ui/core/es/Typography";
import ExpansionPanelSummary from "@material-ui/core/es/ExpansionPanelSummary";
import ExpansionPanel from "@material-ui/core/es/ExpansionPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Card from "@material-ui/core/es/Card";
import TidioChat from "../../components/TidioChat/TidioChat";
import TapIcon from '@material-ui/icons/TouchApp';
import Button from "@material-ui/core/Button";

class FAQ extends React.Component {

    state = {
        expanded: null,
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    render() {
        const {expanded} = this.state;
        return (
            <Grid container className={importedStyles.parentContainer}>
                <Grid item xs={12}>
                    <Navbar path={window.location.pathname} hidden={false}
                            style={{backgroundColor: '#4FA9B3 !important'}}/>
                </Grid>
                <Grid item xs={8} md={8} className={importedStyles.headImageContainer}>
                    <img className={importedStyles.headImage} height={145} src={require('../../img/logo_celeste.png')}
                         alt={'faqs icon'}/>
                    <div className={importedStyles.headImageWrapper}>
                        <Typography variant={"h4"}>
                            Resolvemos tus dudas
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={10} md={6}>
                    <Card className={importedStyles.faqCard}>
                        <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color={"primary"}/>}>
                                <Typography variant={"h6"}>¿Cuánto tarda en llegar el pedido?</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={importedStyles.panelDetails}>
                                <Typography className={importedStyles.panelDetailsTypography}>
                                    Gracias a nuestro complejo sistema de envíos descentralizado puedes recibir tu
                                    pulsID en 3-4 días laborales.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color={"primary"}/>}>
                                <Typography variant={"h6"}>
                                    ¿El pago es seguro?
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={importedStyles.panelDetails}>
                                <Typography className={importedStyles.panelDetailsTypography}>
                                    Totalmente. Nunca guardamos datos de tu tarjeta ya que usamos la plataforma
                                    Redsys para gestionar los pagos. ¡Con pulsID estarás seguro desde el primer segundo!
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel3'} onChange={this.handleChange('panel3')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color={"primary"}/>}>
                                <Typography variant={"h6"}>
                                    ¿Mis datos están seguros?
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={importedStyles.panelDetails}>
                                <Typography className={importedStyles.panelDetailsTypography}>
                                    Absolutamente. En pulsID somos conscientes de los tiempos que corren y por ello
                                    usamos un complejo sistema de cifrado para proteger tus
                                    datos personales. Nadie, salvo quien tú quieras, podrá verlos.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel4'} onChange={this.handleChange('panel4')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color={"primary"}/>}>
                                <Typography variant={"h6"}>
                                    ¿Sólo es posible elegir un color de pulsera?
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={importedStyles.panelDetails}>
                                <Typography className={importedStyles.panelDetailsTypography}>
                                    Actualmente solo disponemos de nuestro cálido color turquesa.<br/>
                                    ¿Necesitas otro color? Ponte en contacto con nosotros.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel5'} onChange={this.handleChange('panel5')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color={"primary"}/>}>
                                <Typography variant={"h6"}>
                                    ¿La pulsera y el código QR son resistentes?
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={importedStyles.panelDetails}>
                                <Typography className={importedStyles.panelDetailsTypography}>
                                    Hacemos pasar a todas nuestras pulseras por exigentes pruebas de fatiga
                                    que garantizan su durabilidad por muuucho tiempo.
                                    <br/> Por cierto, ¡ten cuidado con las sustancias corrosivas! (Lejía, aguarrás,
                                    etc).
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel6'} onChange={this.handleChange('panel6')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color={"primary"}/>}>
                                <Typography variant={"h6"}>
                                    ¿Quién puede atenderme si llevo la pulsera?
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={importedStyles.panelDetails}>
                                <Typography className={importedStyles.panelDetailsTypography}>
                                    Todo el mundo, cualquier persona puede escanear el código QR de tu pulsera con un
                                    teléfono móvil. Además colaboramos con distintos cuerpos de Policía Local y
                                    Cruz Roja de todo el país que ya conocen pulsID.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel7'} onChange={this.handleChange('panel7')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color={"primary"}/>}>
                                <Typography variant={"h6"}>
                                    ¿Que pasa si no se puede leer correctamente el código QR?
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={importedStyles.panelDetails}>
                                <Typography className={importedStyles.panelDetailsTypography}>
                                    Si no puedes leer correctamente el código QR podrás observar que existe un
                                    código de texto al lado del mismo, dirigete a <a href={'https://mipulsid.com/clave'}
                                                                                     target={'_blank'}>mipulsid.com/codigo</a>, introduce este código de texto
                                    (no hace falta respetar mayúsculas y minúsculas) y pulsa en 'Ver datos'.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Card>
                </Grid>
                <Grid item xs={12} className={importedStyles.buttonContainer}>
                    <a href={'https://buy.stripe.com/28o02A11s6Jtbm0dQQ'} target={'_blank'} style={{textDecoration: 'none'}}>
                        <Button className={importedStyles.primaryCTAButton}>
                           Cómprala aquí
                            <TapIcon className={importedStyles.tapIcon}/>
                        </Button>
                    </a>
                </Grid>
                <TidioChat/>
            </Grid>
        );
    }
}

export default FAQ;
