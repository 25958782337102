import {
    SET_RESPONSIBLE_USER_UUID,
    SET_RESPONSIBLE_PHONE,
    SET_RESPONSIBLE_NAME,
    CLEAN_RESPONSIBLE_DATA, SET_BRACELET_ENTITY,
} from "../../../constants/action-types";

export const set_bracelet_entity = (entity) => {
    return ({
        type: SET_BRACELET_ENTITY,
        braceletEntity: entity
    });
};
export const set_responsible_user_uuid = (userUuid) => {
    return ({
        type: SET_RESPONSIBLE_USER_UUID,
        responsibleUserUuid: userUuid
    });
};

export const set_responsible_phone = (phone) => {
    return ({
        type: SET_RESPONSIBLE_PHONE,
        responsiblePhone: phone
    });
};

export const set_responsible_name = (name) => {
    return ({
        type: SET_RESPONSIBLE_NAME,
        responsibleName: name
    });
};

export const clean_responsible_data = () => {
    return ({
        type: CLEAN_RESPONSIBLE_DATA
    });
};