import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import importedStyles from "./PatientModal.module.sass";
import {connect} from "react-redux";
import Snack from "../../components/Snack/Snack";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import BasicDataForm from "./FormComponents/BasicDataForm/BasicDataForm";
import MedicalDataForm from "./FormComponents/MedicalDataForm/MedicalDataForm";
import DocumentsDataForm from "./FormComponents/DocumentsDataForm/DocumentsDataForm";
import ContactsDataForm from "./FormComponents/ContactsDataForm/ContactsDataForm";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
    clean_patient_data,
    next_step_active,
    previous_step_active,
    update_bracelet_uuid
} from "./PatientActions";

class PatientModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendingData: false,
            allBasicData: false,
            oneContactMin: false,
            activeStep: this.props.activeStep
        };
    }

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleModal = () => {
        this.setState({open: !this.state.open});
    };

    handleNext = () => {
       this.props.dispatch(next_step_active());
    };

    handleBack = () => {
        this.props.dispatch(previous_step_active());
    };

    closeModal = () => {
        //Set redux state braceletUuid to null and clean all patient data
        this.props.dispatch(update_bracelet_uuid(null));
        this.props.dispatch(clean_patient_data());
    };

    renderModalContent() {
        switch (this.props.activeStep) {
            case 0:
                return <BasicDataForm handleNext={() => this.handleNext()} handleBack={() => this.handleBack()}/>;
            case 1:
                return <ContactsDataForm handleNext={() => this.handleNext()} handleBack={() => this.handleBack()}/>;
            case 2:
                return <DocumentsDataForm handleNext={() => this.handleNext()} handleBack={() => this.handleBack()} />;
            case 3:
                return <MedicalDataForm handleNext={() => this.handleNext()} handleBack={() => this.handleBack()} />;
            default:
                return <span>{"Aqui el content"}</span>;
        }
    }

    render() {
        const {fullScreen} = this.props;
        return (
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.modalOpened}
                    onClose={this.handleModal}
                    disableBackdropClick
                    fullWidth={true}
                    maxWidth={"sm"}
                >
                    <DialogTitle>
                        {this.props.onAssignFlow ? "Nueva pulsera" : 'Editar pulsera'}
                        {!this.props.onAssignFlow &&
                             <IconButton onClick={this.closeModal} disabled={this.props.sendingData} className={importedStyles.closeModalIcon}>
                                <CloseIcon/>
                            </IconButton>
                        }
                    </DialogTitle>
                    <DialogContent >
                        {this.renderModalContent()}
                    </DialogContent>
                </Dialog>
                <Snack/>
            </div>
        );
    }
}

PatientModal.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};
const mapStateToProps = ({snackReducer, patientReducer, authReducer}) => {
    return ({
        snackData: snackReducer.data,
        modalOpened: patientReducer.modalOpened,
        activeStep: patientReducer.activeStep,
        sendingData: patientReducer.sendingData,
        onAssignFlow: authReducer.onAssignFlow,
    });
};
export default connect(mapStateToProps)(withMobileDialog()(PatientModal));