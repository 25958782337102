import React from 'react';
import importedStyles from "./codeModal.module.sass";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import Grid from "@material-ui/core/es/Grid/Grid";
import TextField from "@material-ui/core/es/TextField/TextField";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import Button from "@material-ui/core/es/Button/Button";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import baseRoute from "../../helpers/baseRoute";
import history from "../../helpers/history";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import connect from "react-redux/es/connect/connect";
import Snack from "../../components/Snack/Snack";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

class CodeModal extends React.Component {

    state = {
        code: '',
        sendingData: false,
    };

    componentDidMount(): void {

        if (this.props.match.params.braceletCode){
            this.setState({code: this.props.match.params.braceletCode}, () => {
                this.checkCode();
            })
        }
    }

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    checkCode = () => {
        this.setState({
            sendingData: true
        });
        fetch(baseRoute + 'api/bracelets/checkCode', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    braceletCode: this.state.code
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if(!response.error){
                history.push('/users/' + response.braceletUuid);
            } else {
                this.props.dispatch(showSnack('El código introducido no existe en el sistema', snackTypes.error));
            }
            this.setState({sendingData: false});
        }).catch((error) => {
            this.props.dispatch(showSnack('Ups, parece que ha ocurrido algún error', snackTypes.error));
            this.setState({
                sendingData: false
            });
        });

    };

    renderMainButton = () => {
        let buttonText = 'Activar';

        return (
            <Button
                className={importedStyles.buttonSubmit}
                variant={"contained"}
                fullWidth
                onClick={this.checkCode}
                disabled={this.state.sendingData || !this.state.code.length > 0 }
            >
                {buttonText}
                {this.state.sendingData && <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
            </Button>
        );
    };

    render() {

        return (
            <div>
            <Dialog
                fullScreen={true}
                open={true}
                disableBackdropClick
            >
                <DialogTitle>
                    {"Comprobar código"}
                    <IconButton onClick={()=>history.goBack()} className={importedStyles.closeModalIcon}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent className={importedStyles.flexColumn}>
                    <DialogContentText>
                        Introduce el código que verás al lado del QR:
                    </DialogContentText>
                    <Grid container spacing={16} className={importedStyles.mainContainer}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                className={importedStyles.textField}
                                autoFocus
                                variant={"outlined"}
                                margin="normal"
                                label="Código"
                                name={"code"}
                                type="text"
                                inputProps={{
                                    maxLength: 8
                                }}
                                value={this.state.code}
                                fullWidth
                                onChange={this.handleInputChange}
                                onKeyPress={(e)=> {
                                    if (e.which === 13 && this.state.code.length > 0) {
                                        this.checkCode();
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant={'subtitle2'}>
                            <i>Puedes usar mayúsculas o minúsculas indistintamente</i>
                        </Typography>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions className={importedStyles.flexColumn}>
                    {this.renderMainButton()}
                </DialogActions>
            </Dialog>
            <Snack/>
            </div>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
    });
};
export default connect(mapStateToProps)(CodeModal);