import {ADD_BRACELET_LIST, HANDLE_VIEW_DETAIL, UPDATE_BRACELET_LIST} from "../../constants/action-types";

const initialState = {
    braceletList: [],
    viewDetail: false,
    selecting: false,
};

const braceletListReducer = (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_BRACELET_LIST:
            return {...state, braceletList: action.braceletList};
        case HANDLE_VIEW_DETAIL:
            return {...state, viewDetail: action.bool};
        case ADD_BRACELET_LIST:
            let newBraceletList = [...state.braceletList];
            newBraceletList.push(action.newBracelet);
            return {...state, braceletList: newBraceletList};
        default:
            return state;
    }
};

export default braceletListReducer;