export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const ACCEPT_COOKIES = "ACCEPT_COOKIES";
export const SHOW_BUY_MODAL = "SHOW_BUY_MODAL";
export const SET_CREATING_NEW_USER_FLAG = "SET_CREATING_NEW_USER_FLAG";
export const SET_ASSIGN_FLOW_FLAG = "SET_ASSIGN_FLOW_FLAG";
export const CLOSE_ASSIGN_MODALS = "CLOSE_ASSIGN_MODALS";

export const SET_BRACELET_ENTITY = "SET_BRACELET_ENTITY";
export const SET_RESPONSIBLE_USER_UUID = "SET_RESPONSIBLE_USER_UUID";
export const SET_RESPONSIBLE_NAME = "SET_RESPONSIBLE_NAME";
export const SET_RESPONSIBLE_PHONE = "SET_RESPONSIBLE_PHONE";
export const CLEAN_RESPONSIBLE_DATA = "CLEAN_RESPONSIBLE_DATA";


export const UPDATE_BRACELET_LIST = "UPDATE_BRACELET_LIST";
export const ADD_BRACELET_LIST = "ADD_BRACELET_LIST";
export const HANDLE_VIEW_DETAIL = "HANDLE_VIEW_DETAIL";

export const PATIENT_UPDATE_BASIC_DATA = "PATIENT_UPDATE_BASIC_DATA";
export const PATIENT_UPDATE_MEDIC_DATA = "PATIENT_UPDATE_MEDIC_DATA";
export const PATIENT_UPDATE_EMERGENCY_STEPS = "PATIENT_UPDATE_EMERGENCY_STEPS";
export const PATIENT_UPDATE_DOCUMENTS_DATA = "PATIENT_UPDATE_DOCUMENTS_DATA";
export const PATIENT_UPDATE_CONTACTS_DATA = "PATIENT_UPDATE_CONTACTS_DATA";
export const PATIENT_UPDATE_BRACELET_UUID = "PATIENT_UPDATE_BRACELET_UUID";
export const PATIENT_CLEAN_DATA = "PATIENT_CLEAN_DATA";
export const PATIENT_CLEAN_EMERGENCY_STEPS = "PATIENT_CLEAN_EMERGENCY_STEPS";
export const PATIENT_UPDATE_EDITTING = "PATIENT_UPDATE_EDITTING";
export const PATIENT_NEXT_STEP = "PATIENT_NEXT_STEP";
export const PATIENT_PREVIOUS_STEP = "PATIENT_PREVIOUS_STEP";
export const PATIENT_HANDLE_MODAL = "PATIENT_HANDLE_MODAL";
export const PATIENT_HANDLE_EMERGENCY_STEPS_MODAL = "PATIENT_HANDLE_EMERGENCY_STEPS_MODAL";
export const PATIENT_SENDING_DATA = "PATIENT_SENDING_DATA";
export const PATIENT_UPDATE_UUID = "PATIENT_UPDATE_UUID";


export const SHOW_SNACK = "SHOW_SNACK";

