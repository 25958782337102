import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";


class TidioChat extends React.Component {

    state = {};

    componentWillUnmount(): void {

        // Remove the iframe and code that charges the live chat in order to preserve other views from be able to use it
        this.removeChatScript();
    }

    removeChatScript = () =>{
        let chat = document.getElementById('tidio-chat-iframe');
        let chatCode = document.getElementById('tidio-chat-code');
        if (chat) {
            chat.parentNode.removeChild(chat);
        }
        if (chatCode) {
            chatCode.parentNode.removeChild(chatCode);
        }
    };

    componentDidMount(): void {


    }

    changeTidioStyles() {
        //prevent tidio widget to collision with other modals
        let interval = window.setInterval(function () {
            let tidioChat = document.getElementById('tidio-chat-iframe');
            if (tidioChat) {
                tidioChat.style.setProperty("z-index", "3000", "important");
                clearInterval(interval);
            }
        }, 100);
    };

    renderChatScript = () => {
        if (this.props.show) {
            return (
                <Helmet>
                    <script id={'tidioChatScript'} src={'//code.tidio.co/hif84v11njvdvlb3m3mkomxygqj6rtuo.js'} async
                            onLoad={this.changeTidioStyles()}/>
                </Helmet>
            )
        } else {
            this.removeChatScript();
        }
    };

    render() {

        return (
            <React.Fragment>
                {this.renderChatScript()}
            </React.Fragment>
        );
    }
}

TidioChat.propTypes = {
    show: PropTypes.bool.isRequired,
};
TidioChat.defaultProps = {
    show: true,
};

export default TidioChat;