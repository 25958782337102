const sevi = `
 
███████╗███████╗██╗   ██╗██╗                                 
██╔════╝██╔════╝██║   ██║██║                                 
███████╗█████╗  ██║   ██║██║                                 
╚════██║██╔══╝  ╚██╗ ██╔╝██║                                 
███████║███████╗ ╚████╔╝ ██║                                 
╚══════╝╚══════╝  ╚═══╝  ╚═╝                                                                                                                            
`;

/*███████╗██╗   ██╗███████╗████████╗███████╗███╗   ███╗███████╗
██╔════╝╚██╗ ██╔╝██╔════╝╚══██╔══╝██╔════╝████╗ ████║██╔════╝
███████╗ ╚████╔╝ ███████╗   ██║   █████╗  ██╔████╔██║███████╗
╚════██║  ╚██╔╝  ╚════██║   ██║   ██╔══╝  ██║╚██╔╝██║╚════██║
███████║   ██║   ███████║   ██║   ███████╗██║ ╚═╝ ██║███████║
╚══════╝   ╚═╝   ╚══════╝   ╚═╝   ╚══════╝╚═╝     ╚═╝╚══════╝*/

const runDevelopment = '========> Run in development <=========';

export default {
  sevi,
  runDevelopment,
};
