import React from 'react';
import importedStyles from "./Privacy.module.sass";
import Navbar from "../../components/Navbar/Navbar";

class BuyConditions extends React.Component {

    state = {};

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <div className={importedStyles.privacy}>
                    Por favor, lee con atención
                    No dudes en consultarnos cualquier duda
                    <hr/>

                    CONDICIONES GENERALES DE www.mipulsid.com
                    <sub>© Documento Protegido por Copyright</sub>


                    <h2>0. OBJETO</h2>
                    Las presentes Condiciones Generales regulan la adquisición de los productos ofertados en el sitio
                    web mipulsid.com, del que es titular Sociedad Europea de Verificación de Identidad S.L. (en
                    adelante, SEVI Systems).
                    La adquisición de cualesquiera de los productos conlleva la aceptación plena y sin reservas de todas
                    y cada una de las Condiciones Generales que se indican, sin perjuicio de la aceptación de las
                    Condiciones Particulares que pudieran ser de aplicación al adquirir determinados productos.
                    Estas Condiciones Generales podrán ser modificadas sin notificación previa, por tanto, es
                    recomendable leer atentamente su contenido antes de proceder a la adquisición de cualquiera de los
                    productos ofertados.


                    <h2>1. IDENTIFICACIÓN</h2>
                    SEVI Systems, en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
                    Información y de Comercio Electrónico, le informa de que:
                    • Su denominación social es: Sociedad Europea de Verificación de Identidad S.L..
                    • Su nombre comercial es: SEVI Systems.
                    • Su CIF es: B44503985.
                    • Su domicilio social está en: C\Santa Caterina 21,2º 3 - 12540 Vila Real - CASTELLÓN DE LA PLANA
                    (ESPAÑA).
                    • Está inscrita en el Registro Mercantil de CASTELLÓN DE LA PLANA Inscrita en el Registro Mercantil
                    de Castellón, el 26/03/18, en el Tomo 1756 , Folio 86, Inscripción 1, Hoja CS-40883.


                    <h2>2. COMUNICACIONES</h2>
                    Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos
                    a continuación:
                    • Email: rgpd@sevisl.com.
                    Todas las notificaciones y comunicaciones entre los usuarios y SEVI Systems se considerarán
                    eficaces, a todos los efectos, cuando se realicen a través de cualquier medio de los detallados
                    anteriormente.


                    <h2>3. PRODUCTOS</h2>
                    Los productos ofertados, junto con las características principales de los mismos y su precio
                    aparecen en pantalla.
                    SEVI Systems se reserva el derecho de decidir, en cada momento, los productos y/o servicios que se
                    ofrecen a los Clientes. De este modo, SEVI Systems podrá, en cualquier momento, añadir nuevos
                    productos y/o servicios a los ofertados actualmente. Asimismo SEVI Systems se reserva el derecho a
                    retirar o dejar de ofrecer, en cualquier momento, y sin previo aviso, cualesquiera de los productos
                    ofrecidos.
                    Todo ello sin perjuicio de que la adquisición de alguno de los productos sólo podrá hacerse mediante
                    la suscripción o registro del USUARIO, otorgando por parte de SEVI Systems al interesado un nombre
                    de Usuario y una contraseña, los cuales le identificarán y habilitarán personalmente para poder
                    tener acceso a los productos.
                    Una vez dentro de mipulsid.com, y para acceder a la contratación de los distintos
                    productos, el Usuario deberá seguir todas las instrucciones indicadas en el proceso de compra, lo
                    cual supondrá la lectura y aceptación de todas las condiciones generales y particulares fijadas en
                    mipulsid.com.
                    OBSERVACIONES
                    Datos médicos opcionales, suministrados por el usuario.


                    <h2>4. DISPONIBILIDAD Y SUSTITUCIONES</h2>
                    PRODUCTOS
                    SEVI Systems pretende garantizar la disponibilidad de todos los productos que están publicitados a
                    través de mipulsid.com. No obstante, en el caso de que cualquier producto solicitado no
                    estuviera disponible o si el mismo se hubiera agotado, se le informará al cliente vía correo
                    electrónico lo antes posible.
                    De no hallarse disponible el producto, y habiendo sido informado de ello el consumidor, SEVI Systems
                    podrá suministrar un producto de similares características sin aumento de precio, en cuyo caso, el
                    consumidor podrá aceptarlo o rechazarlo ejerciendo su derecho de desistimiento y resolución del
                    contrato.
                    En caso de indisponibilidad de la totalidad o parte del pedido, y el rechazo de los productos de
                    sustitución por el cliente, el reembolso previamente abonado, se efectuará en la tarjeta de crédito
                    con la que realizó la compra
                    Si SEVI Systems se retrasara injustificadamente en la devolución de las cantidades abonadas, el
                    consumidor podrá reclamar el doble de la cantidad adeudada.


                    <h2>5. PROCESO DE COMPRA Y/O CONTRATACIÓN</h2>
                    Para realizar cualquier compra en mipulsid.com, es necesario que el cliente sea mayor de
                    edad.
                    Además será preciso que el cliente se registre en mipulsid.com a través de un formulario de
                    recogida de datos en el que se proporcione a SEVI Systems la información necesaria para la
                    contratación; datos que en cualquier caso serán veraces, exactos y completos sobre su identidad y
                    que el cliente deberá consentir expresamente mediante la aceptación de la política de privacidad de
                    SEVI Systems.
                    Los pasos a seguir para realizar la compra son:
                    <ol>
                        <li>Registro de correo electrónico</li>
                        <li>Compra del producto</li>
                        <li>envío de mail con las claves para ingresar a la plataforma para cargar los datos personales en un .log del servidor</li>
                    </ol>

                    Asimismo es recomendable que el cliente imprima y/o guarde una copia en soporte duradero de las
                    condiciones de venta al realizar su pedido, así como también del comprobante de recepción enviado
                    por SEVI Systems por correo electrónico.
                    FACTURACIÓN
                    Junto con su pedido, recibirá la correspondiente factura.
                    A tal efecto, el cliente consiente que dicha factura se le remita en formato electrónico. El cliente
                    la recibirá en su correo electrónico. Podrá, en cualquier momento, revocar este consentimiento si
                    para recibir la factura en papel.


                    <h2>6. DURACIÓN, RENOVACIÓN Y CANCELACIÓN DEL CONTRATO</h2>
                    DURACIÓN
                    La duración es indeterminada, siempre y cuando la empresa esté en funcionamiento.
                    RENOVACIÓN
                    No hay renovación de los servicios.
                    CANCELACIÓN
                    Se puede cancelar previo aviso con quince días de anticipación.


                    <h2>7. ENTREGA</h2>
                    PRODUCTOS
                    El pedido será entregado en el domicilio designado por el cliente en el momento de la contratación
                    según la modalidad escogida.
                    El ámbito de entrega recoge las siguientes zonas: todo el territorio español.
                    FORMAS Y PLAZOS DE ENTREGA
                    1. envío postal:
                    Enviado por el servicio postal que escoja el cliente.
                    El plazo máximo de entrega es de 7 día/s.
                    Dependerá del servicio escogido por el cliente, el producto será puesto a disposición de la agencia
                    a las 48 horas laborales, máximo de realizado el pedido.


                    <h2>8. PRECIOS, GASTOS E IMPUESTOS</h2>
                    Los precios de los productos que se recogen en mipulsid.com vendrán expresados en Euros
                    salvo que se exprese lo contrario y estarán garantizados durante el tiempo de validez de los mismos,
                    salvo error de impresión y sea cual sea el destino del pedido.
                    PRODUCTOS
                    Los precios finales de todos los productos, (y en su caso, los precios finales por unidad de medida,
                    cuando sea obligatorio) junto a sus características, las ofertas, descuentos, etc., se reflejan en
                    cada uno de los artículos que aparecen en el catálogo de mipulsid.com.
                    • Los productos tienen el IVA incluido.
                    • El tipo de IVA es del 21.
                    • Gastos de envío: No incluidos, depende de la elección del cliente.
                    Los precios de los productos pueden variar en cualquier momento. En este caso, el precio a aplicar
                    será el vigente en la fecha de realización del pedido. De cualquier modo, el coste final será
                    comunicado al cliente en el proceso de compra electrónica antes de que éste formalice su aceptación.


                    <h2>9. FORMAS DE PAGO</h2>
                    Ponemos a su disposición estas formas de pago:
                    a. Por tarjeta de crédito o débito
                    Puede realizar el pago de manera inmediata a través de la pasarela de pago seguro SSL de nuestro
                    Banco. Si fuera necesario realizar cualquier devolución o cancelación del pedido, el importe se
                    reembolsará a través de la misma tarjeta de pago. Los datos tanto personales como bancarios serán
                    protegidos con todas las medidas de seguridad establecidas por la Ley Orgánica de Protección de
                    Datos 15/1999 y su Reglamento de Desarrollo con total confidencialidad.
                    gastos que repercuta la entidad bancaria en el cliente.
                    b. Por Transferencia bancaria
                    Mediante ingreso en efectivo o transferencia bancaria a favor de: Entidad: Caixa Rural de Nules.
                    Número de cuenta: ES27 3135 7483 2210 2101 7835. Debiendo indicar en el momento de realizar la
                    transferencia el nombre del cliente y número de pedido.
                    el coste de la transferencia será asumido por el cliente.
                    OBSERVACIONES
                    Si paga mediante tarjeta de crédito, una vez completado el pago, comenzará el proceso para el envío
                    del producto. Si paga mediante transferencia, deberá incluir su mail en la referencia, y una vez
                    comprobado el ingreso, comenzará el proceso para el envío.


                    <h2>10. POLÍTICA DE DEVOLUCIONES Y DESISTIMIENTO</h2>
                    Según establece la normativa, el consumidor y usuario tendrá derecho a DESISTIR del contrato durante
                    un período máximo de 14 días naturales sin necesidad de indicar el motivo y sin incurrir en ningún
                    coste, salvo los previstos en el art. 107.2 y 108 del RD1/2007, de 16 de noviembre, por el que se
                    aprueba el texto referido de la Ley General para la Defensa de los Consumidores y Usuarios.
                    Este derecho de desistimiento no es aplicable entre otros a:
                    • Prestación de servicios ya ejecutados o comenzados.
                    • Los contratos de suministro de productos confeccionados conforme a las especificaciones del
                    consumidor y usuario o, claramente personalizados, o que por su naturaleza no puedan ser devueltos o
                    puedan deteriorarse o caducar con rapidez.
                    PRODUCTOS
                    El cliente dispondrá de un plazo máximo de 14 días naturales desde el día de la recepción del bien o
                    producto solicitado para informar a SEVI Systems sobre su deseo de desistir del contrato.
                    El desistimiento implica que, SEVI Systems procederá a la devolución del importe ya abonado por el
                    cliente en un plazo máximo de 14 días naturales, siguiendo el mismo procedimiento elegido por el
                    cliente para su abono (siempre que el producto adquirido no esté dentro de las excepciones indicadas
                    anteriormente).
                    Durante este período, el consumidor comunicará al empresario su decisión de desistir del contrato
                    rellenando para ello el formulario que se encuentra al final de este documento y enviándolo a la
                    dirección de correo electrónico de SEVI Systems.
                    SEVI Systems comunicará al consumidor en soporte duradero un acuse de recibo de dicho desistimiento.
                    Para proceder a la devolución de un producto es imprescindible que el mismo se encuentre en perfecto
                    estado y con su correspondiente envoltorio original, con todos sus accesorios y sin haber sido
                    utilizado.
                    Una vez recibamos su solicitud nos pondremos en contacto con el cliente para indicarle los detalles
                    de la devolución.
                    El cliente soportará los costes de devolución de los bienes.


                    <h2>11. GARANTÍA</h2>
                    Los productos adquiridos a través de mipulsid.com gozan de un período de garantía de 12
                    meses desde el momento de su adquisición, que cubren los posibles defectos de fabricación del
                    producto, así como cualquier avería producida durante dicho periodo.
                    En caso de que el Qr no pudiera ser escaneado, por diversas causas, se procederá al cambio inmediato
                    de la pulsera, debiendo el usuario asumir el coste del envío a nuestras oficinas. Deberá enviar la
                    pulsera en un sobre al Servicio de Atención Técnica.
                    Se excluyen de las garantías los productos consumibles, las deficiencias ocasionadas por uso o
                    manipulaciones indebidas, conexión a la red eléctrica diferente a la indicada, instalación
                    incorrecta o los defectos derivados de toda causa exterior.
                    En los productos de naturaleza duradera, el consumidor tendrá derecho a un adecuado servicio técnico
                    y a la existencia de repuestos durante un mínimo de 5 años desde la fecha en que el producto deje de
                    fabricarse, sin que pueda incrementarse el precio de los repuestos al aplicarlos en las
                    reparaciones.
                    Para conocer las gestiones que deben seguirse ante los problemas o las averías en un producto pueden
                    contactar con el fabricante o el SAT en las direcciones que aparecen en el siguiente listado:
                    <ul>
                        <li>Fabricante: SEVI SYSTEMS S.L.</li>
                        <li>Email de asistencia: sac@sevisl.com</li>
                        <li>Web del fabricante: www.sevisl.com</li>
                    </ul>


                    Para evitar problemas con la instalación o el manejo del producto comprado, asegúrese de haber
                    seguido las instrucciones de instalación y funcionamiento contenidas en los manuales de usuario.
                    Si aun así, el producto adquirido no funciona correctamente deberá contactar de inmediato con los
                    servicios de atención al cliente y asistencia técnica del fabricante, o en su caso del representante
                    o distribuidor.
                    Habitualmente los servicios técnicos exigirán que les facilite los siguientes datos:
                    <ul>
                        <li>Número de factura</li>
                        <li>Identificación del modelo concreto</li>
                        <li>Número de serie</li>
                    </ul>


                    <h2>12. RESPONSABILIDAD Y EXONERACIÓN DE RESPONSABILIDAD</h2>
                    El consumidor y usuario tiene derecho a la reparación del producto, a su sustitución, a la rebaja
                    del precio o a la resolución del contrato, según lo establecido en el Capítulo II Tít. IV Libro II
                    de la Ley General para la Defensa de los Consumidores y Usuarios.
                    Si el producto no fuera conforme con el contrato, el consumidor podrá optar entre la reparación o la
                    sustitución del producto, siendo ambas gratuitas para el consumidor, las cuales se llevarán a cabo
                    en un plazo de tiempo razonable en función de la naturaleza de los productos y la finalidad a la que
                    estuvieran destinadas.
                    Si concluida la reparación y entregado el producto, éste sigue siendo no conforme al contrato, el
                    consumidor podrá exigir su sustitución, la rebaja del precio o la resolución del contrato.
                    AMPLIACIÓN DE INFORMACIÓN
                    Los datos que el usuario decida compartir, serán los que puedan visualizar las emergencias o cuerpos
                    de seguridad. La veracidad de dichos datos es de la exclusiva responsabilidad del cliente. En caso
                    de adquirir el producto para un menor de edad, deberá poder demostrar el vínculo.


                    <h2>13. ATENCIÓN AL CLIENTE Y RECLAMACIONES</h2>
                    Para interponer cualquier queja o reclamación ante SEVI Systems el cliente deberá dirigirse al
                    departamento de atención al cliente a través de la dirección de correo electrónico
                    direccion@sevisl.com.
                    Según la Resolución de litigios en línea en materia de consumo de la UE y conforme al Art. 14.1 del
                    Reglamento (UE) 524/2013, le informamos que la Comisión Europea facilita a todos los consumidores
                    una plataforma de resolución de litigios en línea que se encuentra disponible en el siguiente
                    enlace: http://ec.europa.eu/consumers/odr/.


                    <h2>14. LEGISLACIÓN APLICABLE</h2>
                    Las condiciones presentes se regirán por la legislación española vigente.
                    La lengua utilizada será el Castellano.

                </div>
                <Navbar path={window.location.pathname} hidden={this.state.hidden}/>
            </div>
        );
    }
}

export default BuyConditions;