import React from 'react';
import importedStyles from "./tutorial.module.sass";
import Navbar from "../../components/Navbar/Navbar";
import ResponsiveEmbed from 'react-responsive-embed';
import TidioChat from "../../components/TidioChat/TidioChat";

class tutorial extends React.Component {

    componentDidMount() {
    }

    render() {
        let height = window.innerHeight;

        return (
            <div style={{height: height}} className={importedStyles.container}>
                <div>
                    <Navbar path={window.location.pathname} className={importedStyles.navBar}
                            style={{backgroundColor: '#4FA9B3'}}/>
                </div>
                {/*
                     <div className={importedStyles.videoBox}>
                   <video height={height < width ? height-120 : 'auto'} width={height < width ? 'auto' : width-40} controls>
                        <source src="./tutoriales/datos.mp4" type="video/mp4"/>
                        Tu navegador no soporta video HTML5, por seguridad te recomendamos cambiarlo por uno más
                        actual.
                    </video>
                    </div>
                    */}
                <div className={importedStyles.embedBox}>
                    <ResponsiveEmbed src='https://www.youtube.com/embed/qXCEB2Jppbk?loop=1&playlist=qXCEB2Jppbk' allowFullScreen ratio='16:9'/>
                </div>
                <TidioChat/>
            </div>
        );
    }
}

export default tutorial;