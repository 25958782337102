import {
    LOGIN_SUCCESS,
    LOGOUT,
    ACCEPT_COOKIES,
    SHOW_BUY_MODAL,
    SET_CREATING_NEW_USER_FLAG, SET_ASSIGN_FLOW_FLAG
} from "../../../constants/action-types";

export const login_success = (user) => {
    return ({
        type: LOGIN_SUCCESS,
        user: user,
    });
};

export const logout = (user) => {
    return ({
        type: LOGOUT
    });
};
export const accept_cookies = () => {
    return ({
        type: ACCEPT_COOKIES
    });
};
export const show_buy_modal = (newState) => {
    return ({
        type: SHOW_BUY_MODAL,
        showBuyModal: newState
    });
};

export const set_creating_new_user_flag = (boolean) => {
    return ({
        type: SET_CREATING_NEW_USER_FLAG,
        creatingNewUser: boolean
    });
};

export const set_assign_flow_flag = (boolean) => {
    return ({
        type: SET_ASSIGN_FLOW_FLAG,
        onAssignFlow: boolean
    });
};