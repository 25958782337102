import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import messages from './helpers/messages';
import * as serviceWorker from './serviceWorker';
import 'whatwg-fetch';

if (!('fetch' in window)) {
    console.warn('Fetch API not found on your browser, fallback to polyfill');
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
console.log(messages.sevi);
