import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card/Card';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import PlacesAutocomplete from 'react-places-autocomplete';
import FormControl from "@material-ui/core/FormControl/FormControl";
import importedStyles from "./oldBuyModals.module.sass";
import baseRoute from "../../../helpers/baseRoute";
import ArrowForward from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import getCountryCodes from "../../../helpers/getCountryCodes";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import {connect} from "react-redux";
import Snack from "../../../components/Snack/Snack";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import Grid from "@material-ui/core/Grid/Grid";
import Validators from "../../../helpers/validators";
import SnackbarContent from "@material-ui/core/es/SnackbarContent/SnackbarContent";
import Snackbar from "@material-ui/core/es/Snackbar/Snackbar";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/es/Typography/Typography";
import ReactPixel from "react-facebook-pixel";
import loadingPaymentRequest from "../../../animations/loadingPaymentRequest";
import redirectToSecure from "../../../animations/redirectToSecure";
import check from "../../../animations/check";
import loadingDots from "../../../animations/loadingDots";
import Lottie from "react-lottie";
import Image from "react-image-webp";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {CardContent} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import {isWebpSupported} from "react-image-webp/dist/utils";

const braceletPrice = 16.95;
const packagingPrice = 2;

const shippingPrice = 3.75;
const stockS = Math.floor(Math.random() * (15 - 9)) + 9;
const stockM = Math.floor(Math.random() * (9 - 5)) + 5;
const stockL = Math.floor(Math.random() * (15 - 9)) + 9;

class OldBuyModals extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.user && props.newBracelet) {
            this.state = {
                validMail: true,
                sendingData: false,
                openRegisterModal: false,
                openPaymentModal: false,
                privacityTermsCheck: false,
                address: this.props.user.addresses && this.props.user.addresses[0] ? this.props.user.addresses[0].address : '',
                zipcode: this.props.user.addresses && this.props.user.addresses[0] ? this.props.user.addresses[0].zipCode : '',
                email: this.props.user.mail ? this.props.user.mail : '',
                name: this.props.user.name ? this.props.user.name : '',
                documentId: this.props.user.documentId ? this.props.user.documentId : '',
                braceletsS: 0,
                braceletsM: 0,
                braceletsL: 0,
                totalBracelets: 0,
                includeShipping: 'free',
                discount: this.props.promoCode ? this.props.promoCode : '',
                discountObject: '',
                giftStickerContent: '',
                city: this.props.user.addresses && this.props.user.addresses[0] ? this.props.user.addresses[0].city : '',
                province: this.props.user.addresses && this.props.user.addresses[0] ? this.props.user.addresses[0].region : '',
                country: this.props.user.addresses && this.props.user.addresses[0] ? this.props.user.addresses[0].country : '',
                openSnackTotalBracelets: false,
                includePackaging: false,
                packagingQuantity: 1,
                firstCartAddition: true,
                firstCheckoutResume: true,
                waitingForPaymentRequestPackage: false,
                waitingForRedsysRedirect: false,
                openLoadingPaymentRequestModal: false,
                elementFontSize: window.innerWidth < 450 ? '14px' : '18px',
                pageHasUserData: false,
                currentStep: 1,
            };
        } else {
            this.state = {
                validMail: true,
                sendingData: false,
                openRegisterModal: false,
                openPaymentModal: false,
                privacityTermsCheck: false,
                address: '',
                zipcode: '',
                email: '',
                name: '',
                documentId: '',
                braceletsS: 0,
                braceletsM: 0,
                braceletsL: 0,
                totalBracelets: 0,
                includeShipping: 'free',
                discount: this.props.promoCode ? this.props.promoCode : '',
                discountObject: '',
                giftStickerContent: '',
                city: '',
                province: '',
                country: 'spain',
                includePackaging: false,
                packagingQuantity: 1,
                firstCartAddition: true,
                openSnackTotalBracelets: false,
                firstCheckoutResume: true,
                waitingForPaymentRequestPackage: false,
                waitingForRedsysRedirect: false,
                openLoadingPaymentRequestModal: false,
                elementFontSize: window.innerWidth < 450 ? '14px' : '18px',
                pageHasUserData: false,
                currentStep: 1,
            };
        }

        this.handleBraceletSelect = this.handleBraceletSelect.bind(this);
        this.handlePaymentModal = this.handlePaymentModal.bind(this);
    }

    componentDidMount(): void {

        if (this.props.promoCode) {
            this.checkDiscount();
        }

        //Listen to page close to avoid accidental closing or refreshing
        window.onbeforeunload = (e) => this.handlePossibleAccidentalExit(e);
    }

    handlePossibleAccidentalExit = (e) => {

        if (this.state.pageHasUserData) {
            e.preventDefault(); //for the standard
            e.returnValue = ''; //required for Chrome
        }

    };

    validateMail = () => {
        this.setState({validMail: Validators.validateEmail(this.state.email)});
    };

    hideImagePreloadSkeleton = size => {
        let skeleton = document.getElementById('skeleton' + size);
        if (skeleton) {
            skeleton.style.display = 'none';
        }
        let image = document.getElementById('pulsidImage' + size);
        if (image) {
            image.style.display = 'block';
        }
    };

    renderPulsidImageWebp = size => {
        return (
            <img id={'pulsidImage' + size} style={{display: 'none'}} src={require('../../../img/pulsid' + size + '.webp')}
                 className={importedStyles.sizeCardImg} alt={'pulsid pulsera' + size}
                 onLoad={() => this.hideImagePreloadSkeleton(size)}/>);
    };
    renderPulsidImage = size => {
        return (
            <img id={'pulsidImage' + size} style={{display: 'none'}} src={require('../../../img/pulsid' + size + '.jpg')}
                 className={importedStyles.sizeCardImg} alt={'pulsid pulsera' + size}
                 onLoad={() => this.hideImagePreloadSkeleton(size)}/>);
    };

    handleInputChange = (event) => {
        let onlyLettersRegex = /^[A-Za-zÀ-ÿ ]+$/;
        let onlyNumbersRegex = /^[0-9]+$/;
        let alphanumericRegex = /^[A-Za-z0-9]+$/;


        if (event.target.value.length > 0) {
            switch (event.target.name) {
                case 'includeShipping':
                    this.setState({includeShipping: event.target.value});
                case 'name':
                    if (onlyLettersRegex.test(event.target.value)) {
                        this.setState({[event.target.name]: event.target.value});
                    }
                    break;
                case 'zipcode':
                    if (onlyNumbersRegex.test(event.target.value)) {
                        this.setState({[event.target.name]: event.target.value});
                    }
                    break;
                case 'documentId':
                    if (alphanumericRegex.test(event.target.value)) {
                        let documentId = event.target.value.toUpperCase();
                        this.setState({[event.target.name]: documentId});
                    }
                    break;
                default:
                    this.setState({[event.target.name]: event.target.value});
                    break;
            }

        } else {
            this.setState({[event.target.name]: event.target.value});
        }

        this.setState({pageHasUserData: true});
    };

    handleGiftStickerContent = (e) => {
        let value = e.target.value;
        this.setState({giftStickerContent: value});
    };

    handlePackagingQuantity = (e) => {
        let value = e.target.value;
        let regex = /^[1-9]+$/;
        if (regex.test(value) && value.length === 1 && value <= this.state.totalBracelets) {
            this.setState({packagingQuantity: e.target.value});
        }
        this.setState({pageHasUserData: true});
    };

    handleCityChange = city => {
        let comaPosition = city.indexOf(',');
        if (comaPosition !== -1) {
            city = city.substring(0, comaPosition);
        }
        this.setState({city, pageHasUserData: true});
    };

    handleProvinceChange = province => {
        let comaPosition = province.indexOf(',');
        if (comaPosition !== -1) {
            province = province.substring(0, comaPosition);
        }
        this.setState({province, pageHasUserData: true});
    };

    renderAutocompleteProvinceInput() {
        const countryCode = getCountryCodes(this.state.country);
        const searchOptions = {
            componentRestrictions: {country: countryCode},
            types: ["(regions)"]
        };

        return (
            <PlacesAutocomplete
                searchOptions={searchOptions}
                value={this.state.province}
                onChange={this.handleProvinceChange}
                autoComplete={"nope"}
            >
                {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
                    <div className={importedStyles.autocompleteLocationInputs}>
                        <TextField
                            className={importedStyles.textField}
                            variant={"outlined"}
                            margin="dense"
                            name={"province"}
                            label="Provincia"
                            type="text"
                            fullWidth
                            {...getInputProps({
                                autoComplete: "nope"
                            })}
                        />

                        <Card>
                            {loading && <div>Generando sugerencias...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? {backgroundColor: '#c4c4c4', cursor: 'pointer', marginBottom: 5}
                                    : {backgroundColor: '#ffffff', cursor: 'pointer', marginBottom: 5};
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </Card>
                    </div>
                )}
            </PlacesAutocomplete>
        )
    }

    renderAutocompleteCityInput() {

        const countryCode = getCountryCodes(this.state.country);
        const searchOptions = {
            componentRestrictions: {country: countryCode},
            types: ["(cities)"]
        };

        return (
            <PlacesAutocomplete
                value={this.state.city}
                onChange={this.handleCityChange}
                searchOptions={searchOptions}
            >
                {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
                    <React.Fragment>
                        <TextField
                            className={importedStyles.textField}
                            variant={"outlined"}
                            margin="dense"
                            name={"city"}
                            label="Ciudad"
                            type="text"
                            fullWidth
                            {...getInputProps({
                                autoComplete: "nope"
                            })}
                        />

                        <div>
                            {loading && <div>Generando sugerencias...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? {backgroundColor: '#c4c4c4', cursor: 'pointer', marginBottom: 5}
                                    : {backgroundColor: '#ffffff', cursor: 'pointer', marginBottom: 5};
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </React.Fragment>
                )}
            </PlacesAutocomplete>
        )
    }

    handlePaymentModal() {
        this.setState({
            openPaymentModal: !this.state.openPaymentModal,
        });
    }

    handleCheckoutResume = () => {
        if (this.state.firstCheckoutResume) {
            ReactPixel.trackCustom('CheckoutResume');
            this.setState({firstCheckoutResume: false});
        }
        this.handlePaymentModal(); //Open payment data and checkout resume modal
        this.props.handleModal(); //Close products selector modal
    };

    handlePaymentRequest = () => {
        window.onbeforeunload = () => {
            //blank function do nothing
        };
        this.setState({sendingData: true, waitingForPaymentRequestPackage: true});
        this.handleLoadingPaymentRequestModal();

        let email = this.state.email;
        let name = this.state.name;
        let documentId = this.state.documentId;
        let discountId = this.state.discountObject ? this.state.discountObject.uuid : '';
        let address = this.state.address;
        let country = this.state.country;
        let province = this.state.province;
        let city = this.state.city;
        let zipcode = this.state.zipcode;
        let totalBracelets = this.state.totalBracelets;
        let braceletsS = this.state.braceletsS;
        let braceletsM = this.state.braceletsM;
        let braceletsL = this.state.braceletsL;
        let includePackaging = this.state.includePackaging;
        let giftStickerContent = this.state.giftStickerContent;
        let packagingQuantity = this.state.includePackaging ? this.state.packagingQuantity : 0;
        let includeShipping = this.state.includeShipping === 'premium';

        ReactPixel.track('InitiateCheckout', {braceletsQuantity: braceletsL + braceletsM + braceletsS});

        fetch(baseRoute + 'api/payments/createPaymentRequest', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: email,
                name: name,
                documentId: documentId,
                discountId: discountId,
                address: address,
                country: country,
                province: province,
                city: city,
                zipcode: zipcode,
                totalBracelets: totalBracelets,
                braceletsS: braceletsS,
                braceletsM: braceletsM,
                braceletsL: braceletsL,
                packaging: includePackaging,
                packagingQuantity: packagingQuantity,
                giftStickerContent: giftStickerContent,
                newBracelet: this.props.newBracelet,
                includeShipping: includeShipping,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((resp) => {
            const response = JSON.parse(resp);
            //console.log('respuesta: ', response);
            if (response.mailError) {
                this.props.dispatch(showSnack('¡Ya existe un usuario con este email!', snackTypes.error));
                this.setState({openLoadingPaymentRequestModal: false})
            } else {

                this.setState({
                    Ds_SignatureVersion: response.Ds_SignatureVersion,
                    Ds_MerchantParameters: response.Ds_MerchantParameters,
                    Ds_Signature: response.Ds_Signature,
                });

                //Change loadingAnimation to check animation, rotate dots animation and display it
                setTimeout(() => {
                    this.setState({waitingForPaymentRequestPackage: false});
                    document.getElementById('dotsAnimation').style.transform = 'rotate(90deg)';
                    document.getElementById('redsysAnimationContainer').style.opacity = 1;
                    //Redirect to redsys after 3s
                    setTimeout(() => {
                        document.getElementById('redsysForm').submit();
                    }, 2500);
                }, 2500);

            }
            this.setState({sendingData: false});
        }).catch((error) => {
            console.error('Error: ', error);
            this.setState({sendingData: false, openLoadingPaymentRequestModal: false});
        });

    };

    increaseOrderId() {
        fetch(baseRoute + 'api/payments/increaseOrderId', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                apiKey: 'M3QWZk`V|~hoB@vo{af{gy4aCz7,;N'
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((resp) => {
            // const response = JSON.parse(resp);
        });
    }

    handleBraceletSelectMobile = (action, target) => () => {

        this.setState({pageHasUserData: true});

        let nextValue = this.state[target];

        switch (action) {
            case 'add':
                nextValue = nextValue + 1;
                break;
            case 'remove':
                nextValue = nextValue - 1;
                break;
            default:
                //do nothing
                break;
        }

        let event = {
            target: {
                name: target,
                value: nextValue
            }
        };

        this.handleBraceletSelect(event);
    };

    handleBraceletSelect(event) {
        this.setState({
            openSnackTotalBracelets: false,
            pageHasUserData: true
        });

        if (this.state.firstCartAddition) {
            ReactPixel.track('AddToCart');
            this.setState({firstCartAddition: false});
        }

        if (event.target.value === '') {
            this.setState({[event.target.name]: event.target.value});
            this.setState({
                totalBracelets: parseInt(this.state.totalBracelets) - (this.state[event.target.name] ? parseInt(this.state[event.target.name]) : 0)
            });

        } else {
            if (event.target.value >= 0 && event.target.value < 6) {
                if ((parseInt(this.state.totalBracelets) - (this.state[event.target.name] ? parseInt(this.state[event.target.name]) : 0) + parseInt(event.target.value)) <= 5) {
                    this.setState({[event.target.name]: event.target.value});
                    this.setState((state) => ({
                        totalBracelets: (state.braceletsS ? parseInt(state.braceletsS) : 0) + (state.braceletsM ? parseInt(state.braceletsM) : 0) + (state.braceletsL ? parseInt(state.braceletsL) : 0),
                    }));

                } else {
                    if (!this.state.openSnackTotalBracelets) {
                        this.setState({openSnackTotalBracelets: true});
                    }
                }
            } else if (!this.state.openSnackTotalBracelets && event.target.value > 0) {
                this.setState({
                    openSnackTotalBracelets: true,
                });
            }
        }

        //Every change on the selectors must check packaging quantity and change it if it's greater than totalBracelets.
        let totalBracelets = 0;
        switch (event.target.name) {
            case 'braceletsS':
                totalBracelets = parseInt(this.state.braceletsM) + parseInt(this.state.braceletsL) + parseInt(event.target.value);
                break;
            case 'braceletsM':
                totalBracelets = parseInt(this.state.braceletsS) + parseInt(this.state.braceletsL) + parseInt(event.target.value);
                break;
            case 'braceletsL':
                totalBracelets = parseInt(this.state.braceletsS) + parseInt(this.state.braceletsM) + parseInt(event.target.value);
                break;
            default:
                totalBracelets = 0;

        }
        if (totalBracelets < this.state.packagingQuantity && totalBracelets > 0) {
            this.setState({packagingQuantity: totalBracelets});
        }

    }

    checkDiscount = () => {
        this.setState({sendingData: true});
        fetch(baseRoute + 'api/discounts/checkDiscount', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    code: this.state.discount,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({discount: '', sendingData: false, discountObject: response.discountObject});

                //show snack only if coming from promo links
                if (this.props.promoCode) {
                    this.props.dispatch(showSnack('¡Selecciona tu talla y obtén tu descuento promocional!', 'success'));
                }
            } else {
                throw new Error('error');
            }
        }).catch((error) => {
            this.setState({discount: '', sendingData: false});
            this.props.dispatch(showSnack('El código promocional no es válido', snackTypes.error));
        });
    };

    getTotalPrice = () => {
        let discountValue = 0;
        let totalValue = this.state.totalBracelets * braceletPrice;

        if (this.state.includePackaging) {
            totalValue = totalValue + (packagingPrice * this.state.packagingQuantity);
        }
        if (this.state.includeShipping === 'premium') {
            totalValue = totalValue + shippingPrice;
        }


        if (this.state.discountObject) {
            switch (this.state.discountObject.type) {
                case '€':
                    discountValue = this.state.discountObject.value;
                    break;
                case '%':
                    discountValue = (totalValue * (this.state.discountObject.value / 100)).toFixed(2);
                    break;
                default:
                    break;
            }
        }
        return (totalValue - discountValue).toFixed(2);
    };

    renderSizeSelector() {
        const styles = [importedStyles.textField, importedStyles.sizePicker];
        return (
            <Grid item xs={12} sm={12} className={importedStyles.sizeCardContainer}>
                <Card className={importedStyles.sizeCard}>
                   {/* <Image
                        className={importedStyles.sizeCardImg}
                        src={require('../../../img/pulsidBracelet.png')}
                        webp={require('../../../img/pulsidBracelet.webp')}
                        alt={'pulsid pulsera'}
                    />*/}
                    <div className={importedStyles.sizeCardImg} id={'skeletonL'}>
                        <Skeleton height={'10rem'} width={'100%'}/>
                    </div>
                    {isWebpSupported() ? this.renderPulsidImageWebp('L') : this.renderPulsidImage('L')}
                    <div className={importedStyles.sizeCardSize}>
                        <span className={importedStyles.braceletSizeCode}>L</span>
                        <div className={importedStyles.braceletSizeBox}>
                            <span>202 mm</span>
                        </div>
                        <span>Para las muñecas  más grandes</span>
                    </div>
                    <div className={importedStyles.sizeSelectorActions}>
                        <IconButton onClick={this.handleBraceletSelectMobile('remove', 'braceletsL')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <RemoveIcon/>
                        </IconButton>
                        <TextField
                            className={styles}
                            variant={"outlined"}
                            margin="normal"
                            label="Cantidad"
                            name={"braceletsL"}
                            type="number"
                            value={this.state.braceletsL}
                            onChange={this.handleBraceletSelect}
                        />
                        <IconButton onClick={this.handleBraceletSelectMobile('add', 'braceletsL')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <AddIcon/>
                        </IconButton>
                    </div>
                    <p className={importedStyles.sizeStock}>Sólo quedan {stockL} en stock</p>
                </Card>
                <Card className={importedStyles.sizeCard}>
                    <div className={importedStyles.sizeCardImg} id={'skeletonM'}>
                        <Skeleton height={'10rem'} width={'100%'}/>
                    </div>
                    {isWebpSupported() ? this.renderPulsidImageWebp('M') : this.renderPulsidImage('M')}
                    <div className={importedStyles.sizeCardSize}>
                        <span className={importedStyles.braceletSizeCode}>M</span>
                        <div className={importedStyles.braceletSizeBox}>
                            <span>180 mm</span>
                        </div>
                        <span>Para el adulto medio</span>
                    </div>
                    <div className={importedStyles.sizeSelectorActions}>
                        <IconButton onClick={this.handleBraceletSelectMobile('remove', 'braceletsM')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <RemoveIcon/>
                        </IconButton>
                        <TextField
                            className={styles}
                            variant={"outlined"}
                            margin="normal"
                            label="Cantidad"
                            name={"braceletsM"}
                            type="number"
                            value={this.state.braceletsM}
                            onChange={this.handleBraceletSelect}
                        />
                        <IconButton onClick={this.handleBraceletSelectMobile('add', 'braceletsM')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <AddIcon/>
                        </IconButton>
                    </div>
                    <p className={importedStyles.sizeStock}>Sólo quedan {stockM} en stock</p>
                </Card>
                <Card className={importedStyles.sizeCard}>
                    <div className={importedStyles.sizeCardImg} id={'skeletonS'}>
                        <Skeleton height={'10rem'} width={'100%'}/>
                    </div>
                    {isWebpSupported() ? this.renderPulsidImageWebp('S') : this.renderPulsidImage('S')}
                    <div className={importedStyles.sizeCardSize}>
                        <span className={importedStyles.braceletSizeCode}>S</span>
                        <div className={importedStyles.braceletSizeBox}>
                            <span>160 mm</span>
                        </div>
                        <span>Ideal para los peques</span>
                    </div>
                    <div className={importedStyles.sizeSelectorActions}>
                        <IconButton onClick={this.handleBraceletSelectMobile('remove', 'braceletsS')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <RemoveIcon/>
                        </IconButton>
                        <TextField
                            className={styles}
                            variant={"outlined"}
                            margin="normal"
                            label="Cantidad"
                            name={"braceletsS"}
                            type="number"
                            value={this.state.braceletsS}
                            onChange={this.handleBraceletSelect}
                        />
                        <IconButton onClick={this.handleBraceletSelectMobile('add', 'braceletsS')}
                                    className={importedStyles.sizeSelectorMobileButton}>
                            <AddIcon/>
                        </IconButton>
                    </div>
                    <p className={importedStyles.sizeStock}>Sólo quedan {stockS} en stock</p>
                </Card>
            </Grid>
        );
    }

    renderShippingSelector() {
        return (
            <div className={importedStyles.shippingPriceSelector}>
                <RadioGroup aria-label="shipping" name="includeShipping" defaultValue={false}
                            value={this.state.includeShipping} onChange={this.handleInputChange}>
                    <FormControlLabel
                        value={'free'}
                        control={<Radio color="primary"/>}
                        label={<span>Estándar - Recíbelo <b>GRATIS</b> en 5-6 días</span>}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value={'premium'}
                        control={<Radio color="primary"/>}
                        label="Premium - Recíbelo en 48h (+3.75€)"
                        labelPlacement="end"
                    />
                </RadioGroup>
            </div>
        );
    }

    renderPackagingSelector() {
        const styles = [importedStyles.textField, importedStyles.sizePicker];
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={importedStyles.packagingCheck}
                        control={
                            <Checkbox
                                checked={this.state.includePackaging}
                                onChange={() => this.setState({includePackaging: !this.state.includePackaging})}
                                className={importedStyles.checkbox}
                            />
                        }
                        label={<span
                            className={importedStyles.packagingCheckLabel}>Añadir caja PulsID para regalo con dedicatoria personalizada (+{packagingPrice}€/u)</span>}
                    />
                </Grid>
                <Grid item xs={12} sm={12} className={importedStyles.sizeCardContainer}>
                    <Card className={importedStyles.sizeCard}>
                        <Grid container spacing={8} className={importedStyles.braceletsSelectorGrid}>
                            {this.state.includePackaging &&
                            <Grid item md={6}>
                                <div className={importedStyles.sizeCardSize}>
                                </div>
                                <TextField
                                    className={styles}
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Cantidad"
                                    type="number"
                                    value={this.state.packagingQuantity}
                                    onChange={this.handlePackagingQuantity}
                                />
                                <TextField
                                    className={styles}
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Dedicatoria"
                                    helperText={"Personaliza tu caja con un nombre o mensaje dedicado (Máx. 40 caracteres)"}
                                    type="text"
                                    inputProps={{
                                        maxLength: 40
                                    }}
                                    value={this.state.giftStickerContent}
                                    onChange={this.handleGiftStickerContent}
                                />
                            </Grid>
                            }
                            <Grid item md={this.state.includePackaging ? 6 : 12}>
                                <img
                                    //className={importedStyles.sizeCardImg}
                                    className={importedStyles.packagingImg}
                                    src={require('../../../img/cajaPulsidFront.jpg')}
                                    alt={'packaging'}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

            </React.Fragment>
        );
    }

    renderCartResume() {
        const totalPrice = this.getTotalPrice();
        return (
            <div>
                <Grid container spacing={16} className={importedStyles.sizeCardContainerCart}>
                    {this.state.braceletsL !== '' && parseInt(this.state.braceletsL) !== 0 &&
                    <Grid item xs={12} md={4} className={importedStyles.cartItem}>
                        <Card className={importedStyles.sizeCardCart}>
                            <Grid container>
                                <Grid item xs={6} className={importedStyles.productImageBox}>
                                    <Image
                                        className={importedStyles.sizeCardImg}
                                        src={require('../../../img/pulsidBracelet.png')}
                                        webp={require('../../../img/pulsidBracelet.webp')}
                                        alt={'pulsid pulsera'}
                                    />
                                    <span>L · 202 mm</span>
                                    <p>x {this.state.braceletsL}</p>
                                </Grid>
                                <Grid item xs={6} className={importedStyles.productDetails}>
                                    {/* <Chip className={importedStyles.oldPriceChip}
                                          label={this.state.braceletsL * 19.99 + '€'}/>*/}
                                    <Chip className={importedStyles.priceChip}
                                          label={this.state.braceletsL * braceletPrice + '€'}/>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>}
                    {this.state.braceletsM !== '' && parseInt(this.state.braceletsM) !== 0 &&
                    <Grid item xs={12} md={4} className={importedStyles.cartItem}>
                        <Card className={importedStyles.sizeCardCart}>
                            <Grid container>
                                <Grid item xs={6} className={importedStyles.productImageBox}>
                                    <Image
                                        className={importedStyles.sizeCardImg}
                                        src={require('../../../img/pulsidBracelet.png')}
                                        webp={require('../../../img/pulsidBracelet.webp')}
                                        alt={'pulsid pulsera'}
                                    />
                                    <span>M · 180 mm</span>
                                    <p>x {this.state.braceletsM}</p>
                                </Grid>
                                <Grid item xs={6} className={importedStyles.productDetails}>
                                    {/*<Chip className={importedStyles.oldPriceChip}
                                          label={this.state.braceletsM * 19.99 + '€'}/>*/}
                                    <Chip className={importedStyles.priceChip}
                                          label={this.state.braceletsM * braceletPrice + '€'}/>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>}
                    {this.state.braceletsS !== '' && parseInt(this.state.braceletsS) !== 0 &&
                    <Grid item xs={12} md={4} className={importedStyles.cartItem}>
                        <Card className={importedStyles.sizeCardCart}>
                            <Grid container>
                                <Grid item xs={6} className={importedStyles.productImageBox}>
                                    <Image
                                        className={importedStyles.sizeCardImg}
                                        src={require('../../../img/pulsidBracelet.png')}
                                        webp={require('../../../img/pulsidBracelet.webp')}
                                        alt={'pulsid pulsera'}
                                    />
                                    <span>S · 160mm</span>
                                    <p>x {this.state.braceletsS}</p>
                                </Grid>
                                <Grid item xs={6} className={importedStyles.productDetails}>
                                    {/*<Chip className={importedStyles.oldPriceChip}
                                          label={this.state.braceletsS * 19.99 + '€'}/>*/}
                                    <Chip className={importedStyles.priceChip}
                                          label={this.state.braceletsS * braceletPrice + '€'}/>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>}
                    {this.state.includePackaging &&
                    <Grid item xs={12} md={4} className={importedStyles.cartItem}>
                        <Card className={importedStyles.sizeCardCart}>
                            <Grid container>
                                <Grid item xs={6} className={importedStyles.productImageBox}>
                                    <img
                                        className={importedStyles.packagingCartImg}
                                        src={require('../../../img/cajaPulsidFront.jpg')}
                                        alt={'packaging'}
                                    />
                                </Grid>
                                <Grid item xs={6} className={importedStyles.productDetails}>
                                    <span style={{textAlign: 'center'}}>Caja PulsID para regalo</span>
                                    <p>x {this.state.packagingQuantity}</p>
                                    <Chip className={importedStyles.priceChip}
                                          label={this.state.packagingQuantity * packagingPrice + '€'}/>
                                </Grid>
                                <Grid item xs={12} className={importedStyles.cartItem}>
                                    <span>Dedicatoria: </span>
                                    <span><i>'{this.state.giftStickerContent}'</i></span>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    }
                    <Grid item xs={12} className={importedStyles.parentShippingPriceBox}>
                        <Grid item md={4} className={importedStyles.shippingPriceBox}>
                            <Grid item xs={6} className={importedStyles.centerContainer}>
                                <img
                                    className={importedStyles.packagingCartImg}
                                    src={require('../../../img/shipping-pulsID.png')}
                                    alt={'shipping'}
                                />
                            </Grid>
                            <Grid item xs={6} className={importedStyles.centerContainer}>
                                <Typography variant={"h6"}>
                                    {this.state.includeShipping === 'premium' ? "3.75€" : <React.Fragment>Envío totalmente <b>GRATIS</b></React.Fragment>}
                                </Typography>
                                <Typography variant={"caption"}>
                                    {this.state.includeShipping === 'premium' ? "Recíbela en 48h" : "Recíbela en 4-5 días en tu buzón"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.discountObject.code &&
                    <Grid item xs={12} md={12} className={importedStyles.cartItem}>
                        <Card className={importedStyles.sizeCardCart} style={{borderLeft: 'solid 5px red'}}>
                            <Grid container>
                                <Grid item xs={6} className={importedStyles.productImageBox}>
                                    <img
                                        className={importedStyles.sizeCardImg}
                                        src={require('../../../img/discount.png')}
                                        alt={'discount'}
                                    />
                                </Grid>
                                <Grid item xs={6} className={importedStyles.productDetails}>
                                    <span style={{textAlign: 'center'}}>{this.state.discountObject.code}</span>
                                    <span style={{textAlign: 'center'}}>{this.state.discountObject.description}</span>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    }


                    {/*<Grid item xs={12} className={importedStyles.cartItem}>*/}
                    <Grid item xs={12} md={6} className={importedStyles.discountContainer}>
                        <TextField
                            className={importedStyles.textField}
                            variant={"outlined"}
                            margin="dense"
                            label="Código promocional"
                            name={"discount"}
                            type="text"
                            fullWidth
                            value={this.state.discount}
                            autoComplete="nope"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    this.checkDiscount();
                                }
                            }}
                            onChange={this.handleInputChange}
                            inputProps={{
                                maxLength: 50
                            }}
                        />

                        <Button id={'checkDiscountCode'} onClick={this.checkDiscount}
                                className={importedStyles.discountButton} disabled={this.state.sendingData}>
                            Comprobar {this.state.sendingData &&
                        <CircularProgress size={35} className={importedStyles.sendingDataAnimation}/>}
                        </Button>

                    </Grid>
                    <Grid item xs={12} md={6} className={importedStyles.cartItem}>
                        <div className={importedStyles.totalPriceWrapper}>
                            <Typography variant={"heading"}>
                                TOTAL:
                                <Chip className={importedStyles.totalPriceChip}
                                      label={totalPrice + '€'}/>
                            </Typography>
                            {this.state.discountObject &&
                            <p>{this.state.discountObject.code} | {this.state.discountObject.description}</p>}
                        </div>
                    </Grid>
                    {/*</Grid>*/}
                </Grid>
            </div>
        );
    }

    renderUserInfoForm = () => {
        return (
            <React.Fragment>
                <Grid item xs={12} sm={12}>
                    <TextField
                        className={importedStyles.textField}
                        variant={"outlined"}
                        margin="dense"
                        label="Nombre y apellidos"
                        name={"name"}
                        type="text"
                        fullWidth
                        value={this.state.name}
                        autoComplete="nope"
                        onChange={this.handleInputChange}
                        inputProps={{
                            maxLength: 150
                        }}
                        disabled={this.props.newBracelet}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={this.state.validMail ? importedStyles.textField : importedStyles.textFieldError}
                        variant={"outlined"}
                        margin="dense"
                        label="Email"
                        name={"email"}
                        type="email"
                        value={this.state.email}
                        fullWidth
                        autoComplete="nope"
                        onBlur={this.validateMail}
                        onChange={this.handleInputChange}
                        disabled={this.props.newBracelet}
                    />
                </Grid>
                {/*<Grid item xs={12} sm={6}>
                    <TextField
                        className={importedStyles.textField}
                        variant={"outlined"}
                        margin="dense"
                        label="Nº identificación fiscal "
                        name={"documentId"}
                        type="text"
                        value={this.state.documentId}
                        helperText={'Opcional. Rellena este campo solo si deseas factura'}
                        fullWidth
                        autoComplete="nope"
                        onChange={this.handleInputChange}
                        inputProps={{
                            maxLength: 9
                        }}
                    />
                </Grid>*/}
                <Grid item xs={12} sm={12}>
                    <DialogContentText className={''}>
                        Indica dónde quieres recibir las pulseras:
                    </DialogContentText>
                </Grid>
                {/*<Grid item xs={12} sm={12}>
                                <FormControl variant="outlined" className={importedStyles.formControl}
                                             style={{marginTop: 8, marginBottom: 4}}>
                                    <InputLabel>
                                        País
                                    </InputLabel>
                                    <Select
                                        value={this.state.country}
                                        onChange={this.handleInputChange}
                                        fullWidth
                                        input={<OutlinedInput fullWidth labelWidth={32} name="country"/>}
                                    >
                                        <MenuItem value=''>
                                            <em style={{color: "#8e8e8e"}}>-----</em>
                                        </MenuItem>
                                        <MenuItem value={'spain'}>España</MenuItem>
                                        <MenuItem value={'argentina'}>Argentina</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>*/}
                <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" className={importedStyles.formControl} fullWidth
                                 autoComplete="nope">
                        {this.renderAutocompleteProvinceInput()}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={importedStyles.formControl} fullWidth
                                 autoComplete="nope">
                        {this.renderAutocompleteCityInput()}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={importedStyles.textField}
                        variant={"outlined"}
                        margin="dense"
                        label="Código postal"
                        name={"zipcode"}
                        value={this.state.zipcode}
                        type="text"
                        inputProps={{
                            maxLength: this.state.country === 'spain' ? 5 : 8,
                        }}
                        autoComplete="nope"
                        fullWidth
                        onChange={this.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        className={importedStyles.formControl}
                        variant={"outlined"}
                        margin="dense"
                        name={"address"}
                        label="Dirección"
                        value={this.state.address}
                        type="text"
                        fullWidth
                        autoComplete="nope"
                        inputProps={{
                            maxLength: 50,
                        }}
                        onChange={this.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.privacityTermsCheck}
                                onChange={() => this.setState({privacityTermsCheck: !this.state.privacityTermsCheck})}
                                className={importedStyles.checkbox}
                            />
                        }
                        label={<span className={importedStyles.privacyLink} onClick={(e) => {
                            e.preventDefault();
                            let win = window.open(window.location.origin + '/law', '_blank');
                            win.focus();
                        }}>Acepto los términos y condiciones</span>}
                    />
                </Grid>
            </React.Fragment>
        )
    };

    backToProductSelector = () => {
        this.props.handleModal(); //Open product selector modal
        this.handlePaymentModal(); //Close payment data and resume modal
    };

    handleLoadingPaymentRequestModal = () => {
        this.setState({
            openLoadingPaymentRequestModal: !this.state.openLoadingPaymentRequestModal
        });
    };

    renderLoadingAnimation = () => {
        let defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loadingPaymentRequest,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        if (!this.state.waitingForPaymentRequestPackage) {
            defaultOptions = defaultOptions = {
                loop: false,
                autoplay: true,
                animationData: check,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
            };
        }

        return (
            <Lottie options={defaultOptions}
                    height={100}
                    width={100}
                //speed={0.7}
            />
        )
    };
    renderLoadingDotsAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loadingDots,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={100}
                    width={100}
                    style={{transform: 'rotate(90deg) !important'}}
                //speed={0.7}
            />
        )
    };
    renderRedirectAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: redirectToSecure,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={100}
                    width={100}
                //speed={0.7}
            />
        )
    };

    handleStepChange = step => {
        this.setState({currentStep: step});
        document.getElementById('mainDialogContent').scrollTo({ top: 0, behavior: 'smooth' });

    };

    renderDialogTitle = () => {
        if (!this.props.promoCode) {
            if (window.matchMedia("(max-width: 768px)").matches) {
                return (
                    <React.Fragment>
                        <Typography variant={"subtitle1"}>
                            {this.state.currentStep === 2 && <IconButton onClick={() => this.handleStepChange(1)} style={{marginRight: '0.5rem'}}><ArrowBackIcon /></IconButton>}
                            {"Consigue tus pulseras por solo 16.95€*"}
                        </Typography>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        <Typography variant={"h5"}>
                            {this.state.currentStep === 2 && <IconButton onClick={() => this.handleStepChange(1)} style={{marginRight: '0.5rem'}}><ArrowBackIcon /></IconButton>}
                            {"Consigue tus pulseras por solo 16.95€*"}
                        </Typography>
                    </React.Fragment>
                )
            }
        }

        return (
            <React.Fragment>
                {this.state.currentStep === 2 && <IconButton onClick={() => this.handleStepChange(1)} style={{marginRight: '0.5rem'}}><ArrowBackIcon /></IconButton>}
                <Typography variant={"h5"}>{"Escoge una talla"}</Typography>
            </React.Fragment>
        )
    };

    showIncompleteFormAlert = () => {
        if (!this.state.sendingData) {
            this.props.dispatch(showSnack('Tienes algún campo sin rellenar o no has aceptado los términos y condiciones', 'warning'));
        }
    };

    renderPaymentConfirmationButton = () => {

        let incompleteForm = !this.state.email.length > 0 || !this.state.address.length > 0
            || !this.state.province.length > 0 || !this.state.totalBracelets > 0
            || !this.state.city.length > 0 || !this.state.zipcode.length > 0 || !this.state.validMail || !this.state.privacityTermsCheck || this.state.sendingData;

        if (incompleteForm) {
            return (
                <React.Fragment>
                    <Button
                        color="primary"
                        variant={"contained"}
                        className={importedStyles.disabledButton}
                        onClick={this.showIncompleteFormAlert}
                    >
                        Confirmar pedido <DoneIcon style={{marginLeft: '0.5em'}}/>
                        {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                    </Button>
                    <Typography variant={"caption"}>
                        Debes aceptar las condiciones antes de confirmar tu pedido
                    </Typography>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Button
                        color="primary"
                        variant={"contained"}
                        className={importedStyles.dialogActionButton}
                        onClick={this.handlePaymentRequest}
                    >
                        Confirmar pedido <DoneIcon style={{marginLeft: '0.5em'}}/>
                        {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                    </Button>
                    <Typography variant={"caption"}>
                        Debes aceptar las condiciones antes de confirmar tu pedido
                    </Typography>
                </React.Fragment>
            )
        }
    };

    renderFirstBuySteps = () => {
        switch (this.state.currentStep) {
            case 1:
                return (
                    <React.Fragment>
                        <DialogContentText className={importedStyles.contentInnerText}>
                            Elige la talla de tus pulseras 📐:
                        </DialogContentText>
                        {this.renderSizeSelector()}
                    </React.Fragment>
                );

            case 2:
                return (
                    <React.Fragment>
                        <DialogContentText className={importedStyles.contentInnerText}>
                            Elige el modo de envío 🚚:
                        </DialogContentText>
                        {this.renderShippingSelector()}
                        <DialogContentText className={importedStyles.contentInnerText}>
                            Regala pulsID 🎁:
                        </DialogContentText>
                        {this.renderPackagingSelector()}
                    </React.Fragment>
                );
            default:
                // do nothing
                break;
        }
    };

    renderFirstStepsActions = () => {
        switch (this.state.currentStep) {
            case 1:
                return (
                    <React.Fragment>
                        <Button
                            variant={"contained"}
                            onClick={() => this.handleStepChange(2)}
                            disabled={this.state.sendingData || this.state.totalBracelets <= 0}
                            className={importedStyles.dialogActionButton}
                        >
                            Siguiente paso
                            <ArrowForward style={{position: 'absolute', right: '0.5em'}}/>
                            {this.state.sendingData &&
                            <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                    </React.Fragment>
                );

            case 2:
                return (
                    <React.Fragment>
                        <Button
                            variant={"contained"}
                            onClick={this.handleCheckoutResume}
                            disabled={this.state.sendingData || this.state.totalBracelets <= 0}
                            className={importedStyles.dialogActionButton}
                        >
                            <ShoppingCart style={{marginRight: '0.5em'}}/>
                            Ir al carrito
                            <ArrowForward style={{position: 'absolute', right: '0.5em'}}/>
                            {this.state.sendingData &&
                            <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                    </React.Fragment>
                );
            default:
                // do nothing
                break;
        }
    };

    render() {
        return (
            <React.Fragment>
                <Dialog
                    id={'firstStepsModal'}
                    fullScreen={true}
                    open={this.props.open}
                    onClose={this.props.handleModal}
                    scroll={"paper"}
                    disableBackdropClick
                    className={importedStyles.fixModalPadding}
                >
                    <DialogTitle style={{display: 'flex', alignItems: 'center'}}>
                        {this.renderDialogTitle()}
                        <IconButton onClick={this.props.handleModal} className={importedStyles.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={importedStyles.mainDialogContent} id={'mainDialogContent'}>
                        <DialogContentText className={importedStyles.contentText}>
                            {/*<Card className={importedStyles.offerCard}>
                                <Typography variant={"subtitle1"}>
                                    ¡Oferta de lanzamiento, 20% de descuento!
                                </Typography>
                                <Typography variant={"subheading"}>
                                    <span style={{textDecoration: 'line-through'}}>19.99€</span> 👉 <b>13.99€</b>
                                </Typography>
                            </Card>*/}
                        </DialogContentText>
                        <Grid container spacing={16} className={importedStyles.parentContainer}>
                            {this.renderFirstBuySteps()}
                            <div>
                                <p>*IVA incluido.</p>
                            </div>
                        </Grid>

                    </DialogContent>
                    <DialogActions className={importedStyles.dialogActionsFirstStep}>
                        {this.renderFirstStepsActions()}
                    </DialogActions>
                </Dialog>

                {/*/////////////////////////////////////////////////*/}

                {/*PAYMENT MODAL */}
                <Dialog
                    fullScreen={true}
                    open={this.state.openPaymentModal}
                    onClose={this.handlePaymentModal}
                    disableBackdropClick
                    className={importedStyles.paymentModal}
                >
                    <DialogTitle>
                        <div className={importedStyles.paymentModalTitleBox}>
                            <IconButton onClick={this.backToProductSelector}>
                                <ArrowBackIcon/>
                            </IconButton>
                            <span style={{marginLeft: 5}}>Último paso</span>
                            <IconButton onClick={this.handlePaymentModal} className={importedStyles.closeModalIcon}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent className={importedStyles.mainDialogContent}>
                        <DialogContentText>
                            <div>
                                {/*<p>Ya casi estamos, ahora te vamos a redirigir a nuestra plataforma de pago.</p>*/}
                                <p>Resumen de tu pedido:</p>
                            </div>
                        </DialogContentText>
                        {this.renderCartResume()}
                        <DialogContentText style={{marginBottom: '0.5em'}}>
                            Rellena tus datos:
                        </DialogContentText>
                        <Grid container spacing={16}>
                            {this.renderUserInfoForm()}
                        </Grid>
                    </DialogContent>
                    <DialogActions className={importedStyles.dialogActions}>
                        <form
                            className={importedStyles.formRedsys}
                            method="post"
                            id={'redsysForm'}
                            //action={'https://sis-t.redsys.es:25443/sis/realizarPago'} // test
                            action={'https://sis.redsys.es/sis/realizarPago'} // production
                            onSubmit={() => {
                                this.setState({sendingData: true})
                            }}
                        >
                            <input type="text" name="Ds_SignatureVersion" value={this.state.Ds_SignatureVersion}
                                   hidden/>
                            <input type="text" name="Ds_MerchantParameters" value={this.state.Ds_MerchantParameters}
                                   hidden/>
                            <input type="text" name="Ds_Signature" value={this.state.Ds_Signature} hidden/>
                            {this.renderPaymentConfirmationButton()}

                        </form>
                    </DialogActions>
                </Dialog>

                {/* LOADING PAYMENT REQUEST MODAL */}
                <Dialog
                    fullScreen={false}
                    open={this.state.openLoadingPaymentRequestModal}
                    onClose={this.handleLoadingPaymentRequestModal}
                    disableBackdropClick
                    className={importedStyles.loadingPaymentActionsModal}
                >
                    <DialogContent className={importedStyles.mainDialogContent}>
                        <DialogContentText id={'loadingPaymentRequestAnimation'}
                                           style={{textAlign: 'center', marginBottom: '1em'}}>
                            {this.renderLoadingAnimation()}
                            Enviando petición de pago
                        </DialogContentText>
                        <DialogContentText id={'redsysAnimationContainer'}
                                           className={importedStyles.redsysAnimationContainer}>
                            <div id={'dotsAnimation'}>
                                {this.renderLoadingDotsAnimation()}
                            </div>
                            {this.renderRedirectAnimation()}
                            Redireccionando a plataforma de pago seguro
                        </DialogContentText>

                    </DialogContent>
                </Dialog>

                {/* ////////////////////////// */}

                <Snack/>
                <Snackbar
                    className={importedStyles.snackTotalBracelets}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.openSnackTotalBracelets}
                >
                    <SnackbarContent
                        className={importedStyles.snackTotalBraceletsMessage}
                        variant="warning"
                        message={
                            <span>
                                Eh, máximo 5 pulsID, si quieres más contacta con nosotros en <a
                                href={'mailto:mipulsid@gmail.com'} target={'_blank'}>mipulsid@gmail.com</a>
                            </span>
                        }
                        classes={{
                            message: importedStyles.snackTotalBraceletsMessage
                        }}
                        action={
                            <button onClick={() => this.setState({openSnackTotalBracelets: false})}>
                                Ok
                            </button>
                        }
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

OldBuyModals.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    newBracelet: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
    func_success: PropTypes.func.isRequired,
    promoCode: PropTypes.string.isRequired,
};
OldBuyModals.defaultProps = {
    newBracelet: false,
};

const mapStateToProps = ({snackReducer, authReducer}) => {
    return ({
        snackData: snackReducer.data,
        user: authReducer.user,
    });
};
export default connect(mapStateToProps)(withMobileDialog()(OldBuyModals));