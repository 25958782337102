import hamster from "../../../../animations/hamster";
import bye from "../../../../animations/bye";
import Lottie from "react-lottie";
import React from "react";
import importedStyles from "./PresaleModal.module.sass";
import {DialogContentText} from "@material-ui/core";
import Button from "@material-ui/core/Button/index";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import IconButton from "@material-ui/core/IconButton/index";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent/index";
import Dialog from "@material-ui/core/Dialog/index";
import TextField from "@material-ui/core/TextField/index";
import baseRoute from "../../../../helpers/baseRoute";
import {showSnack, snackTypes} from "../../../../components/Snack/SnackActions";
import Cookies from 'universal-cookie';
import CircularProgress from "@material-ui/core/CircularProgress/index";
import DialogActions from "@material-ui/core/DialogActions";

class PresaleModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendingData: false,
            email: '',
            subscriptionSucceed: false,
        };
    }


    renderAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: hamster,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                //speed={0.7}
            />
        )
    };
    renderSucceedAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: bye,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={250}
                    width={250}
                    speed={0.5}
            />
        )
    };

    handleInput = (e) => {
        let email = e.target.value;
        let regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        let isValidMail = regex.test(email);

        this.setState({email: email, isValidMail: isValidMail});
    };

    setPresaleCokkie = () => {
        const cookies = new Cookies();
        let expireDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        cookies.set('subscribedToPresale', true, {path: '/', expires: expireDate});
    };


    handleSubscription = () => {
        this.setState({sendingData: true});
        fetch(baseRoute + 'api/presales/newPresaleSubscription', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: this.state.email,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setPresaleCokkie();
                this.setState({subscriptionSucceed: true});
                //this.props.onClose();
            } else {
                throw new Error(response.errorMessage);
            }
            this.setState({sendingData: false});
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    renderDialogContent = () => {
        let dialogContent = '';

        if (this.state.subscriptionSucceed) {
            dialogContent = (
                <React.Fragment>
                    {this.renderSucceedAnimation()}
                    <DialogContentText className={importedStyles.dialogContentText}>
                        ¡Genial, te avisaremos en cuanto pongamos a la venta PulsID!
                    </DialogContentText>
                    <Button
                        variant={"contained"}
                        color={"secondary"} size={"large"} fullWidth
                        onClick={this.props.onClose}
                    >
                        Cerrar
                    </Button>
                </React.Fragment>
            );
        } else {
            dialogContent = (
                <React.Fragment>
                    {/*<Grid container spacing={16} className={importedStyles.parentContainer}>*/}
                    {/*    <Grid item xs={12}>*/}
                    {this.renderAnimation()}
                    <DialogContentText className={importedStyles.dialogContentText}>
                        Disculpa, aún estamos haciendo los últimos retoques. Déjanos tu correo y te avisaremos cuando pulsID
                        esté listo
                    </DialogContentText>
                    <TextField
                        variant={"outlined"}
                        margin="normal"
                        label="Correo"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleInput}
                        fullWidth
                    />
                    {/*</Grid>*/}
                    {/*<Grid item xs={8} className={importedStyles.mainActionButtonContainer}>*/}
                    <DialogActions className={importedStyles.dialogActions}>
                        <Button
                            variant={"contained"}
                            color={"primary"} size={"large"}
                            className={importedStyles.mainActionButton}
                            onClick={this.handleSubscription}
                            disabled={!this.state.isValidMail || this.state.email.length <= 0 || this.state.sendingData}
                        >
                            Avisadme por favor {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                    </DialogActions>
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {dialogContent}
            </React.Fragment>
        );
    };

    render() {
        const {fullScreen} = this.props;
        return (
            <React.Fragment>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    disableBackdropClick
                    fullWidth={true}
                    maxWidth={"sm"}
                >
                    <DialogTitle>
                        Comprar mi PulsID
                        <IconButton onClick={this.props.onClose} className={importedStyles.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {this.renderDialogContent()}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    };
}


PresaleModal.propTypes = {
    open: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
const mapStateToProps = ({snackReducer, patientReducer, authReducer}) => {

    return ({
        onAssignFlow: authReducer.onAssignFlow,

    });
};

export default connect(mapStateToProps)(withMobileDialog()(PresaleModal));