import React from 'react';
import importedStyles from "./ForgottenPasswordModal.module.sass";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import Grid from "@material-ui/core/es/Grid/Grid";
import TextField from "@material-ui/core/es/TextField/TextField";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import Button from "@material-ui/core/es/Button/Button";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import baseRoute from "../../helpers/baseRoute";
import history from "../../helpers/history";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import connect from "react-redux/es/connect/connect";
import Snack from "../../components/Snack/Snack";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReactPixel from "react-facebook-pixel";

class ForgottenPasswordModal extends React.Component {

    state = {
        userEmail: '',
        sendingData: false,
    };

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    sendForgottenPasswordEmail = () => {
        this.setState({
            sendingData: true
        });
        fetch(baseRoute + 'api/users/changeForgottenPassword', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userEmail: this.state.userEmail
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if(!response.error){
                ReactPixel.trackCustom('passwordForgottenAndChanged', {email: this.state.email});
                this.props.dispatch(showSnack('Te hemos enviado un email con tu nueva contraseña', snackTypes.success));
                history.push('/');
            } else {
                if (response.userError) {
                    this.props.dispatch(showSnack('El email introducido no coincide con ninguna cuenta', snackTypes.warning));
                } else {
                    throw new Error(response.errorMessage);
                }
            }
            this.setState({sendingData: false});
        }).catch((error) => {
            console.error('Error: ', error);
            this.props.dispatch(showSnack('Oops, parece que ha ocurrido algún error, intentalo de nuevo en unos minutos', snackTypes.error));
            this.setState({sendingData: false});
        });

    };

    render() {
        return (
            <div>
            <Dialog
                fullScreen={true}
                open={true}
                disableBackdropClick
            >
                <DialogTitle className={importedStyles.dialogTitle}>
                    <IconButton onClick={()=>history.push('/')} className={importedStyles.closeModalIcon}>
                        <ArrowBackIcon/>
                    </IconButton>
                    {"Recuperar contraseña"}
                </DialogTitle>
                <DialogContent className={importedStyles.flexColumn}>
                    <DialogContentText>
                        Introduce el email con el que te registraste y te enviaremos tu nueva contraseña:
                    </DialogContentText>
                    <Grid container spacing={16} style={{marginTop: 15}}>
                        <Grid item xs={12} sm={12}>

                            <TextField
                                className={importedStyles.textField}
                                autoFocus
                                variant={"outlined"}
                                margin="normal"
                                label="Email"
                                name={"userEmail"}
                                type="email"
                                value={this.state.userEmail}
                                fullWidth
                                onChange={this.handleInputChange}
                                onKeyPress={(e)=> {
                                    if (e.which === 13 && this.state.userEmail.length > 0) {
                                        this.sendForgottenPasswordEmail();
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={importedStyles.flexColumn}>
                    <Button
                        className={importedStyles.buttonSubmit}
                        variant={"contained"}
                        fullWidth
                        onClick={this.sendForgottenPasswordEmail}
                        disabled={this.state.sendingData || !this.state.userEmail.length > 0 }
                    >
                        Solicitar nueva contraseña {this.state.sendingData &&
                    <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snack/>
            </div>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
    });
};
export default connect(mapStateToProps)(ForgottenPasswordModal);