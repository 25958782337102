import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import importedStyles from "./ContactsDataForm.module.sass";
import {connect} from "react-redux";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import TextField from "@material-ui/core/TextField/TextField";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {Add, Close} from "@material-ui/icons";
import {codeCountries, update_contact_data} from "../../PatientActions";
import MobileStepper from "@material-ui/core/MobileStepper/MobileStepper";
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Grid from "@material-ui/core/Grid/Grid";
import FormControl from "@material-ui/core/FormControl/FormControl";
import List from "@material-ui/core/List/List";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography/Typography";
import baseRoute from "../../../../helpers/baseRoute";
import Tour from "reactour";


class ContactsDataForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendingData: false,
            open: true,
            contactPhone: '',
            //contactPhone: codeCountries[this.props.country],
            contactName: '',
            relationship: '',
            addingContact: false,
            isTourOpen: true,
            uxTutorialSteps: [
                {
                    position: 'right',
                    selector: '.userTutorialTarget',
                    content: (
                        <div className={importedStyles.tutoWrapper}>
                            <Typography variant={"subtitle2"} gutterBottom>¿A quien se avisará cuando<br/> ocurra una emergencia?</Typography>
                            <div className={importedStyles.tutoImgWrapper}>
                                <img height={350} alt={'preview pasos'}
                                       src={require('../../../../img/previewEmergencyContactsData.png')}
                                />
                            </div>
                            <Button color={"primary"} variant={"contained"}
                                    onClick={() => this.setState({isTourOpen: false})}>Entendido</Button>
                        </div>
                    ),
                }
            ]
        };
        this.addContactToList = this.addContactToList.bind(this);
        this.removeContactToList = this.removeContactToList.bind(this);
        this.renderContactList = this.renderContactList.bind(this);
    }

    componentDidMount(): void {

        if (this.props.contacts.length <= 0){
            if (this.props.logged){
                let userPhone = this.props.user.phone ? this.props.user.phone : '';
                this.setState({contactPhone: userPhone, contactName: this.props.user.name});
            } else if (this.props.onAssignFlow) {
                if (this.props.creatingNewUser) {
                    this.setState({contactPhone: this.props.responsiblePhone, contactName: this.props.responsibleName});
                } else {
                    this.getResponsibleData(this.props.responsibleUserUuid);
                }
            }
        }
    }

    getResponsibleData = (userUuid) =>{
        fetch(baseRoute + 'api/users/getUserData', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userUuid: userUuid,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({contactPhone: response.user.phone, contactName: response.user.name});
            } else {
                throw new Error(response.errorMessage);
            }

        }).catch((error) => {
            console.log(error);
        });
    };

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };
    handlePhoneChange = (event) => {
        //Check if the input value is not a number, it so, do nothing. Else update state
        let isNotNumber = isNaN(event.target.value);
        if (!isNotNumber) {
            this.setState({[event.target.name]: event.target.value});
        }
    };

    addContactToList() {
        let newContactsList = this.props.contacts ? this.props.contacts : [];
        newContactsList.push({
            name: this.state.contactName,
            phone: this.state.contactPhone,
            relationship: this.state.relationship,
            prefix: codeCountries[this.props.country]
        });
        this.props.dispatch(update_contact_data(newContactsList));
        this.setState({
            //contactPhone: codeCountries[this.props.country],
            contactPhone: '',
            contactName: '',
            relationship: '',
            addingContact: false,
        })
    }


    removeContactToList(index) {
        let newContactsList = this.props.contacts ? [...this.props.contacts] : [];
        newContactsList.splice(index, 1);
        this.props.dispatch(update_contact_data(newContactsList));
    }

    renderContactList() {
        const contacts = this.props.contacts ? this.props.contacts : [];
        let contactsView = [];
        Object.keys(contacts).forEach((index) => {
            let primaryText = '';
            if (contacts[index].relationship) {
                primaryText =
                    <span>{contacts[index].name}
                        <i className={importedStyles.relationshipText}>
                            {contacts[index].relationship}
                        </i>
                    </span>
            } else {
                primaryText = contacts[index].name;
            }
            contactsView.push(
                <React.Fragment key={index}>
                    <ListItem>
                        <ListItemText
                            primary={primaryText}
                            secondary={'+' + contacts[index].prefix + ' ' + contacts[index].phone}
                        />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => this.removeContactToList(index)}>
                                <Close/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </React.Fragment>
            );
        });
        return (
            <List component={'div'} disablePadding>
                {contactsView}
            </List>
        );
    }

    getDisabledNext() {
        return this.props.contacts.length === 0;
    }

    displayUserTutorial(){
        if (this.props.creatingNewUser){
            return (
                <Tour
                    highlightedMaskClassName={importedStyles.UxTutoMask}
                    steps={this.state.uxTutorialSteps}
                    isOpen={this.state.isTourOpen}
                    rounded={10}
                    maskSpace={18}
                    showCloseButton={false}
                    showNavigation={false}
                    disableInteraction={true}
                    showButtons={false}
                    showNumber={false}
                    disableDotsNavigation={true}
                    onRequestClose={() => this.setState({isTourOpen: false})}
                    className={importedStyles.tutoParentWrapper}
                />
            )
        }
    }

    render() {
        return (
            <div className={importedStyles.container}>
                <DialogContentText className={importedStyles.contentText}>
                    <Typography gutterBottom variant={"body"}>Cuantos más datos añadas más te podrá ayudar tu pulsera en caso de emergencia. </Typography>
                    <Typography gutterBottom variant={"subheading"}>  Añade al menos un <b>contacto de emergencia</b> para esta pulsera.</Typography>
                </DialogContentText>
                <Grid container spacing={8} className={[importedStyles.contactsContainer, 'userTutorialTarget']}>
                    {this.props.onAssignFlow && this.displayUserTutorial()}

                    {!this.state.addingContact &&
                    <Grid item xs={12} sm={12}>
                        <Button
                            className={importedStyles.addButton}
                            variant={'contained'}
                            fullWidth
                            color={"primary"}
                            //onClick={this.addContactToList}
                            onClick={() => this.setState({addingContact: true})}
                            disabled={this.state.addingContact}
                        >
                            <Add className={importedStyles.addButtonIcon}/>Añadir contacto
                        </Button>
                    </Grid>
                    }
                    {this.state.addingContact &&
                    <Grid container item xs={12} spacing={16} id={'newContactFormContainer'} className={importedStyles.newContactFormContainer}>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={importedStyles.formControl}>
                                <TextField
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Nombre"
                                    name={"contactName"}
                                    fullWidth
                                    type="text"
                                    value={this.state.contactName}
                                    onChange={this.handleInputChange}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={importedStyles.formControl}>
                                <TextField
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Teléfono"
                                    name={"contactPhone"}
                                    helperText={'Recuerda NO incluir prefijos de pais (+34, +54...)'}
                                    type="tel"
                                    value={this.state.contactPhone}
                                    inputProps={{
                                        startAdornment: <InputAdornment position="start"
                                                                        className={importedStyles.inputAdornment}>+{codeCountries[this.props.country]}</InputAdornment>,
                                        maxLength: this.props.country === 'spain' ? 9 : 15,
                                    }}
                                    onChange={this.handlePhoneChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl variant="outlined" className={importedStyles.formControl}>
                                <InputLabel>
                                    Parentesco
                                </InputLabel>
                                <Select
                                    value={this.state.relationship}
                                    onChange={this.handleInputChange}
                                    fullWidth
                                    input={<OutlinedInput fullWidth labelWidth={80} name="relationship"/>}
                                >
                                    <MenuItem value=''>
                                        <em style={{color: "#8e8e8e"}}><i>--Indefinido--</i></em>
                                    </MenuItem>
                                    <MenuItem value={'Pareja'}>Pareja</MenuItem>
                                    <MenuItem value={'Madre'}>Madre</MenuItem>
                                    <MenuItem value={'Padre'}>Padre</MenuItem>
                                    <MenuItem value={'Hijo/a'}>Hijo/a</MenuItem>
                                    <MenuItem value={'Hermano/a'}>Hermano/a</MenuItem>
                                    <MenuItem value={'Abuelo/a'}>Abuelo/a</MenuItem>
                                    <MenuItem value={'Primo/a'}>Primo/a</MenuItem>
                                    <MenuItem value={'Tio/a'}>Tio/a</MenuItem>
                                    <MenuItem value={'Otros'}>Otros</MenuItem>
                                </Select>
                                <FormHelperText><i>Campo opcional</i></FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={importedStyles.newContactActionButtons}>
                            <Button
                                variant={"text"}
                                style={{color: 'black'}}
                                onClick={()=>this.setState({contactPhone: '', contactName: '', relationship: '', addingContact: false,})}
                            >
                                Cancelar
                            </Button>
                            <Button
                                className={importedStyles.addButton}
                                color={"primary"}
                                variant={"contained"}
                                onClick={this.addContactToList}
                                disabled={!this.state.contactPhone || this.state.contactName.length === 0}
                            >
                                <Add className={importedStyles.addButtonIcon}/>Añadir
                            </Button>
                        </Grid>
                    </Grid>
                    }
                    <Grid item xs={12} sm={12}>
                        {this.props.contacts.length > 0 ?
                            <Typography variant="h6" gutterBottom style={{color: "#979797"}}>
                                Contactos
                            </Typography>
                            : null
                        }
                        <div className={importedStyles.contactsListContainer}>
                            {this.renderContactList()}
                        </div>
                    </Grid>
                </Grid>
                {!this.state.addingContact &&
                    <MobileStepper
                        className={importedStyles.stepper}
                        variant="dots"
                        steps={4}
                        position="static"
                        activeStep={this.props.activeStep}
                        nextButton={
                            <Button size="small"
                                    onClick={this.props.handleNext}
                                    disabled={this.getDisabledNext()}>
                                Siguiente <KeyboardArrowRight/>
                            </Button>
                        }
                        backButton={
                            <Button
                                size="small"
                                onClick={this.props.handleBack}
                            >
                                <KeyboardArrowLeft/>
                                Atrás
                            </Button>
                        }
                    />
                }
            </div>
        );
    }
}

ContactsDataForm.propTypes = {
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
};
const mapStateToProps = ({snackReducer, patientReducer, authReducer, assignReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        onAssignFlow: authReducer.onAssignFlow,
        creatingNewUser: authReducer.creatingNewUser,
        responsiblePhone: assignReducer.responsiblePhone,
        responsibleName: assignReducer.responsibleName,
        responsibleUserUuid: assignReducer.responsibleUserUuid,
        snackData: snackReducer.data,
        contacts: patientReducer.emergencyContacts,
        activeStep: patientReducer.activeStep,
        country: patientReducer.address.country,
        editing: patientReducer.editing,
    });
};
export default connect(mapStateToProps)(withMobileDialog()(ContactsDataForm));
