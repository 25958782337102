import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField/TextField";
import Typography from "@material-ui/core/Typography/Typography";
import importedStyles from "./PasswordChangeModal.module.sass";
import baseRoute from "../../helpers/baseRoute";
import WarningIcon from '@material-ui/icons/Warning';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import {connect} from "react-redux";
import Snack from "../../components/Snack/Snack";
import {login_success} from "../Modals/ModalsActions";
import Grid from "@material-ui/core/Grid/Grid";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

class PasswordChangeModal extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            sendingData: false,
            open: false,
            newPassword: '',
            newPasswordConfirmation: '',
            showPassword: false,
            showConfirmPassword: false,
        };
    }

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    sendConfirmationEmailWithSuggestions() {
        const userUuid = this.props.user.uuid;
        fetch(baseRoute + 'api/users/sendSuggestionsAndShareEmail', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userUuid: userUuid,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (response.error) {
                throw new Error('An error ocurred when trying to send register confirmation and suggestions email');
            }
        }).catch((error) => {
            console.error(error);
        });
    }


    changePassword() {

        const userUuid = this.props.user.uuid;
        this.setState({sendingData: true});

        fetch(baseRoute + 'api/users/changePassword', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userUuid: userUuid,
                    newPassword: this.state.newPassword
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.props.dispatch(showSnack('Contraseña establecida con éxito', snackTypes.success));
                this.props.handleModal();
                this.props.dispatch(login_success(response.user));
                this.sendConfirmationEmailWithSuggestions();
            } else {
                this.props.dispatch(showSnack('No hemos podido cambiar tu contraseña, por favor, prueba de nuevo en unos minutos', snackTypes.error));
            }
            this.setState({sendingData: false});
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('No hemos podido cambiar tu contraseña, por favor, prueba de nuevo en unos minutos', snackTypes.error));
        });
    }

    handleClickShowPassword = () => {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    handleClickShowConfirmPassword = () => {
        this.setState(state => ({showConfirmPassword: !state.showConfirmPassword}));
    };

    render() {
        const {fullScreen} = this.props;

        return (
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.open}
                    onClose={this.props.handleModal}
                    disableBackdropClick
                >
                    <DialogTitle>
                        {"Cambio de contraseña"}
                    </DialogTitle>
                    <DialogContent className={importedStyles.flexColumn}>
                        <DialogContentText>
                            Bienvenid@ a PulsID, antes de empezar necesitamos que establezcas una nueva contraseña
                            personalizada
                        </DialogContentText>
                        <Grid container spacing={16} style={{marginTop: 15}}>
                            <TextField
                                variant={"outlined"}
                                margin="dense"
                                label="Email"
                                name={"email"}
                                type="email"
                                value={this.props.user.mail}
                                style={{display: 'none'}}
                            />
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    className={importedStyles.textField}
                                    autoFocus
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Nueva contraseña"
                                    name={"newPassword"}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.newPassword}
                                    helperText={"Longitud mínima 8 caracteres"}
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Mostrar contraseña" placement="top">
                                                    <IconButton
                                                        tabIndex="-1"
                                                        aria-label="Toggle password visibility"
                                                        onClick={this.handleClickShowPassword}
                                                    >
                                                        {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <TextField
                                    className={importedStyles.textField}
                                    variant={"outlined"}
                                    margin="normal"
                                    label="Confirma nueva contraseña"
                                    name={"newPasswordConfirmation"}
                                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                                    value={this.state.newPasswordConfirmation}
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Mostrar contraseña" placement="top">
                                                    <IconButton
                                                        tabIndex="-1"
                                                        aria-label="Toggle password visibility"
                                                        onClick={this.handleClickShowConfirmPassword}
                                                    >
                                                        {this.state.showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {this.state.newPasswordConfirmation.length > 0 && this.state.newPassword !== this.state.newPasswordConfirmation ?
                                    <div className={importedStyles.noEqualPasswordsMessageBox}>
                                        <WarningIcon/>
                                        <Typography variant={"subtitle1"}
                                                    className={importedStyles.noEqualPasswordsMessage}>
                                            Las contraseñas no coinciden
                                        </Typography>
                                    </div>
                                    :
                                    null
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={importedStyles.actionsContainer}>
                        <Button
                            color="primary"
                            variant={"contained"}
                            fullWidth
                            onClick={() => {
                                this.changePassword();
                            }}
                            disabled={this.state.sendingData || this.state.newPassword.length < 8 || this.state.newPassword !== this.state.newPasswordConfirmation}
                        >
                            Cambiar contraseña {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>

                    </DialogActions>
                </Dialog>
                <Snack/>
            </div>
        );
    }
}

PasswordChangeModal.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};
const mapStateToProps = ({snackReducer, authReducer}) => {
    return ({
        snackData: snackReducer.data,
        user: authReducer.user,
    });
};
export default connect(mapStateToProps)(withMobileDialog()(PasswordChangeModal));