import {
    PATIENT_UPDATE_CONTACTS_DATA,
    PATIENT_UPDATE_BASIC_DATA,
    PATIENT_UPDATE_DOCUMENTS_DATA,
    PATIENT_UPDATE_MEDIC_DATA,
    PATIENT_CLEAN_DATA,
    PATIENT_UPDATE_BRACELET_UUID,
    PATIENT_UPDATE_EDITTING,
    PATIENT_NEXT_STEP,
    PATIENT_PREVIOUS_STEP,
    PATIENT_HANDLE_MODAL,
    PATIENT_HANDLE_EMERGENCY_STEPS_MODAL,
    PATIENT_SENDING_DATA,
    PATIENT_CLEAN_EMERGENCY_STEPS,
    PATIENT_UPDATE_EMERGENCY_STEPS,
    CLOSE_ASSIGN_MODALS
} from "../../constants/action-types";

export const codeCountries = {
    spain: 34,
    mexico: 52,
    argentina: 54,
    colombia: 57,
    peru: 51,
    eeuu: 1,
};

export const update_basic_data = (name, surname, bornDate, patientPhoto, country, city, region, address, zipCode) => {
    return ({
        type: PATIENT_UPDATE_BASIC_DATA,
        name: name,
        surname: surname,
        patientPhoto: patientPhoto,
        bornDate: bornDate,
        address: {
            country: country,
            city: city,
            region: region,
            address: address,
            zipCode: zipCode,
        },
    });
};

export const update_medic_data = (diagnosis, medicines, allergies, observations) => {
    return ({
        type: PATIENT_UPDATE_MEDIC_DATA,
        allergies: allergies,
        medicines: medicines,
        diagnosis: diagnosis,
        observations: observations,
    });
};

export const update_emergency_steps = (emergencySteps) => {
    return ({
        type: PATIENT_UPDATE_EMERGENCY_STEPS,
        emergencySteps: emergencySteps,
    });
};

export const update_documents_data = (documents) => {
    return ({
        type: PATIENT_UPDATE_DOCUMENTS_DATA,
        documents: documents,
    });
};

export const update_contact_data = (contacts) => {
    return ({
        type: PATIENT_UPDATE_CONTACTS_DATA,
        contacts: contacts
    });
};

export const clean_patient_data = () => {
    return ({
        type: PATIENT_CLEAN_DATA,
    });
};

export const clean_emergency_steps = () => {
    return ({
        type: PATIENT_CLEAN_EMERGENCY_STEPS,
    });
};
export const update_bracelet_uuid = (uuid) => {
    return ({
        type: PATIENT_UPDATE_BRACELET_UUID,
        uuid: uuid,
    });
};

export const update_patient_uuid = (patientUuid) => {
    return ({
        type: PATIENT_UPDATE_BRACELET_UUID,
        patientUuid: patientUuid,
    });
};

export const update_editing = (bool) => {
    return ({
        type: PATIENT_UPDATE_EDITTING,
        bool: bool,
    });
};

export const next_step_active = () => {
    return ({
        type: PATIENT_NEXT_STEP,
    });
};

export const previous_step_active = () => {
    return ({
        type: PATIENT_PREVIOUS_STEP,
    });
};

export const patient_handle_modal = (openOnStep) => {
    return ({
        type: PATIENT_HANDLE_MODAL,
        openOnStep: openOnStep,
    });
};

export const close_assign_modals = () => {
    return ({
        type: CLOSE_ASSIGN_MODALS,
    });
};

export const patient_emergency_steps_handle_modal = () => {
    return ({
        type: PATIENT_HANDLE_EMERGENCY_STEPS_MODAL,
    });
};

export const set_sending_data = (newState) => {
    return ({
        type: PATIENT_SENDING_DATA,
        sendingData: newState
    });
};
