import React from 'react';


export default class HeadBanner extends React.Component {

    state = {};

    componentDidMount() {
        this.animateCSS('#banner', this.props.animation, ()=>{
            /*setTimeout(()=>{
                this.animateCSS('#banner', this.props.endAnimation);
            }, 3050)*/
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        this.animateCSS('#banner', this.props.animation, ()=>{
           /* setTimeout(()=>{
                this.animateCSS('#banner', this.props.endAnimation);
            }, 3050)*/
        });

    }


    animateCSS(element, animationName, callback) {
        const node = document.querySelector(element);
        if (node) {
            node.classList.add('animated', animationName);

            function handleAnimationEnd() {
                node.classList.remove('animated', animationName);
                node.removeEventListener('animationend', handleAnimationEnd);

                if (typeof callback === 'function') callback()
            }

            node.addEventListener('animationend', handleAnimationEnd);
        }
    }

    render() {
        return (
            <div className={this.props.classes} id={'banner'}>
                {this.props.content}
            </div>
        )
    }
}